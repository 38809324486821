export class Drug {

  drugID: number;
  tradeName: string;
  genericID: number;
  pack: string;
  vendor: string;
  countryID: number;
  price: number;
  priceUSD: number;
  isCommon: boolean;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  genericName: string;


}
