import { Component, Input } from '@angular/core';
import { PharmacyService } from '../services/pharmacy.service';
import { Pharmacy } from '../models/pharmacy.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-pharmacy-detail',
  templateUrl: './pharmacy-detail.component.html'
})
export class PharmacyDetailComponent {
  constructor(public service: PharmacyService, private notificationService: NotificationService) { }

  @Input() id: number;
  model: Pharmacy;

  ngOnInit() {

      this.service.get(this.id).subscribe(
        res => {
          this.service.model = res as Pharmacy;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );
  }

}
 
