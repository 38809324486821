import { Component, Input } from '@angular/core';
import { FormField } from '../models/form.field.model';
import { FormFieldOption } from '../models/form.field.option.model';
import { AssesmentFormService } from '../services/assesment.form.service';
import { FormFieldOptionService } from '../services/form.field.option.service';
import { FormFieldService } from '../services/form.field.service';
import { NotificationService } from '../services/notification.service';
import * as $ from 'jquery';
import { AssesmentForm } from '../models/assesment.form.model';

@Component({
  selector: 'app-assesment-form',
  templateUrl: './assesment-form.component.html'
})
export class AssesmentFormComponent {
  constructor(public formFieldOptionService: FormFieldOptionService, public formFieldService: FormFieldService, public service: AssesmentFormService, private notificationService: NotificationService) { }

  @Input() visitID: number;
  @Input() formFieldID: number;
  formFieldOptions: FormFieldOption[];
  formField: FormField;

  assesmentForm: AssesmentForm;
  formFieldOption: FormFieldOption;

  inputValue: string;

  ngOnInit() {
    this.formFieldService.get(this.formFieldID)
      .toPromise()
      .then(res => this.formField = res as FormField);

    this.formFieldOptionService.getByFormField(this.formFieldID)
      .toPromise()
      .then(res => this.formFieldOptions = res as FormFieldOption[]);
  }

  onOptionClick(option) {
    var id = option.formFieldOptionID;
    if ($("#fieldOption-" + id).hasClass("line-through")) {
      this.onOptionDblClick(id);
    } else {
      $("#fieldOption-" + id).toggleClass("underline");
      $("#fieldOption-" + id).toggleClass("text-bold");

      option.selected = $("#fieldOption-" + id).hasClass('underline');

      var include = $("#fieldOption-" + id).hasClass('underline');
      var exclude = $("#fieldOption-" + id).hasClass('line-through');

      if (include)
        this.push(option, null, true);
      else if (exclude)
        this.push(option, null, false);
      else {
        this.pull(option);
      }

    }
  }

  onOptionDblClick(option) {
    var id = option.formFieldOptionID;

    if ($("#fieldOption-" + id).hasClass("underline")) {
      this.onOptionClick(id);
    } else {
      $("#fieldOption-" + id).toggleClass("line-through");
      $("#fieldOption-" + id).toggleClass("text-bold");

      option.selected = $("#fieldOption-" + id).hasClass('underline');

      var include = $("#fieldOption-" + id).hasClass('underline');
      var exclude = $("#fieldOption-" + id).hasClass('line-through');

      if (include)
        this.push(option, null, true);
      else if (exclude)
        this.push(option, null, false);
      else {
        this.pull(option);
      }

    }
  }

  push(option, inputValue, include) {
    this.service.model = new AssesmentForm(0, option.formFieldOptionID, option.formFieldID, inputValue, this.visitID, include, null, null, null, null, null);

    this.service.push().subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );

  }

  pull(option) {
    this.service.model = new AssesmentForm(0, option.formFieldOptionID, option.formFieldID, null, this.visitID, null, null, null, null, null, null);

    this.service.pull().subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );
  }

  put(option, event) {
    this.service.model = new AssesmentForm(0, option.formFieldOptionID, option.formFieldID, event.target.value, this.visitID, false, null, null, null, null, null);

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );
  }

  edit(formFieldID, event) {

    this.service.model = new AssesmentForm(0, null, formFieldID, event.target.value, this.visitID, true, null, null, null, null, null);

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );
  }


  findOption(item) {

    if (this.formFieldOptionID)
      return item.formFieldID == this.formFieldID && item.formFieldOptionID == this.formFieldOptionID;
    else
      return item.formFieldID == this.formFieldID && !item.formFieldOptionID;
  }

  findFormFieldOption(item) {
      return item.formFieldOptionID == this.formFieldOptionID;
  }

  formFieldOptionID: number = null;

  findFieldOption(formFieldOptionID) {


    this.formFieldOptionID = formFieldOptionID;
    if (this.service.list)
      this.assesmentForm = this.service.list.find(this.findOption, { formFieldOptionID: formFieldOptionID, formFieldID: this.formFieldID });

    if (this.formFieldOptions)
      this.formFieldOption = this.formFieldOptions.find(this.findFormFieldOption, { formFieldOptionID: formFieldOptionID });

    if (this.assesmentForm && this.assesmentForm.include && this.assesmentForm.inputValue)
      this.formFieldOption.inputValue = this.assesmentForm.inputValue;
  }

  findForm() {

    if (this.service.list)
      var assesmentForm = this.service.list.find(this.findOption, { formFieldOptionID: null, formFieldID: this.formFieldID });

    if (assesmentForm)
      this.inputValue = assesmentForm.inputValue;
  }

}

