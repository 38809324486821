import { Component } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { GenericService } from '../services/generic.service';

import { Generic } from '../models/generic.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html'
})
export class GenericListComponent {

  constructor(public service: GenericService, private modalService: NgbModal, private notificationService: NotificationService) { }

  ngOnInit() {
    this.onSearch();
  }

  closeResult = '';
  model = new Generic();
  modelSearch = new Generic();

  open(content, model) {
    if (model == null)
      model = new Generic();

    this.model = Object.assign({}, model);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }

  onArchive(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );

  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onCommon(values: any, genericID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.toggleCommon(genericID).subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );

  }
}

