import { Component, Input } from '@angular/core';
import { TaskService } from '../services/task.service';
import { Task } from '../models/task.model';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html'
})
export class TaskFormComponent {
  constructor(public service: TaskService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() model: Task;

  options: any = { format: 'YYYY-MM-DD' };
  optionsTime: any = { format: 'LT' };

  submitted = false;

  onSubmit() {
    this.submitted = true;

    this.service.model = this.model;
    if (!this.model.visitTaskID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByVisit();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByVisit();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }

}
 
