import { Component, Input } from '@angular/core';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-payment-list-view',
  templateUrl: './payment-list-view.component.html'
})
export class PaymentListViewComponent {

  @Input() invoiceID: number;
  constructor(public service: PaymentService) { }

  ngOnInit() {
    this.service.invoiceID = this.invoiceID;
    this.service.getByInvoice();
  }

}

