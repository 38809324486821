export class Message {

  messageID: number;
  patientID: number;
  subject: string;
  body: string;
  reply: string;
  remarks: string;

  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  fromDate: string;
  toDate: string;

  patientNameEn: string;
  mobileNo: string;
  email: string;

}
