import { Component, Input } from '@angular/core';
import { ServiceRequestService } from '../services/service.request.service';
import { ServiceRequest } from '../models/service.request.model';

@Component({
  selector: 'app-service-request-list-view',
  templateUrl: './service-request-list-view.component.html'
})
export class ServiceRequestListViewComponent {

  @Input() serviceRequestIDs;
  constructor(public service: ServiceRequestService) { }

  ngOnInit() {
    this.service.getByVisit();
  }
}

