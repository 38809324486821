import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import {CommonModule} from '@angular/common';
import { StaffService } from '../services/staff.service';
import { Staff } from '../models/staff.model';
import { Privilege } from '../models/privilege.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-staff-form',
  templateUrl: './staff-form.component.html'
})
export class StaffFormComponent {
  constructor(public service: StaffService, public lookupService: LookupService, private notificationService: NotificationService) { }

  @Input() model: Staff;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {

    this.model = changes.model.currentValue;

    if (this.model.privilege != null)
      this.destructPrivilege();
  }

  Registration: boolean = false;
  Doctor: boolean = false;
  Nurse: boolean = false;
  Accountant: boolean = false;
  Pharmacy: boolean = false;
  Lab: boolean = false;
  Imaging: boolean = false;
  ECG: boolean = false;
  Admin: boolean = false;
  CMO: boolean = false;

  submitted = false;

  buildPrivilege() {
    return (this.Registration ? '1' : '0') +
      (this.Doctor ? '1' : '0') +
      (this.Nurse ? '1' : '0') +
      (this.Accountant ? '1' : '0') +
      (this.Pharmacy ? '1' : '0') +
      (this.Lab ? '1' : '0') +
      (this.Imaging ? '1' : '0') +
      (this.ECG ? '1' : '0') +
      (this.Admin ? '1' : '0') +
      (this.CMO ? '1' : '0');

  }

  destructPrivilege() {
    var privelege = this.model.privilege.split('');

    this.Registration = (privelege[Privilege.Registration] == '1' ? true : false);
    this.Doctor = (privelege[Privilege.Doctor] == '1' ? true : false);
    this.Nurse = (privelege[Privilege.Nurse] == '1' ? true : false);
    this.Accountant = (privelege[Privilege.Accountant] == '1' ? true : false);
    this.Pharmacy = (privelege[Privilege.Pharmacy] == '1' ? true : false);
    this.Lab = (privelege[Privilege.Lab] == '1' ? true : false);
    this.Imaging = (privelege[Privilege.Imaging] == '1' ? true : false);
    this.ECG = (privelege[Privilege.ECG] == '1' ? true : false);
    this.Admin = (privelege[Privilege.Admin] == '1' ? true : false);
    this.CMO = (privelege[Privilege.CMO] == '1' ? true : false);

  }

  onSubmit() {
    this.submitted = true;
    this.model.privilege = this.buildPrivilege();

    this.service.model = this.model;
    if (!this.model.staffID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.search();

        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        this.notificationService.notifyFailure();

        console.log(err);
      }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.search();

        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        this.notificationService.notifyFailure();

        console.log(err);
      }
    )
  }

}

