import { Component } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';

@Component({
  selector: 'app-prescription-pagination',
  templateUrl: './prescription-pagination.component.html'
})
export class PrescriptionPaginationComponent {
  constructor(public service: PrescriptionService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    //this.service.refreshList();
  }
}
