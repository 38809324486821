import { Component, Input } from '@angular/core';
import { InvoiceDetailService } from '../services/invoice.detail.service';

@Component({
  selector: 'app-invoice-detail-list-view',
  templateUrl: './invoice-detail-list-view.component.html'
})
export class InvoiceDetailListViewComponent {

  @Input() invoiceID: number;
  constructor(public service: InvoiceDetailService) { }

  ngOnInit() {
    this.service.invoiceID = this.invoiceID;
    this.service.getByInvoice();
  }

}

