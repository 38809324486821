import { Appointment } from '../models/appointment.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { RoleService } from './role.service';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  model: Appointment = null;
  modelSearch: Appointment = null;
  list: Appointment[];
  listReport: Appointment[];
  modelReport: Report = null;
  baseUrl: string;

  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number;
  pages: number[];

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getDailyTotals(fromDate, toDate) {
    let params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate);

    return this.http.get(this.baseUrl + "api/Appointment/GetDailyTotals", { params: params });
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    return this.http.post(this.baseUrl + "api/Appointment", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    return this.http.put(this.baseUrl + "api/Appointment/" + this.model.appointmentID, this.model);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "api/Appointment/" + id);
  }

  confirm() {
    this.model.confirmedBy = this.roleService.getStaffID();
    return this.http.put(this.baseUrl + "api/Appointment/Confirm/" + this.model.appointmentID, this.model);
  }

  get(id) {
    return this.http.get(this.baseUrl + "api/Appointment/" + id);
  }

  listByAssignee() {
    this.list = null;

    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

      params = params.set("assignee", this.roleService.getStaffID().toString());

    this.http.get(this.baseUrl + "api/Appointment/ListByAssignee", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  search() {
    this.list = null;

    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    if (this.modelSearch.fromDate != null)
      params = params.set("fromDate", this.modelSearch.fromDate);

    if (this.modelSearch.toDate != null)
      params = params.set("toDate", this.modelSearch.toDate);

    if (this.modelSearch.appointmentStatus != null)
      params = params.set("appointmentStatus", this.modelSearch.appointmentStatus.toString());

    if (this.modelSearch.specializationID != null)
      params = params.set("specializationID", this.modelSearch.specializationID.toString());

    if (this.modelSearch.stateID != null)
      params = params.set("stateID", this.modelSearch.stateID.toString());

    if (this.modelSearch.patientID != null)
      params = params.set("patientID", this.modelSearch.patientID.toString());

    if (this.modelSearch.email != null)
      params = params.set("email", this.modelSearch.email);

    if (this.modelSearch.mobileNo != null)
      params = params.set("mobileNo", this.modelSearch.mobileNo);

    this.http.get(this.baseUrl + "api/Appointment/Search", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  report() {
    this.listReport = null;

    let params = new HttpParams();

    if (this.modelReport.fromDate != null)
      params = params.set("fromDate", this.modelReport.fromDate);

    if (this.modelReport.toDate != null)
      params = params.set("toDate", this.modelReport.toDate);

    if (this.modelReport.assignee != null)
      params = params.set("assignee", this.modelReport.assignee.toString());

    this.http.get(this.baseUrl + "api/Appointment/Report", { params: params })
      .toPromise()
      .then(res => this.listReport = res as Appointment[]);
  }

  loadData(res) {
    this.list = res.item1 as Appointment[];
    this.paginate(res.item2);
  }

  paginate(totalRecords) {

    var pageSize = this.pageSize;
    var currentPage = this.currentPage;
    // calculate total pages
    var totalPages = Math.ceil(totalRecords / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalRecords - 1);

    this.totalPages = totalPages;
    this.totalRecords = totalRecords;

    // create an array of pages to ng-repeat in the pager control
    this.pages = [];
    var i;
    for (i = startPage; i <= endPage; i++)
      this.pages.push(i);
  };
}
