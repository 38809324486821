export class OnlinePatient {

  onlinePatientID: number;

  patientID: number;
  patientNameAr: string;
  patientNameEn: string;
  dOB: Date;
  gender: number;
  mobileNo: string;
  emergencyNo: string;
  emergencyNameAr: string;
  emergencyNameEn: string;
  stateID: number;
  cityID: number;
  address: string;
  location: string;
  nationalID: string;
  email: string;
  whatsappNo: string;
  identification: string;
  username: string;
  password: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
  photo: string;

  genderTitle: string;
  age: string;

  verifiedAt: Date;
  verifiedBy: number;
}
