export class Visit {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  visitID: number;
  patientID: number;
  visitDate: string;
  complaint: string;
  diagnosis: string;
  staffID: number;
  arrivalTime: Date;
  appointmentID: number;
  visitStatus: number;
  visitRemarks: string;
  consultationType: boolean;
  specializationID: number;
  departualTime: Date;
  consultionFees: number;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  fromDate: string;
  toDate: string;

  specializationName: string;
  cityNameEn: string;
  diagnosisName: string;

  staffNameEn: string;

  title: string;
  count: number;
  mobileNo: string;
  email: string;

  visitNo: string;

  hasAlergy: boolean;

  sponserID: number;
}
