export class Sponsor {
  sponsorID: number;
  sponsorName: string;
  email: string;
  phone: string;
  mobileNo: string;
  address: string;
  status: number;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
}
