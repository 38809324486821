import { Component } from '@angular/core';
import { OnlineAppointmentService } from '../services/online.appointment.service';

@Component({
  selector: 'app-online-appointment-report',
  templateUrl: './online-appointment-report.component.html'
})
export class OnlineAppointmentReportComponent {

  constructor(public service: OnlineAppointmentService) { }
}

