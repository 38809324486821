export class Appointment {

  appointmentID: number;
  patientID: number;
  appointmentDate: Date;
  appointmentTime: string;
  complaint: string;
  appointmentRemarks: string;
  appointmentStatus: number;
  assignee: number;
  specializationID: number;
  stateID: number;
  cityID: number;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  fromDate: string;
  toDate: string;
  email: string;
  mobileNo: string;

  visitID: number;
  specializationNameEn: string;
  patientNameEn: string;

  hasAlergy: boolean;

  confirmeddAt: Date;
  confirmedBy: number;

  isTelemedicine: boolean;
  meetingNumber: string;
  meetingPassword: string;

  sponserID: number;
}
