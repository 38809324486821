import { Component } from '@angular/core';
import { PharmacyContractService } from '../services/pharmacy.contract.service';

@Component({
  selector: 'app-pharmacy-contract-pagination',
  templateUrl: './pharmacy-contract-pagination.component.html'
})
export class PharmacyContractPaginationComponent {
  constructor(public service: PharmacyContractService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
