import { Component } from '@angular/core';
import { RoleService } from '../services/role.service';
import { Privilege } from '../models/privilege.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent {
  constructor(private roleService: RoleService, private modalService: NgbModal) {

  }

  fullname: string;
  privilege: Privilege;
  /*
Registration,
Doctor,
Nurse,
Accountant,
Pharmacy,
Lab,
Imaging,
ECG,
Admin,
   */
  Registration: boolean = false;
  Doctor: boolean = false;
  Nurse: boolean = false;
  Accountant: boolean = false;
  Pharmacy: boolean = false;
  Lab: boolean = false;
  Imaging: boolean = false;
  ECG: boolean = false;
  Admin: boolean = false;
  CMO: boolean = false;


  ngOnInit() {
    this.fullname = this.roleService.getFullname();

    this.Registration = this.roleService.isAuthorized(Privilege.Registration);
    this.Doctor = this.roleService.isAuthorized(Privilege.Doctor);
    this.Nurse = this.roleService.isAuthorized(Privilege.Nurse);
    this.Accountant = this.roleService.isAuthorized(Privilege.Accountant);
    this.Pharmacy = this.roleService.isAuthorized(Privilege.Pharmacy);
    this.Lab = this.roleService.isAuthorized(Privilege.Lab);
    this.Imaging = this.roleService.isAuthorized(Privilege.Imaging);
    this.ECG = this.roleService.isAuthorized(Privilege.ECG);
    this.Admin = this.roleService.isAuthorized(Privilege.Admin);
    this.CMO = this.roleService.isAuthorized(Privilege.CMO);

  }

  isExpanded = false;

  closeResult = '';

  changePassword(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
