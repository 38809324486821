import { Component } from '@angular/core';
import { StateService } from '../services/state.service';

@Component({
  selector: 'app-state-pagination',
  templateUrl: './state-pagination.component.html'
})
export class StatePaginationComponent {
  constructor(public service: StateService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
