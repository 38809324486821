export class Staff {

  staffID: number;
  staffNameEn: string;
  staffNameAr: string;
  mobileNo: string;
  email: string;
  username: string;
  password: string;
  staffCategoryID: number;
  specializationID: number;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  privilege: string;

  old_password: string;
  confirm_password: string;

}
