import { Component } from '@angular/core';
import { SponsorServiceService } from '../services/sponsor.service.service';

@Component({
  selector: 'app-sponsor-service-pagination',
  templateUrl: './sponsor-service-pagination.component.html'
})
export class SponsorServicePaginationComponent {
  constructor(public service: SponsorServiceService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
