import { Component, Input } from '@angular/core';
import { InvoiceService } from '../services/invoice.service';
import { Invoice } from '../models/invoice.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html'
})
export class InvoiceFormComponent {
  constructor(public lookupService: LookupService, public service: InvoiceService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() model: Invoice;

  options: any = { format: 'YYYY-MM-DD' };
  optionsTime: any = { format: 'LT' };

  filterPatient() {
    var term = $('#Filter').val();
    if (term.toString().length >= 3)
      this.lookupService.filterPatient(term);
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;

    this.service.model = this.model;
    if (!this.model.invoiceID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }


}
 
