import { Component } from '@angular/core';
import { OnlinePatientService } from '../services/online.patient.service';

@Component({
  selector: 'app-online-patient-pagination',
  templateUrl: './online-patient-pagination.component.html'
})
export class OnlinePatientPaginationComponent {
  constructor(public service: OnlinePatientService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
