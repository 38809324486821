import { Component, Input } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { Payment } from '../models/payment.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html'
})
export class PaymentListComponent {

  constructor(public lookupService: LookupService, public service: PaymentService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  ngOnInit() {
    this.service.getByInvoice();
  }

  model = new Payment();
  modelSearch = new Payment();

  total: number = 0;
  vat: number = 0;

  onDelete(id) {
    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByInvoice();
      },
      err => { console.log(err); }
    );    
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;

    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onNew() {
    this.model = new Payment();
    this.model.amount = parseFloat($('#Remaining').val().toString());
    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    if (model.paymentDate)
      model.paymentDate = this.datepipe.transform(model.paymentDate, 'dd/MM/yyyy');

    this.model = Object.assign({}, model);
  }


}

