import { Component } from '@angular/core';
import { AlergyService } from '../services/alergy.service';

@Component({
  selector: 'app-alergy-list-view',
  templateUrl: './alergy-list-view.component.html'
})
export class AlergyListViewComponent {

  constructor(public service: AlergyService) { }

  ngOnInit() {
    this.service.getByPatient();
  }

}

