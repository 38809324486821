import { Component, Input } from '@angular/core';

import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { NotificationService } from '../services/notification.service';
import { Booking } from '../models/booking.model';
import { BookingService } from '../services/booking.service';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html'
})
export class BookingDetailComponent {

  constructor(public service: BookingService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  model: Booking;
  @Input() id: string;

  ngOnInit() {

    this.service.get(this.id).subscribe(
      res => {
        this.model = res as Booking;
      },
      err => { console.log(err); this.notificationService.notifyFailure(); }
    );

  }
}
