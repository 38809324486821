import { Component } from '@angular/core';

import { StaffService } from '../services/staff.service';

import { Staff } from '../models/staff.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html'
})
export class StaffListComponent {

  constructor(public lookupService: LookupService, public service: StaffService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.lookupService.getStaffCategories();
    this.onSearch();
  }

  closeResult = '';
  model = new Staff();
  modelSearch = new Staff();

  onNew() {
    this.model = new Staff();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.model = Object.assign({}, model);
  }

  onArchive(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); this.notificationService.notifyFailure(); }
    );
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }
}

