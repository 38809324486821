import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {

  baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  login(username: string, password: string): Observable<boolean> {
    return this.http.post<{ token: string, id: string, staffName: string, username: string, privilege: string }>(this.baseUrl + 'api/users/authenticate', { username: username, password: password })
      .pipe(
        map(result => {
          localStorage.setItem('access_token', result.token);
          localStorage.setItem('staffID', result.id);
          localStorage.setItem('staffName', result.staffName);
          localStorage.setItem('username', result.username);
          localStorage.setItem('privilege', result.privilege);
          return true;
        })
      );
  }

  logout() {
    localStorage.removeItem('access_token');
  }

  public get loggedIn(): boolean {
    return (localStorage.getItem('access_token') !== null);
  }
}
