import { Component } from '@angular/core';
import { MembershipType } from '../models/membership.type.model';
import { Pharmacy } from '../models/pharmacy.model';
import { ServiceProvider } from '../models/service.provider.model';
import { Sponsor } from '../models/sponsor.model';
import { InvoiceDetailService } from '../services/invoice.detail.service';
import { MembershipTypeService } from '../services/membership.type.service';
import { PharmacyService } from '../services/pharmacy.service';
import { ServiceProviderService } from '../services/service.provider.service';
import { SponsorService } from '../services/sponsor.service';

@Component({
  selector: 'app-invoice-detail-report',
  templateUrl: './invoice-detail-report.component.html'
})
export class InvoiceDetailReportComponent {
  constructor(public service: InvoiceDetailService, public serviceServiceProvider: ServiceProviderService, public servicePharmacy: PharmacyService, public serviceSponsor: SponsorService, public serviceMembershipType: MembershipTypeService) { }

  title = "";
  invoicesList = [];

  ngOnInit() {

    if (this.service.modelReport.serviceProviderID) {
      this.serviceServiceProvider.get(this.service.modelReport.serviceProviderID).subscribe(
        res => {
          this.title = (res as ServiceProvider).providerName;
        },
        err => { console.log(err); }
      );
    } else if (this.service.modelReport.pharmacyID) {
      this.servicePharmacy.get(this.service.modelReport.pharmacyID).subscribe(
        res => {
          this.title = (res as Pharmacy).pharmacyName;
        },
        err => { console.log(err); }
      );
    }
    else if (this.service.modelReport.membershipTypeID) {
      this.serviceMembershipType.get(this.service.modelReport.membershipTypeID).subscribe(
        res => {
          let membershipType = (res as MembershipType);

          this.title = membershipType.sponsorName + " ["+ membershipType.membershipTypeName+"]";
        },
        err => { console.log(err); }
      );
    }

    else if (this.service.modelReport.sponsorID) {
      this.serviceSponsor.get(this.service.modelReport.sponsorID).subscribe(
        res => {
          this.title = (res as Sponsor).sponsorName;
        },
        err => { console.log(err); }
      );
    }

  }
}


