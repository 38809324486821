import { Component } from '@angular/core';
import { PharmacyService } from '../services/pharmacy.service';

@Component({
  selector: 'app-pharmacy-pagination',
  templateUrl: './pharmacy-pagination.component.html'
})
export class PharmacyPaginationComponent {
  constructor(public service: PharmacyService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
