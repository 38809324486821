import { Component, Input } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { Payment } from '../models/payment.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { InvoiceService } from '../services/invoice.service';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html'
})
export class PaymentFormComponent {
  constructor(public lookupService: LookupService, public service: PaymentService, public invoiceService: InvoiceService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() model: Payment;

  options: any = { format: 'YYYY-MM-DD' };
  optionsTime: any = { format: 'LT' };

  ngOnInit() {
    this.lookupService.getPaymentMethods();
  }


  submitted = false;

  onSubmit() {
    this.submitted = true;

    this.service.model = this.model;
    if (!this.model.paymentID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByInvoice();

        this.invoiceService.refreshModel(this.service.invoiceID);

        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByInvoice();

        this.invoiceService.refreshModel(this.service.invoiceID);

        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }



}
 
