import { Component, Input, SimpleChanges } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { AppointmentService } from '../services/appointment.service';
import { Appointment } from '../models/appointment.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-visit-form-wizard',
  templateUrl: './visit-form-wizard.component.html'
})
export class VisitFormWizardComponent {
  constructor(public lookupService: LookupService, private router: Router, public service: VisitService, public appointmentService: AppointmentService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() appointment: Appointment;
  model: Visit;

  options: any = { format: 'YYYY-MM-DD', minDate: new Date() };
  optionsTime: any = { format: 'LT' };

  ngOnChanges(changes: SimpleChanges) {

    this.appointment = changes.appointment.currentValue;
    this.model = new Visit();

    this.model.appointmentID = this.appointment.appointmentID;
    this.model.patientID = this.appointment.patientID;
    this.model.staffID = this.appointment.assignee;
    this.model.specializationID = this.appointment.specializationID;
    this.model.complaint = this.appointment.complaint;
  }

  ngOnInit() {
    this.model = new Visit();
    this.lookupService.getSpecializations();
    this.lookupService.getStaffs();
  }

  submitted = false;

  onSubmit() {
    this.insertRecord();
  }


  insertRecord() {
    this.service.model = this.model;
    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.router.navigate(['visit-detail/' + res]);
      },
      err => { console.log(err); }
    )
  }
}
 
