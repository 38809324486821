import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { AssesmentFormService } from '../services/assesment.form.service';
import { RoleService } from '../services/role.service';
import { Privilege } from '../models/privilege.model';

@Component({
  selector: 'app-visit-detail',
  templateUrl: './visit-detail.component.html'
})
export class VisitDetailComponent {

  constructor(public roleService: RoleService, private activatedRoute: ActivatedRoute, public service: VisitService, private assesmentFormService: AssesmentFormService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  isDoctor: boolean = false;
  isNurse: boolean = false;
  isAdmin: boolean = false;

  model: Visit;
  id: string;

  ngOnInit() {
    this.isAdmin = this.roleService.isAuthorized(Privilege.Admin);
    this.isDoctor = this.roleService.isAuthorized(Privilege.Doctor);

    if (this.isDoctor)
      this.isNurse = false;
    else
      this.isNurse = this.roleService.isAuthorized(Privilege.Nurse);

    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');

      this.service.get(this.id).subscribe(
        res => {
          this.model = res as Visit;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );

      this.assesmentFormService.getByVisit(this.id);
    });
  }

  currentTab = '';
  toggleTab(tab) {

    if (this.currentTab == tab) {
      this.currentTab = '';
    } else {
      this.currentTab = tab;
    }

    $(document).scrollTop($(document).height());
  }

  doctor: boolean = false;
  nurse: boolean = false;
  problem: boolean = false;
  alergy: boolean = false;
  vital: boolean = false;
  task: boolean = false;

  onTabSelected(tab) {
    this.doctor = false;
    this.nurse = false;
    this.problem = false;
    this.alergy = false;
    this.vital = false;
    this.task = false;

    switch (tab) {
      case 'doctor':
        this.doctor = true;
        break;
      case 'nurse':
        this.nurse = true;
        break;
      case 'problem':
        this.problem = true;
        break;
      case 'alergy':
        this.alergy = true;
        break;
      case 'vital':
        this.vital = true;

        this.assesmentFormService.getByVisit(this.id);
        break;
      case 'task':
        this.task = true;
        break;
    }
  }
}
