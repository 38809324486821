import { Component } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';

@Component({
  selector: 'app-prescription-history-pagination',
  templateUrl: './prescription-history-pagination.component.html'
})
export class PrescriptionHistoryPaginationComponent {
  constructor(public service: PrescriptionService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.getHistory();
  }
}
