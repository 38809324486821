import { InvoiceDetail } from '../models/invoice.detail.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { RoleService } from './role.service';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceDetailService {
  model: InvoiceDetail = null;
  list: InvoiceDetail[];
  baseUrl: string;

  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number;
  pages: number[];

  invoiceID: number;

  listReport: InvoiceDetail[];
  modelReport: Report = null;

  totalAmount: number = 0;
  totalCost: number = 0;
  totalCopayment: number = 0;
  totalDiscount: number = 0;

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    this.model.invoiceID = this.invoiceID;
    return this.http.post(this.baseUrl + "api/InvoiceDetail", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    this.model.invoiceID = this.invoiceID;
    return this.http.put(this.baseUrl + "api/InvoiceDetail/" + this.model.invoiceDetailID, this.model);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "api/InvoiceDetail/" + id);
  }

  getByInvoice() {

    this.http.get(this.baseUrl + "api/InvoiceDetail/GetByInvoice/" + this.invoiceID)
      .toPromise()
      .then(res => this.loadDataList(res));
  }

  report() {

    this.listReport = null;

    let params = new HttpParams();

    if (this.modelReport.fromDate != null)
      params = params.set("fromDate", this.modelReport.fromDate);

    if (this.modelReport.toDate != null)
      params = params.set("toDate", this.modelReport.toDate);

    if (this.modelReport.status != null)
      params = params.set("status", this.modelReport.status);

    if (this.modelReport.serviceProviderID != null)
      params = params.set("serviceProviderID", this.modelReport.serviceProviderID.toString());

      if (this.modelReport.pharmacyID != null)
      params = params.set("pharmacyID", this.modelReport.pharmacyID.toString());

    if (this.modelReport.sponsorID != null)
      params = params.set("sponsorID", this.modelReport.sponsorID.toString());

    if (this.modelReport.membershipTypeID != null)
      params = params.set("membershipTypeID", this.modelReport.membershipTypeID.toString());

    this.http.get(this.baseUrl + "api/InvoiceDetail/Report", { params: params })
      .toPromise()
      .then(res => this.loadDataReport(res));
  }

  search(modelSearch: InvoiceDetail) {
    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    if (modelSearch.invoiceID != null)
      params = params.set("invoiceID", modelSearch.invoiceID.toString());

    this.http.get(this.baseUrl + "api/InvoiceDetail/Search", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  loadData(res) {
    this.list = res.item1 as InvoiceDetail[];
    this.paginate(res.item2);
  }

  loadDataReport(res) {
    this.listReport = res.item1 as InvoiceDetail[];
    this.totalRecords = res.item2;
    this.totalAmount = res.item3;
    this.totalCost = res.item4;
    this.totalCopayment = res.item5;
    this.totalDiscount = res.item6;

  }

  loadDataList(res) {
    this.list = res.item1 as InvoiceDetail[];
    this.totalAmount = res.item2;
    this.totalCopayment = res.item3;
    this.totalDiscount = res.item4;
  }

  paginate(totalRecords) {

    var pageSize = this.pageSize;
    var currentPage = this.currentPage;
    // calculate total pages
    var totalPages = Math.ceil(totalRecords / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalRecords - 1);

    this.totalPages = totalPages;
    this.totalRecords = totalRecords;

    // create an array of pages to ng-repeat in the pager control
    this.pages = [];
    var i;
    for (i = startPage; i <= endPage; i++)
      this.pages.push(i);
  };
}
