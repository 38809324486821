export class ExchangeRate {

  exchangeRateID: number;
  effectiveDate: string;
  drugExchangeRate: string;
  serviceExchangeRate: string;
  createdAt: Date;
  createdBy: number;
}


