export class Invoice {

  invoiceID: number;
  patientID: number;
  visitID: number;
  invoiceNo: number;
  invoiceDate: Date;
  amount: number;
  vAT: number;
  invoiceRemarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  sponsorID: number;
  copayment: number;
  membershipTypeID: number;

  fromDate: string;
  toDate: string;
  email: string;
  mobileNo: string;

  title: string;
  count: number;

  visitNo: string;
}
