import { Component, Inject, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { ServiceRequestService } from '../services/service.request.service';
import { NotificationService } from '../services/notification.service';
import { FileUploader } from 'ng2-file-upload';
import { ServiceRequest } from '../models/service.request.model';
import * as $ from 'jquery';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-result-upload',
  templateUrl: './result-upload.component.html'
})
export class ResultUploadComponent {
  constructor(public lookupService: LookupService, private service: ServiceRequestService, private notificationService: NotificationService, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Input() model: ServiceRequest;

  baseUrl: string;

  public uploader: FileUploader;

  ngOnChanges(changes: SimpleChanges) {

    this.model = changes.model.currentValue;

    this.lookupService.getServiceProvidersByService(this.model.serviceID);

    this.uploader = new FileUploader({ url: this.baseUrl + 'api/ServiceRequest/UploadResult', itemAlias: 'ResultFile' });
    //override the onAfterAddingfile property of the uploader so it doesn't authenticate with //credentials.
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    //overide the onCompleteItem property of the uploader so we are 
    //able to deal with the server response.
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.notificationService.notifySuccess();

      this.service.getByVisit();
      $('#PanelResult').slideToggle();
    };
  }


  onSubmitResult() {
    const token = localStorage.getItem('access_token');
    if (this.model.serviceProviderID)
      this.uploader.options.headers = [{ name: 'Authorization', value: 'JWT ' + token }, { name: 'ServiceRequestID', value: this.model.serviceRequestID.toString() }, { name: 'ServiceProviderID', value: this.model.serviceProviderID.toString() }, { name: 'Result', value: this.model.result }];
    else
      this.uploader.options.headers = [{ name: 'Authorization', value: 'JWT ' + token }, { name: 'ServiceRequestID', value: this.model.serviceRequestID.toString() }, { name: 'Result', value: this.model.result }];

    if (this.uploader.getNotUploadedItems().length) {
      this.uploader.uploadAll();
    } else {

      this.service.updateResult(this.model.serviceRequestID, this.model.result, this.model.serviceProviderID).subscribe(
        res => {
          this.notificationService.notifySuccess();

          this.service.getByVisit();
          $('#PanelResult').slideToggle();
        },
        err => { console.log(err); this.notificationService.notifyFailure();}
      );
    }
  }

}
