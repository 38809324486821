import { Component, Input } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { ActivatedRoute } from '@angular/router';
import { InvoiceService } from '../services/invoice.service';
import { Invoice } from '../models/invoice.model';
import { NotificationService } from '../services/notification.service';



@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent {
  

  constructor(private activatedRoute: ActivatedRoute, public service: PaymentService, public invoiceService: InvoiceService, private notificationService: NotificationService) {

  }

  model: Invoice;
  id: string;

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');

      this.service.invoiceID = parseInt(this.id);

      this.invoiceService.get(this.id).subscribe(
        res => {
          this.model = res as Invoice;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );
    });
  }


}
