import { Component } from '@angular/core';
import { ServiceProviderService } from '../services/service.provider.service';
import { ServiceProvider } from '../models/service.provider.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-service-provider-list',
  templateUrl: './service-provider-list.component.html'
})
export class ServiceProviderListComponent {

  constructor(public lookupService: LookupService, public service: ServiceProviderService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  ngOnInit() {
    this.onSearch();

    this.lookupService.getStates();
  }

  model = new ServiceProvider();
  modelSearch = new ServiceProvider();

  onDelete(id) {
    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onNew() {
    this.model = new ServiceProvider();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.lookupService.getCitiesByState(model.stateID);
    this.model = Object.assign({}, model);
  }
}

