import { Component } from '@angular/core';
import { MembershipTypeService } from '../services/membership.type.service';

@Component({
  selector: 'app-membership-type-pagination',
  templateUrl: './membership-type-pagination.component.html'
})
export class MembershipTypePaginationComponent {
  constructor(public service: MembershipTypeService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
