import { Component } from '@angular/core';
import { ServiceTypeService } from '../services/service.type.service';

@Component({
  selector: 'app-service-type-pagination',
  templateUrl: './service-type-pagination.component.html'
})
export class ServiceTypePaginationComponent {
  constructor(public service: ServiceTypeService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
