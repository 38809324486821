export class MembershipSpecialization {
  membershipSpecializationID: number;
  specializationID: number;
  membershipTypeID: number;
  copayment: number;

  status: number;
  lastUpdate: Date;
  remarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  specializationName: string;
}
