export class Generic {

  genericID: number;
  genericName: string;
  isCommon: boolean;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

}
