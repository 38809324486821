import { Component } from '@angular/core';
import { InvoiceService } from '../services/invoice.service';

@Component({
  selector: 'app-invoice-pagination',
  templateUrl: './invoice-pagination.component.html'
})
export class InvoicePaginationComponent {
  constructor(public service: InvoiceService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
