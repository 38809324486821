import { Component, Input } from '@angular/core';
import { AlergyService } from '../services/alergy.service';



@Component({
  selector: 'app-alergy-view',
  templateUrl: './alergy-view.component.html'
})
export class AlergyViewComponent {
  @Input() patientID: number;

  constructor(public service: AlergyService) {

  }

  ngOnInit() {
    this.service.patientID = this.patientID;
  }


}
