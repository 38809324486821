import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

import { PatientComponent } from './patient/patient.component';
import { HomeComponent } from './home/home.component';
import { StateComponent } from './state/state.component';
import { LoginLayoutComponent } from './layout/login/login-layout.component';
import { MasterLayoutComponent } from './layout/master/master-layout.component';
import { LoginComponent } from './login/login.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { VisitComponent } from './visit/visit.component';
import { VisitDetailComponent } from './visit/visit-detail.component';
import { ReportLayoutComponent } from './layout/report/report-layout.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { PrintInvoiceComponent } from './report/print-invoice.component';
import { PrintPrescriptionComponent } from './report/print-prescription.component';
import { PrintOrderComponent } from './report/print-order.component';
import { OrderComponent } from './order/order.component';
import { StaffComponent } from './staff/staff.component';

import { DrugComponent } from './drug/drug.component';
import { GenericComponent } from './generic/generic.component';

import { ServiceTypeComponent } from './service-type/service-type.component';
import { ServiceCategoryComponent } from './service-category/service-category.component';
import { ServiceComponent } from './service/service.component';

import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { CityComponent } from './city/city.component';
import { ExchangeRateComponent } from './exchange-rate/exchange-rate.component';
import { ProviderContractComponent } from './provider-contract/provider-contract.component';
import { SpecializationComponent } from './specialization/specialization.component';
import { PrintPaymentComponent } from './report/print-payment.component';
import { FrequencyComponent } from './frequency/frequency.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { PaymentsComponent } from './payment/payments.component';
import { PrintMedicalReportComponent } from './report/print-medical-report.component';
import { ReportComponent } from './report/report.component';
import { PrintReportComponent } from './report/print-report.component';
import { RegistrationComponent } from './registration/registration.component';
import { BoardComponent } from './home/board.component';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { PharmacyContractComponent } from './pharmacy-contract/pharmacy-contract.component';
import { AlergyTypeComponent } from './alergy-type/alergy-type.component';
import { PrintStickerComponent } from './report/print-sticker.component';
import { OnlinePatientComponent } from './online-patient/online-patient.component';
import { OnlineAppointmentComponent } from './online-appointment/online-appointment.component';
import { BookingComponent } from './booking/booking.component';
import { MessageComponent } from './message/message.component';
import { StaffVisitListComponent } from './visit/staff-visit-list.component';
import { AssigneeAppointmentListComponent } from './appointment/assignee-appointment-list.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { MembershipTypeComponent } from './membership-type/membership-type.component';
import { SponsorServiceComponent } from './sponsor-service/sponsor-service.component';
import { SponsorSpecializationComponent } from './sponsor-specialization/sponsor-specialization.component';
import { MembershipServiceComponent } from './membership-service/membership-service.component';
import { MembershipSpecializationComponent } from './membership-specialization/membership-specialization.component';

const appRoutes: Routes = [
  {
    path: '',
    component: MasterLayoutComponent,
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'patient', component: PatientComponent, canActivate: [AuthGuard] },
      { path: 'state', component: StateComponent, canActivate: [AuthGuard] },
      { path: 'appointment', component: AppointmentComponent, canActivate: [AuthGuard] },
      { path: 'visit', component: VisitComponent, canActivate: [AuthGuard] },
      { path: 'staff', component: StaffComponent, canActivate: [AuthGuard] },
      { path: 'drug', component: DrugComponent, canActivate: [AuthGuard] },
      { path: 'generic', component: GenericComponent, canActivate: [AuthGuard] },
      { path: 'service-type', component: ServiceTypeComponent, canActivate: [AuthGuard] },
      { path: 'service-category', component: ServiceCategoryComponent, canActivate: [AuthGuard] },
      { path: 'service', component: ServiceComponent, canActivate: [AuthGuard] },
      { path: 'diagnosis', component: DiagnosisComponent, canActivate: [AuthGuard] },
      { path: 'visit-detail/:id', component: VisitDetailComponent, canActivate: [AuthGuard] },
      { path: 'invoice', component: InvoiceComponent, canActivate: [AuthGuard] },
      { path: 'invoice-detail/:id', component: InvoiceDetailComponent, canActivate: [AuthGuard] },
      { path: 'order/:id', component: OrderComponent, canActivate: [AuthGuard] },
      { path: 'service-provider', component: ServiceProviderComponent, canActivate: [AuthGuard] },
      { path: 'city', component: CityComponent, canActivate: [AuthGuard] },
      { path: 'exchange-rate', component: ExchangeRateComponent, canActivate: [AuthGuard] },
      { path: 'provider-contract/:id', component: ProviderContractComponent, canActivate: [AuthGuard] },
      { path: 'specialization', component: SpecializationComponent, canActivate: [AuthGuard] },
      { path: 'frequency', component: FrequencyComponent, canActivate: [AuthGuard] },
      { path: 'payment-method', component: PaymentMethodComponent, canActivate: [AuthGuard] },
      { path: 'patient-history/:id', component: PatientHistoryComponent, canActivate: [AuthGuard] },
      { path: 'payment', component: PaymentsComponent, canActivate: [AuthGuard] },
      { path: 'registration', component: RegistrationComponent, canActivate: [AuthGuard] },
      { path: 'board', component: BoardComponent, canActivate: [AuthGuard] },
      { path: 'report/:id', component: ReportComponent, canActivate: [AuthGuard] },
      { path: 'pharmacy', component: PharmacyComponent, canActivate: [AuthGuard] },
      { path: 'pharmacy-contract/:id', component: PharmacyContractComponent, canActivate: [AuthGuard] },
      { path: 'alergy-type', component: AlergyTypeComponent, canActivate: [AuthGuard] },
      { path: 'online-patient', component: OnlinePatientComponent, canActivate: [AuthGuard] },
      { path: 'online-appointment', component: OnlineAppointmentComponent, canActivate: [AuthGuard] },
      { path: 'booking', component: BookingComponent, canActivate: [AuthGuard] },
      { path: 'message', component: MessageComponent, canActivate: [AuthGuard] },
      { path: 'my-appointments', component: AssigneeAppointmentListComponent, canActivate: [AuthGuard] },
      { path: 'my-visits', component: StaffVisitListComponent, canActivate: [AuthGuard] },

      { path: 'sponsor', component: SponsorComponent, canActivate: [AuthGuard] },
      { path: 'membership-type', component: MembershipTypeComponent, canActivate: [AuthGuard] },
      { path: 'sponsor-service/:id', component: SponsorServiceComponent, canActivate: [AuthGuard] },
      { path: 'sponsor-specialization/:id', component: SponsorSpecializationComponent, canActivate: [AuthGuard] },
      { path: 'membership-service/:id', component: MembershipServiceComponent, canActivate: [AuthGuard] },
      { path: 'membership-specialization/:id', component: MembershipSpecializationComponent, canActivate: [AuthGuard] },
    ]
  },

  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full' },
    ]
  },

  {
    path: 'zoom', loadChildren: () => import('./zoom/zoom.module').then(m => m.ZoomModule)
  },

  {
    path: '',
    component: ReportLayoutComponent,
    children: [
      { path: 'print-report/:id', component: PrintMedicalReportComponent, pathMatch: 'full' },
      { path: 'print-invoice/:id', component: PrintInvoiceComponent, pathMatch: 'full' },
      { path: 'print-payment/:id', component: PrintPaymentComponent, pathMatch: 'full' },
      { path: 'print-prescription/:id/:prescriptionIDs', component: PrintPrescriptionComponent, pathMatch: 'full' },
      { path: 'print-order/:id/:serviceRequestIDs', component: PrintOrderComponent, pathMatch: 'full' },
      { path: 'print-provider-contract/:id', component: PrintPrescriptionComponent, pathMatch: 'full' },

      { path: 'print/:id/:from_date/:to_date/:pk', component: PrintReportComponent, pathMatch: 'full' },
      { path: 'print-sticker/:id/:serviceID', component: PrintStickerComponent, pathMatch: 'full' },
    ]
  },
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, { useHash: true });


