import { Component, Input } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { Payment } from '../models/payment.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html'
})
export class PaymentsComponent {

  constructor(public lookupService: LookupService, public service: PaymentService, public datepipe: DatePipe, private notificationService: NotificationService) { }
  options: any = { format: 'YYYY-MM-DD' };

  ngOnInit() {
    this.lookupService.getPaymentMethods();

    this.onSearch();
  }

  model = new Payment();
  modelSearch = new Payment();

  total: number = 0;
  vat: number = 0;

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;

    if (this.modelSearch.fromDate != null)
      this.modelSearch.fromDate = this.datepipe.transform(this.modelSearch.fromDate, 'yyyy-MM-dd');

    if (this.modelSearch.toDate != null)
      this.modelSearch.toDate = this.datepipe.transform(this.modelSearch.toDate, 'yyyy-MM-dd');

    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

}
