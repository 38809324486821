import { Component, Input } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { AssesmentFormService } from '../services/assesment.form.service';
import { AppointmentService } from '../services/appointment.service';
import { Appointment } from '../models/appointment.model';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html'
})
export class AppointmentDetailComponent {

  constructor(public service: AppointmentService, private notificationService: NotificationService) { }

  model: Appointment;
  @Input() appointmentID: string;

  ngOnInit() {

    this.service.get(this.appointmentID).subscribe(
        res => {
          this.model = res as Appointment;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );
  }


}
