import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate() {
    //return true;
    let token = localStorage.getItem('access_token');
    
    if (token && token!=undefined && token !='undefined') {
      return true;
    }

    this.router.navigate(['login']);
    
    return false;
  }
}
