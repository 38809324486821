import { Component, Input } from '@angular/core';
import { InvoiceDetailService } from '../services/invoice.detail.service';
import { InvoiceDetail } from '../models/invoice.detail.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { RoleService } from '../services/role.service';
import { Privilege } from '../models/privilege.model';

@Component({
  selector: 'app-invoice-detail-list',
  templateUrl: './invoice-detail-list.component.html'
})
export class InvoiceDetailListComponent {

  constructor(public roleService: RoleService, public lookupService: LookupService, public service: InvoiceDetailService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  admin: boolean = false;
  accountant: boolean = false;

  ngOnInit() {
    this.service.getByInvoice();
    this.admin = this.roleService.isAuthorized(Privilege.Admin);
    this.accountant = this.roleService.isAuthorized(Privilege.Accountant);

  }

  model = new InvoiceDetail();
  modelSearch = new InvoiceDetail();

  onDelete(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByInvoice();
      },
      err => { console.log(err); }
    );

  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.search(this.modelSearch);
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.model = Object.assign({}, model);
  }

}

