import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  public username: string;
  public password: string;
  public error: string;

  
  ngOnInit() {

    this.auth.logout();
  }

  constructor(private auth: AuthService, private router: Router, private notificationService: NotificationService) { }


  public submit() {
    this.auth.login(this.username, this.password)
      .pipe(first())
      .subscribe(
        result => this.router.navigate(['']),
        err => this.notificationService.notifyFailure()
      );
    this.router.navigate(['']);
  }
}
