import { Component, Input } from '@angular/core';
import { ProblemService } from '../services/problem.service';



@Component({
  selector: 'app-problem-view',
  templateUrl: './problem-view.component.html'
})
export class ProblemViewComponent {
  @Input() patientID: number;

  constructor(public service: ProblemService) {

  }

  ngOnInit() {
    this.service.patientID = this.patientID;
  }


}
