import { Component } from '@angular/core';
import { SpecializationService } from '../services/specialization.service';

@Component({
  selector: 'app-specialization-pagination',
  templateUrl: './specialization-pagination.component.html'
})
export class SpecializationPaginationComponent {
  constructor(public service: SpecializationService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
