export class City {

  cityID: number;
  stateID: number;
  cityNameEn: string;
  cityNameAr: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
  deliveryCharges: number;
}
