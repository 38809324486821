import { Component } from '@angular/core';
import { OnlineAppointmentService } from '../services/online.appointment.service';

@Component({
  selector: 'app-online-appointment-pagination',
  templateUrl: './online-appointment-pagination.component.html'
})
export class OnlineAppointmentPaginationComponent {
  constructor(public service: OnlineAppointmentService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
