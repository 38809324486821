import { Component } from '@angular/core';
import { InvoiceDetailService } from '../services/invoice.detail.service';

@Component({
  selector: 'app-invoice-detail-pagination',
  templateUrl: './invoice-detail-pagination.component.html'
})
export class InvoiceDetailPaginationComponent {
  constructor(public service: InvoiceDetailService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    //this.service.refreshList();
  }
}
