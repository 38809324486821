import { Component, Input } from '@angular/core';
import { AssesmentFormService } from '../services/assesment.form.service';

@Component({
  selector: 'app-nurse-form',
  templateUrl: './nurse-form.component.html'
})
export class NurseFormComponent {
  @Input() visitID: number;

  constructor(private assesmentFormService: AssesmentFormService) {
  }

  ngOnInit() {
    this.assesmentFormService.getByVisit(this.visitID);
  }

}
 
