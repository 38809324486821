import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';

import { DatePipe } from '@angular/common';

import { AppointmentService } from '../services/appointment.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
})
export class BoardComponent {

  

  date: string = null;
  today: Date;
  tomorrow: Date;
  yesterday: Date;
  visitDate: string = null;
  appointmentDate: string = null;

  options: any = { format: 'YYYY-MM-DD' };

  constructor(public visitService: VisitService, public appointmentService: AppointmentService, private datePipe: DatePipe) {


  }

  ngOnInit() {
    this.onToday();
  }

  onYesterday() {
    var date = new Date();
    date.setDate(date.getDate() - 1);

    this.yesterday = date;
  }

  onTomorrow() {

    var date = new Date();
    date.setDate(date.getDate() + 1);

    this.tomorrow = date;
  }

  onToday() {
    this.today = new Date();
  }

  onDate() {
    this.visitDate = null;
    this.appointmentDate = null;

    var tmpDate = this.datePipe.transform(this.date, 'yyyy-MM-dd')
    if (new Date(tmpDate) >= new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd')))
      this.appointmentDate = tmpDate;
    else
      this.visitDate = tmpDate;
  }
}
