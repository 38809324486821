import { Component, Input } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { AppointmentService } from '../services/appointment.service';
import { Appointment } from '../models/appointment.model';
import { Router } from '@angular/router';
import { RoleService } from '../services/role.service';
import { Privilege } from '../models/privilege.model';

@Component({
  selector: 'app-visit-form',
  templateUrl: './visit-form.component.html'
})
export class VisitFormComponent {
  constructor(public lookupService: LookupService, private router: Router, private roleService: RoleService, public service: VisitService, public appointmentService: AppointmentService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() model: Visit;

  options: any = { format: 'YYYY-MM-DD', minDate: new Date() };
  optionsTime: any = { format: 'LT' };

  ngOnInit() {
    this.lookupService.getSpecializations();
    this.lookupService.getStaffs();
    this.lookupService.getTodayAppointments();

    if (this.roleService.isAuthorized(Privilege.Admin))
      this.options = { format: 'YYYY-MM-DD' }
  }

  filterAppointment() {
    var term = $('#Filter').val();
    if (term.toString().length >= 2)
      this.lookupService.filterAppointment(term);
  }

  onAppointmentChanged(data: { value: string[] }): void {
    if (!data)
      return;

    this.appointmentService.get(data).subscribe(
      res => {
        var appointment = res as Appointment;

        this.model.patientID = appointment.patientID;
        this.model.staffID = appointment.assignee;
        this.model.specializationID = appointment.specializationID;
        this.model.complaint = appointment.complaint;
      },
      err => { console.log(err); this.notificationService.notifyFailure(); }
    );
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;

    this.service.model = this.model;
    if (!this.model.visitID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        /*this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();*/

        this.router.navigate(['visit-detail/' + res]);
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }

}
 
