export class Report {
  assignee: number;
  staffID: number;
  serviceTypeID: number;
  paymentMethodID: number;
  status: string;
  specializationID: number;
  serviceProviderID: number;
  pharmacyID: number;
  sponsorID: number;
  membershipTypeID: number;
  fromDate: string;
  toDate: string;
}
