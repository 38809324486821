import { Component } from '@angular/core';
import { BookingService } from '../services/booking.service';

@Component({
  selector: 'app-booking-pagination',
  templateUrl: './booking-pagination.component.html'
})
export class BookingPaginationComponent {
  constructor(public service: BookingService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
