import { Prescription } from '../models/prescription.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { vwPrescription } from '../models/vwprescription.model';
import { RoleService } from './role.service';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {
  model: Prescription = null;
  modelSearch: Prescription = null;
  modelReport: Report = null;
  list: vwPrescription[];
  listReport: vwPrescription[];
  baseUrl: string;

  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number;
  pages: number[];

  visitID: number;
  patientID: number;

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    this.model.visitID = this.visitID;
    return this.http.post(this.baseUrl + "api/Prescription", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    this.model.visitID = this.visitID;
    return this.http.put(this.baseUrl + "api/Prescription/" + this.model.prescriptionID, this.model);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "api/Prescription/" + id);
  }

  do() {
    this.model.doneBy = this.roleService.getStaffID();
    this.model.visitID = this.visitID;
    return this.http.put(this.baseUrl + "api/Prescription/Do/" + this.model.prescriptionID, this.model);
  }

  undo() {
    this.model.doneBy = this.roleService.getStaffID();
    this.model.visitID = this.visitID;
    return this.http.put(this.baseUrl + "api/Prescription/Undo/" + this.model.prescriptionID, this.model);
  }

  getByVisit() {
    let params = new HttpParams().set("visitID", this.visitID.toString());

    this.http.get(this.baseUrl + "api/Prescription/GetByVisit", { params: params })
      .toPromise()
      .then(res => this.list = res as vwPrescription[]);
  }

  report() {

    this.listReport = null;

    let params = new HttpParams();

    if (this.modelReport.fromDate != null)
      params = params.set("fromDate", this.modelReport.fromDate);

    if (this.modelReport.toDate != null)
      params = params.set("toDate", this.modelReport.toDate);


    this.http.get(this.baseUrl + "api/Prescription/Report", { params: params })
      .toPromise()
      .then(res => this.listReport = res as vwPrescription[]);
  }

  search() {

    this.list = null;

    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    if (this.modelSearch.visitID != null)
      params = params.set("visitID", this.modelSearch.visitID.toString());

    if (this.modelSearch.patientID != null)
      params = params.set("patientID", this.modelSearch.patientID.toString());

    this.http.get(this.baseUrl + "api/Prescription/Search", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  getHistory() {

    this.list = null;

    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    params = params.set("patientID", this.patientID.toString());

    this.http.get(this.baseUrl + "api/Prescription/GetHistory", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  loadData(res) {
    this.list = res.item1 as vwPrescription[];
    this.paginate(res.item2);
  }

  paginate(totalRecords) {

    var pageSize = this.pageSize;
    var currentPage = this.currentPage;
    // calculate total pages
    var totalPages = Math.ceil(totalRecords / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalRecords - 1);

    this.totalPages = totalPages;
    this.totalRecords = totalRecords;

    // create an array of pages to ng-repeat in the pager control
    this.pages = [];
    var i;
    for (i = startPage; i <= endPage; i++)
      this.pages.push(i);
  };
}
