import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ServiceTypeService } from '../services/service.type.service';
import { ServiceType } from '../models/service.type.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-service-type-form',
  templateUrl: './service-type-form.component.html'
})
export class ServiceTypeFormComponent {
  constructor(public service: ServiceTypeService, private notificationService: NotificationService) { }

  @Input() model: ServiceType;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  submitted = false;

  ngOnInit() {
    //this.notificationService.showDatePicker();
  }

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.model.serviceTypeID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.closeModal.emit(null);
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.closeModal.emit(null);
      },
      err => {
        console.log(err);
      }
    )
  }


}

