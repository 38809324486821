import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';

@Component({
  selector: 'app-visit-pagination',
  templateUrl: './visit-pagination.component.html'
})
export class VisitPaginationComponent {
  constructor(public service: VisitService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
