import { Component } from '@angular/core';

import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';
import * as $ from 'jquery';
import { PatientService } from '../services/patient.service';
import { Patient } from '../models/patient.model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html'
})
export class RegistrationComponent {
  constructor(public patientService: PatientService, public lookupService: LookupService, private notificationService: NotificationService) { }

  patientID: number;
  appointmentID: number;

  currentTab = 'Patient';

  navigateNext() {
    switch (this.currentTab) {
      case 'Patient':
        this.navigate('Appointment');
        break;
      case 'Appointment':
        this.navigate('Confirmation');
        break;
    }
  }

  navigatePrevious() {
    switch (this.currentTab) {
      case 'Appointment':
        this.navigate('Patient');
        break;
      case 'Confirmation':
        this.navigate('Appointment');
        break;
    }
  }

  navigate(tab) {
    $('#Panel' + this.currentTab + ",#Panel" + tab).slideToggle();
    this.currentTab = tab;
  }

  filterPatient() {
    var term = $('#Filter').val();
    if (term.toString().length >= 3)
      this.lookupService.filterPatient(term);
  }

  patient: Patient;

  getPatientByID() {
    var id = $('#PatientID').val();
    $('#LoaderMini').show();
    if (id.toString().length >= 1) {
      this.patientService.get(id).subscribe(
        res => {
          this.patient = res as Patient;
          this.patientID = this.patient.patientID;
          $('#LoaderMini').hide();
        },
        err => { console.log(err); this.notificationService.notifyFailure(); $('#LoaderMini').hide();}
      );
    }
  }

  onNewAppointment() {
    $('#PanelSelectPatient, #PanelPatientDetail').slideToggle();
  }

  onNewPatient() {
    $('#PanelSelectPatient, #PanelNewPatient').slideToggle();
  }

  onPatientCreated(event) {
    this.patientID = event;
  }

  onAppointmentCreated(event) {
    this.appointmentID = event;
  }

  onCancel() {
    if (!confirm('Are you sure?'))
      return;

    $('#PanelPatientDetail, #PanelNewPatient').hide();
    $('#PanelSelectPatient').show();
  }

  onFinish() {

    this.patientID = null;
    this.appointmentID = null;

    $('#PanelPatientDetail, #PanelNewPatient').hide();
    $('#PanelSelectPatient').show();

    this.notificationService.notifySuccess();

    this.navigate('Patient');
  }

}
