import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SponsorSpecializationService } from '../services/sponsor.specialization.service';
import { SponsorSpecialization } from '../models/sponsor.specialization.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-sponsor-specialization-form',
  templateUrl: './sponsor-specialization-form.component.html'
})
export class SponsorSpecializationFormComponent {
  constructor(public lookupService: LookupService, public service: SponsorSpecializationService, private notificationService: NotificationService) { }

  @Input() model: SponsorSpecialization;

  submitted = false;

  ngOnInit() {
    this.lookupService.getSpecializations();
  }

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;

    if (!this.model.sponsorSpecializationID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.model.specializationID = null;
        this.model.price = null;
        this.service.search();

        $('#PanelForm').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.service.search();

        $('#PanelForm').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }
}

