export class Payment {

  paymentID: number;
  invoiceID: number;
  paymentNo: string;
  paymentMethodID: number;
  amount: number;
  paymentDate: Date;
  remarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  fromDate: string;
  toDate: string;
  invoiceNo: string;

  title: string;
  count: number;
}
