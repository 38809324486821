import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-visit-history',
  templateUrl: './visit-history.component.html'
})
export class VisitHistoryComponent {

  constructor(public lookupService: LookupService, public service: VisitService, public datepipe: DatePipe) { }

  ngOnInit() {
    //this.onSearch();
  }

  options: any = { format: 'YYYY-MM-DD' };

  modelSearch = new Visit();

  onSearch() {
    if (this.modelSearch.fromDate != null)
      this.modelSearch.fromDate = this.datepipe.transform(this.modelSearch.fromDate, 'yyyy-MM-dd');

    if (this.modelSearch.toDate != null)
      this.modelSearch.toDate = this.datepipe.transform(this.modelSearch.toDate, 'yyyy-MM-dd');

    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }
}

