import { Component } from '@angular/core';
import { AlergyTypeService } from '../services/alergy.type.service';

@Component({
  selector: 'app-alergy-type-pagination',
  templateUrl: './alergy-type-pagination.component.html'
})
export class AlergyTypePaginationComponent {
  constructor(public service: AlergyTypeService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
