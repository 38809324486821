import { Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from '../services/appointment.service';
import { VisitService } from '../services/visit.service';
import { ServiceRequestService } from '../services/service.request.service';
import { PrescriptionService } from '../services/prescription.service';
import { InvoiceService } from '../services/invoice.service';
import { PaymentService } from '../services/payment.service';
import { Location } from '@angular/common';
import { Report } from '../models/report.model';
import { InvoiceDetailService } from '../services/invoice.detail.service';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html'
})
export class PrintReportComponent {

  constructor(public serviceAppointment: AppointmentService, public visitService: VisitService, public serviceRequestService: ServiceRequestService,
    public prescriptionService: PrescriptionService, public invoiceService: InvoiceService, public invoiceDetailService: InvoiceDetailService, public paymentService: PaymentService,
    private route: ActivatedRoute, private location: Location) { }

  type: string = '';

  model: Report = new Report();
  ngOnInit() {

    var pk = 'null';

    if (this.route.snapshot.paramMap.has('id'))
      this.type = this.route.snapshot.paramMap.get('id');

    if (this.route.snapshot.paramMap.has('from_date')) {
      this.model.fromDate = this.route.snapshot.paramMap.get('from_date');

    }

    if (this.route.snapshot.paramMap.has('to_date')) {
      this.model.toDate = this.route.snapshot.paramMap.get('to_date');
    }

    if (this.route.snapshot.paramMap.has('pk')) {
      pk = this.route.snapshot.paramMap.get('pk');
    }

    switch (this.type) {
      case 'Appointment':
        if (pk && pk != 'null')
          this.model.assignee = parseInt(pk);
        this.serviceAppointment.modelReport = this.model;
        this.serviceAppointment.report();
        break;
      case 'Visit':
        if (pk && pk != 'null')
          this.model.staffID = parseInt(pk);
        this.visitService.modelReport = this.model;
        this.visitService.report();
        break;
      case 'Service Order':
        if (pk && pk != 'null')
          this.model.serviceTypeID = parseInt(pk);
        this.serviceRequestService.modelReport = this.model;
        this.serviceRequestService.report();
        break;
      case 'Pharmacy Order':
        this.prescriptionService.modelReport = this.model;
        this.prescriptionService.report();
        break;
      case 'Invoice':
        if (pk && pk != 'null')
          this.model.status = pk;
        this.invoiceService.modelReport = this.model;
        this.invoiceService.report();
        break;
      case 'Payment':
        if (pk && pk != 'null')
          this.model.paymentMethodID = parseInt(pk);
        this.paymentService.modelReport = this.model;
        this.paymentService.report();
        break;
      case 'Service Claim':
        if (pk && pk != 'null')
          this.model.serviceProviderID = parseInt(pk);
        this.invoiceDetailService.modelReport = this.model;
        this.invoiceDetailService.report();
        break;
      case 'Drug Claim':
        if (pk && pk != 'null')
          this.model.pharmacyID = parseInt(pk);
        this.invoiceDetailService.modelReport = this.model;
        this.invoiceDetailService.report();
        break;
      case 'Sponsor Claim':
        if (pk && pk != 'null')
          this.model.sponsorID = parseInt(pk);
        this.invoiceDetailService.modelReport = this.model;
        this.invoiceDetailService.report();
        break;
      case 'Sponsor Claim Membership':
        if (pk && pk != 'null')
          this.model.membershipTypeID = parseInt(pk);
        this.invoiceDetailService.modelReport = this.model;
        this.invoiceDetailService.report();
        break;
    }

  }

  goBack(): void {
    this.location.back();
  }
}
