export class ServiceType {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  serviceTypeID: number;
  serviceTypeName: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

}

export enum ServiceTypes {

  Laboratory = 1,
  Radiology,
  Operation,
  ECG,
  OtherServices,
}


