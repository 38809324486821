import { Component } from '@angular/core';
import { ServiceRequestService } from '../services/service.request.service';

@Component({
  selector: 'app-service-request-pagination',
  templateUrl: './service-request-pagination.component.html'
})
export class ServiceRequestPaginationComponent {
  constructor(public service: ServiceRequestService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
