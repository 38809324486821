import { Component } from '@angular/core';
import { InvoiceService } from '../services/invoice.service';

@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html'
})
export class InvoiceReportComponent {
  constructor(public service: InvoiceService) { } 
}

