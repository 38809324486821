import { Problem } from '../models/problem.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root'
})
export class ProblemService {

  model: Problem = null;
  list: Problem[];
  baseUrl: string;

  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number;
  pages: number[];

  patientID: number;

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    this.model.patientID = this.patientID;
    return this.http.post(this.baseUrl + "api/ProblemList", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    this.model.patientID = this.patientID;
    return this.http.put(this.baseUrl + "api/ProblemList/" + this.model.problemListID, this.model);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "api/ProblemList/" + id);
  }


  get(id) {
    return this.http.get(this.baseUrl + "api/ProblemList/" + id);
  }

  getByPatient() {
    this.list = null;

    this.http.get(this.baseUrl + "api/ProblemList/GetByPatient/" + this.patientID)
      .toPromise()
      .then(res => this.list = res as Problem[]);
  }


}
