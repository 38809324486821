import { Component, Input, SimpleChanges } from '@angular/core';
import { VisitService } from '../services/visit.service';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { Visit } from '../models/visit.model';


@Component({
  selector: 'app-visit-list-date',
  templateUrl: './visit-list-date.component.html'
})
export class VisitListDateComponent {

  constructor(public service: VisitService, public datepipe: DatePipe) { }

  @Input() date: Date;

  ngOnChanges(changes: SimpleChanges) {

    this.date = changes.date.currentValue;
    this.onSearch();
  }

  ngOnInit() {
    this.onSearch();
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = new Visit();
    this.service.modelSearch.fromDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    this.service.modelSearch.toDate = this.service.modelSearch.fromDate
    this.service.search();
  }
}

