import { Drug } from '../models/drug.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root'
})
export class DrugService {

  model: Drug = null;
  modelSearch: Drug;
  list: Drug[];
  baseUrl: string;

  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number;
  pages: number[];

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    return this.http.post(this.baseUrl + "api/Drug", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    return this.http.put(this.baseUrl + "api/Drug/" + this.model.drugID, this.model);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "api/Drug/" + id);
  }

  archive(id) {
    return this.http.get(this.baseUrl + "api/Drug/Archive/" + id);
  }

  toggleCommon(id) {
    return this.http.get(this.baseUrl + "api/Drug/ToggleCommon/" + id);
  }

  search() {
    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    if (this.modelSearch.genericName != null)
      params = params.set("genericName", this.modelSearch.genericName);

    if (this.modelSearch.tradeName != null)
      params = params.set("tradeName", this.modelSearch.tradeName);


    if (this.modelSearch.pack != null)
      params = params.set("pack", this.modelSearch.pack);


    if (this.modelSearch.vendor != null)
      params = params.set("vendor", this.modelSearch.vendor);


    if (this.modelSearch.countryID != null)
      params = params.set("countryID", this.modelSearch.countryID.toString());

    this.http.get(this.baseUrl + "api/Drug/Search", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  loadData(res) {
    this.list = res.item1 as Drug[];
    this.paginate(res.item2);
  }

  paginate(totalRecords) {

    var pageSize = this.pageSize;
    var currentPage = this.currentPage;
    // calculate total pages
    var totalPages = Math.ceil(totalRecords / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalRecords - 1);

    this.totalPages = totalPages;
    this.totalRecords = totalRecords;

    // create an array of pages to ng-repeat in the pager control
    this.pages = [];
    var i;
    for (i = startPage; i <= endPage; i++)
      this.pages.push(i);
  };
}
