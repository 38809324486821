import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { AssesmentFormService } from '../services/assesment.form.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-print-prescription',
  templateUrl: './print-prescription.component.html'
})
export class PrintPrescriptionComponent {

  constructor(private location: Location, private route: ActivatedRoute, public service: VisitService, public assesmentFormService: AssesmentFormService, private notificationService: NotificationService) { }

  model: Visit;
  id: string;
  prescriptionIDs = null;
  ngOnInit() {
 
    if (this.route.snapshot.paramMap.has('id')) {
      this.id = this.route.snapshot.paramMap.get('id');

      var prescriptions = this.route.snapshot.paramMap.get('prescriptionIDs');

      if (prescriptions != 'null') {
        this.prescriptionIDs = prescriptions.split(',');

        this.prescriptionIDs = prescriptions.split(',').map(function (item) {
          return parseInt(item, 10);
        });
      }

      this.service.get(this.id).subscribe(
        res => {
          this.model = res as Visit;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );

    }

  }
}

