import { Component, Input } from '@angular/core';
import { ProblemService } from '../services/problem.service';
import { Problem } from '../models/problem.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: 'app-problem-list',
  templateUrl: './problem-list.component.html'
})
export class ProblemListComponent {

  constructor(public service: ProblemService, public lookupService: LookupService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  ngOnInit() {
    this.service.getByPatient();
  }

  model = new Problem();
  modelSearch = new Problem();

  onDelete(id) {
    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByPatient();
      },
      err => { console.log(err); }
    );
  }

  onNew() {
    this.model = new Problem();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.model = Object.assign({}, model);
  }

}

