import { Component, Input } from '@angular/core';
import { AssesmentFormService } from '../services/assesment.form.service';
import { AssesmentForm } from '../models/assesment.form.model';
import { VisitService } from '../services/visit.service';

@Component({
  selector: 'app-doctor-form-detail',
  templateUrl: './doctor-form-detail.component.html'
})
export class DoctorFormDetailComponent {
  @Input() visitID: number;
  ids: number[];
  BASICINFORMATION: boolean = false;
  CHIEFCOMPLAINT: boolean = false;
  REVIEWOFSYSTEMS: boolean = false;
  HEALTHSTATUS: boolean = false;
  VitalSigns: boolean = false;
  Measurements: boolean = false;
  ENT: boolean = false;
  Respirotery: boolean = false;
  MEDICALDECISIONMAKING: boolean = false;
  PLAN: boolean = false;
  EDUCATION: boolean = false;

  constructor(public assesmentFormService: AssesmentFormService, public service: VisitService) { }

  show(ids: number[]) {

    if (this.ids.length == 0)
      return false;

    for (let id of ids) {
      if (this.ids.indexOf(id) >= 0)
        return true;
    }

    return false;
  }

  ngOnInit() {
    this.service.getDiagnosis(this.visitID);
    this.assesmentFormService.get(this.visitID)
      .toPromise()
      .then(res => this.loadData(res));
  }


  loadData(res) {
    this.ids = (res as AssesmentForm[]).map(item => { return item.formFieldID });

    this.BASICINFORMATION = this.show(new Array(1, 2, 3));

    this.CHIEFCOMPLAINT = this.show(new Array(4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14));
    this.REVIEWOFSYSTEMS = this.show(new Array(16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30));
    this.HEALTHSTATUS = this.show(new Array(31, 32, 33, 34, 35, 36, 37));
    this.VitalSigns = this.show(new Array(38, 39, 40, 41));
    this.Measurements = this.show(new Array(42, 43, 44, 45, 46, 47, 48));
    this.ENT = this.show(new Array(49, 50, 51, 52, 53, 54, 55, 56));
    this.Respirotery = this.show(new Array(57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72));
    this.MEDICALDECISIONMAKING = this.show(new Array(73, 74));
    this.PLAN = this.show(new Array(75, 76));
    this.EDUCATION = this.show(new Array(77));

  }
}
 
