import { Component } from '@angular/core';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html'
})
export class PaymentReportComponent {

  constructor(public service: PaymentService) { }
  
}
