import { Component } from '@angular/core';
import { PaymentMethodService } from '../services/payment.method.service';

@Component({
  selector: 'app-payment-method-pagination',
  templateUrl: './payment-method-pagination.component.html'
})
export class PaymentMethodPaginationComponent {
  constructor(public service: PaymentMethodService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
