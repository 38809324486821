import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';

import { Location } from '@angular/common';
import { ServiceRequestService } from '../services/service.request.service';

@Component({
  selector: 'app-print-order',
  templateUrl: './print-order.component.html'
})
export class PrintOrderComponent {

  constructor(private location: Location, private route: ActivatedRoute, public service: VisitService, public serviceRequestService: ServiceRequestService, private notificationService: NotificationService) { }

  model: Visit;
  id: string;
  serviceRequestIDs = null;

  ngOnInit() {

    if (this.route.snapshot.paramMap.has('id')) {
      this.id = this.route.snapshot.paramMap.get('id');

      var serviceRequests = this.route.snapshot.paramMap.get('serviceRequestIDs');

      if (serviceRequests != 'null') {
        this.serviceRequestIDs = serviceRequests.split(',');

        this.serviceRequestIDs = serviceRequests.split(',').map(function (item) {
          return parseInt(item, 10);
        });
      }

      this.service.get(this.id).subscribe(
        res => {
          this.model = res as Visit;
          this.serviceRequestService.visitID = this.model.visitID;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );
    }

  }

  goBack(): void {
    this.location.back();
  }
}
