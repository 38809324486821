import { Component } from '@angular/core';
import { BookingService } from '../services/booking.service';
import { Booking } from '../models/booking.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { RoleService } from '../services/role.service';
import { Privilege } from '../models/privilege.model';

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html'
})
export class BookingListComponent {

  constructor(public roleService: RoleService, public lookupService: LookupService, public service: BookingService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  registration: boolean = false;

  ngOnInit() {
    this.registration = this.roleService.isAuthorized(Privilege.Registration) || this.roleService.isAuthorized(Privilege.Admin);

    this.onSearch();

    this.lookupService.getStates();
    this.lookupService.getCities();
    this.lookupService.getGenders();
  }

  model = new Booking();
  modelSearch = new Booking();

  onDelete(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onNew() {
    this.model = new Booking();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();
    console.log("model.dob", model.dob);

    model.dOB = this.datepipe.transform(model.dob, 'yyyy-MM-dd');
    console.log("model.dOB", model.dOB);
    this.lookupService.getCitiesByState(model.stateID);
    this.model = Object.assign({}, model);
  }

  onVerified(values: any, bookingID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new Booking();
    this.service.model.bookingID = bookingID;

    if (values.currentTarget.checked)
      this.service.verify().subscribe(
        res => {
          this.notificationService.notifySuccess();

          this.onSearch();

        },
        err => { console.log(err); }
      );
  }

}

