import { Component, Input } from '@angular/core';
import { ServiceProviderService } from '../services/service.provider.service';
import { ServiceProvider } from '../models/service.provider.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-service-provider-detail',
  templateUrl: './service-provider-detail.component.html'
})
export class ServiceProviderDetailComponent {
  constructor(public service: ServiceProviderService, private notificationService: NotificationService) { }

  @Input() id: number;
  model: ServiceProvider;

  ngOnInit() {

      this.service.get(this.id).subscribe(
        res => {
          this.service.model = res as ServiceProvider;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );
  }

}
 
