import { Component} from '@angular/core';
import { ServiceRequestService } from '../services/service.request.service';

@Component({
  selector: 'app-service-request-report',
  templateUrl: './service-request-report.component.html'
})
export class ServiceRequestReportComponent {
  constructor(public service: ServiceRequestService) { }
}

