import { Component } from '@angular/core';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-payment-pagination',
  templateUrl: './payment-pagination.component.html'
})
export class PaymentPaginationComponent {
  constructor(public service: PaymentService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
