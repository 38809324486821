import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PaymentMethodService } from '../services/payment.method.service';
import { PaymentMethod } from '../models/payment.method.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-payment-method-form',
  templateUrl: './payment-method-form.component.html'
})
export class PaymentMethodFormComponent {
  constructor(public service: PaymentMethodService, private notificationService: NotificationService) { }

  @Input() model: PaymentMethod;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  submitted = false;

  ngOnInit() {
    //this.notificationService.showDatePicker();
  }

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.model.paymentMethodID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.closeModal.emit(null);
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.closeModal.emit(null);
      },
      err => {
        console.log(err);
      }
    )
  }
}

