export class Specialization {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  specializationID: number;
  specializationNameEn: string;
  specializationNameAr: string;
  price: number;
  costPrice: number;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

}
