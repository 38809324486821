import { Component, Input } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';



@Component({
  selector: 'app-prescription-view',
  templateUrl: './prescription-view.component.html'
})
export class PrescriptionViewComponent {
  @Input() visitID: number;
  @Input() prescriptionIDs;

  constructor(public service: PrescriptionService) {

  }

  ngOnInit() {
    this.service.visitID = this.visitID;
  }


}
