import { Component, Input } from '@angular/core';
import { SponsorServiceService } from '../services/sponsor.service.service';

import { SponsorService } from '../models/sponsor.service.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-sponsor-service-list',
  templateUrl: './sponsor-service-list.component.html'
})
export class SponsorServiceListComponent {
  @Input() id: number;

  constructor(public lookupService: LookupService, public service: SponsorServiceService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.service.sponsorID = this.id;
    this.lookupService.getServiceTypes();
    this.onSearch();
  }

  model = new SponsorService();
  modelSearch = new SponsorService();
  modelPush = new SponsorService();


  onServiceTypeChanged(data: { value: string[] }): void {
    this.lookupService.getCategoriesByType(data);
  }

  onNew() {
    this.model = new SponsorService();
    $("#PanelSearch, #PanelPush").hide();
    $('#PanelForm').slideToggle();
  }

  onPush() {
    this.modelPush = new SponsorService();
    $("#PanelSearch, #PanelForm").hide();
    $('#PanelPush').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm').slideToggle();

    this.lookupService.getCategoriesByType(model.serviceTypeID);
    this.lookupService.getSevicesByCategory(model.serviceCategoryID);

    this.model = Object.assign({}, model);
  }

  onStatus(values: any, sponsorServiceID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new SponsorService();
    this.service.model.sponsorServiceID = sponsorServiceID;

    if (values.currentTarget.checked)
      this.service.updateStatus(1).subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
    else
      this.service.updateStatus(0).subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
  }

  onArchive(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );

  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onSubmitPush() {
    if (!this.modelPush.serviceCategoryID)
      this.modelPush.serviceCategoryID = 0;

    this.service.model = this.modelPush;

    this.service.push().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }
}

