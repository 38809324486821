import { Component, Input } from '@angular/core';
import { OnlineAppointmentService } from '../services/online.appointment.service';
import { OnlineAppointment } from '../models/online.appointment.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { PatientService } from '../services/patient.service';
import { Patient } from '../models/patient.model';

@Component({
  selector: 'app-online-appointment-form',
  templateUrl: './online-appointment-form.component.html'
})
export class OnlineAppointmentFormComponent {
  constructor(public lookupService: LookupService, public patientService: PatientService, public service: OnlineAppointmentService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() model: OnlineAppointment;

  options: any = { format: 'YYYY-MM-DD' };
  optionsTime: any = { format: 'LT' };
  filter: string;

  ngOnInit() {
    this.lookupService.getStates();
    this.lookupService.getGenders();
    this.lookupService.getSpecializations();
    this.lookupService.getStaffs();
  }

  onPatientChanged(data: { value: string[] }): void {
    if (!data)
      return;

    this.patientService.get(data).subscribe(
      res => {
        var patient = res as Patient;

        this.model.stateID = patient.stateID;
        this.lookupService.getCitiesByState(patient.stateID);
        this.model.cityID = patient.cityID;
      },
      err => { console.log(err); this.notificationService.notifyFailure(); }
    );
  }

  filterPatient() {
    var term = $('#Filter').val();
    if (term.toString().length >= 3)
      this.lookupService.filterPatient(term);
  }

  getCitiesByState(id) {
    this.lookupService.getCitiesByState(id);
  }

  onStateChanged(data: { value: string[] }): void {
    this.getCitiesByState(data);
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;
    this.model.appointmentTime = this.datepipe.transform(this.model.appointmentTime, 'HH:mm');

    this.service.model = this.model;
    if (!this.model.onlineAppointmentID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }


}

