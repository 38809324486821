import { Component } from '@angular/core';
import { GenericService } from '../services/generic.service';

@Component({
  selector: 'app-generic-pagination',
  templateUrl: './generic-pagination.component.html'
})
export class GenericPaginationComponent {
  constructor(public service: GenericService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
