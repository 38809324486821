import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';
@Component({
  selector: 'app-service-category',
  templateUrl: './service-category.component.html'
})
export class ServiceCategoryComponent {
  constructor(private service: RoleService, private router: Router) { }

  ngOnInit() {
    /*if (!this.service.isAuthorized(Privilege.ServiceCategory))
      this.router.navigate(['']);*/
  }
}
