import { Component } from '@angular/core';
import { DiagnosisService } from '../services/diagnosis.service';

@Component({
  selector: 'app-diagnosis-pagination',
  templateUrl: './diagnosis-pagination.component.html'
})
export class DiagnosisPaginationComponent {
  constructor(public service: DiagnosisService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
