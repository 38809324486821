import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html'
})
export class PaymentMethodComponent {
  constructor(private service: RoleService, private router: Router) { }

  ngOnInit() {
    /*if (!this.service.isAuthorized(Privilege.PaymentMethod))
      this.router.navigate(['']);*/
  }
}
