import { Component, Input } from '@angular/core';
import { TaskService } from '../services/task.service';



@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html'
})
export class TaskViewComponent {
  @Input() visitID: number;

  constructor(public service: TaskService) {

  }

  ngOnInit() {
    this.service.visitID = this.visitID;
  }


}
