import { Component, Input } from '@angular/core';
import { TaskService } from '../services/task.service';
import { Task } from '../models/task.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html'
})
export class TaskListComponent {

  constructor(public service: TaskService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  ngOnInit() {
    this.service.getByVisit();
  }

  model = new Task();
  modelSearch = new Task();

  onDelete(id) {
    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByVisit();

      },
      err => { console.log(err); }
    );
  }

  onNew() {
    this.model = new Task();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.model = Object.assign({}, model);
  }

  onDo(values: any, visitTaskID): void {
    this.service.model = new Task();
    this.service.model.visitTaskID = visitTaskID;

    if (values.currentTarget.checked)
      this.service.do().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
    else
      this.service.undo().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
  }

}

