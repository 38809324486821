export class Pharmacy {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  pharmacyID: number;
  pharmacyName: string;
  pharmacyType: boolean;
  stateID: number;
  cityID: number;
  address: string;
  location: string;
  status: boolean;
  username: string;
  password: string;
  costPrice: number;
  pharmacyLocationType: boolean;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  email: string;
  phone: string;
  mobileNo: string;
}
