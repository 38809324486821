import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StaffService } from '../services/staff.service';
import { Staff } from '../models/staff.model';
import { RoleService } from '../services/role.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-staff-password',
  templateUrl: './staff-password.component.html'
})
export class StaffPasswordComponent {
  constructor(public service: StaffService, private notificationService: NotificationService, private roleService: RoleService) { }


  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  model: Staff = new Staff();

  old_password: string;

  ngOnInit() {

    this.service.get(this.roleService.getStaffID()).subscribe(
      res => {
        this.model = res as Staff;
        this.model.password = null;
      },
      err => {
        console.log(err);
        this.notificationService.notifyFailure();
      }
    );
  }

  onSubmit() {

    this.service.model = this.model;

    this.service.put().subscribe(
      res => {

        this.notificationService.notifySuccess();

        this.closeModal.emit(null);
      },
      err => {
        console.log(err);

        this.notificationService.notifyFailure();

        this.closeModal.emit(null);
      }
    )
  }

}

