import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ServiceService } from '../services/service.service';
import { Service } from '../models/service.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-service-form',
  templateUrl: './service-form.component.html'
})
export class ServiceFormComponent {
  constructor(public service: ServiceService, public lookupService: LookupService, private notificationService: NotificationService) { }

  @Input() model: Service;

  submitted = false;

  ngOnInit() {
    //this.notificationService.showDatePicker();
  }

  onServiceTypeChanged(data: { value: string[] }): void {
    this.lookupService.getCategoriesByType(data);
  }

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.model.serviceID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }

}

