import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DrugService } from '../services/drug.service';
import { Drug } from '../models/drug.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-drug-form',
  templateUrl: './drug-form.component.html'
})
export class DrugFormComponent {
  constructor(public service: DrugService, public lookupService: LookupService, private notificationService: NotificationService) { }

  @Input() model: Drug;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  submitted = false;

  ngOnInit() {
    //this.notificationService.showDatePicker();
  }

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.model.drugID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }

}

