import { Component } from '@angular/core';
import { ProblemService } from '../services/problem.service';

@Component({
  selector: 'app-problem-list-view',
  templateUrl: './problem-list-view.component.html'
})
export class ProblemListViewComponent {

  constructor(public service: ProblemService) { }

  ngOnInit() {
    this.service.getByPatient();
  }

}

