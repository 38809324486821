export class Booking {
  bookingID: number;

  patientID: number;
  appointmentID: number;

  patientNameAr: string;
  patientNameEn: string;
  dOB: Date;
  gender: number;
  mobileNo: string;
  stateID: number;
  cityID: number;
  address: string;
  appointmentDate: Date;
  appointmentTime: string;
  complaint: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  genderTitle: string;
  age: string;

  verifiedAt: Date;
  verifiedBy: number;
}
