import { Component, Input } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';
import { Prescription } from '../models/prescription.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-prescription-form',
  templateUrl: './prescription-form.component.html'
})
export class PrescriptionFormComponent {
  constructor(public lookupService: LookupService, public service: PrescriptionService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() model: Prescription;

  options: any = { format: 'YYYY-MM-DD' };
  optionsTime: any = { format: 'LT' };

  ngOnInit() {
    this.lookupService.getFrequencies();
    this.lookupService.getGenerics();
  }

  onGenericChanged(data: { value: string[] }): void {
    if (data)
      this.lookupService.getDrugsByGeneric(data);
  }

  onDurationChanged() {
    try {
      var duration = $('#duration').val();
      if (duration && this.model.prescriptionTimeinitiated) {
        var result = new Date(this.model.prescriptionTimeinitiated);
        result.setDate(result.getDate() + parseInt(duration.toString()));
        this.model.prescriptionLastDose = result;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;

    this.service.model = this.model;
    if (!this.model.prescriptionID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.model = new Prescription();
        $('#formPrescription').trigger("reset");

        this.notificationService.notifySuccess();
        this.service.getByVisit();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.model = new Prescription();
        $('#formPrescription').trigger("reset");

        this.notificationService.notifySuccess();
        this.service.getByVisit();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }

}
 
