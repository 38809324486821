import { Component, Input } from '@angular/core';
import { AlergyService } from '../services/alergy.service';
import { Alergy } from '../models/alergy.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: 'app-alergy-list',
  templateUrl: './alergy-list.component.html'
})
export class AlergyListComponent {

  constructor(public service: AlergyService, public lookupService: LookupService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  ngOnInit() {
    this.service.getByPatient();
    this.lookupService.getAlergyTypes();
  }

  model = new Alergy();
  modelSearch = new Alergy();

  onDelete(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByPatient();
      },
      err => { console.log(err); }
    );
  }

  onNew() {
    this.model = new Alergy();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.model = Object.assign({}, model);
  }

}

