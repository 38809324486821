import { Injectable } from '@angular/core';

//import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  isAuthorized(privilege) {
    try {
      let token = localStorage.getItem('access_token');

      if (!token || token == undefined || token == 'undefined') {
        return false;
      }

      return localStorage.getItem("privilege").split('')[privilege] == '1';
    } catch (err) {
      console.log("Error Is Authorized: " + err);
      return false;
    }
  }

  getStaffID() {

    try {
      let token = localStorage.getItem('access_token');

      if (!token || token == undefined || token == 'undefined') {
        return null;
      }

      return parseInt(localStorage.getItem("staffID"));
    } catch (err) {
      console.log("Error Get UserId: " + err);
      return null;
    }
    return null;
  }


  getFullname() {

    try {
      let token = localStorage.getItem('access_token');

      if (!token || token == undefined || token == 'undefined') {
        return null;
      }

      //return localStorage.getItem("staffName");
      return localStorage.getItem("username");
    } catch (err) {
      console.log("Error Get fullname: " + err);
      return null;
    }
    return null;
  }

}
