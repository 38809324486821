import { Component, Input } from '@angular/core';
import { SponsorSpecializationService } from '../services/sponsor.specialization.service';

import { SponsorSpecialization } from '../models/sponsor.specialization.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-sponsor-specialization-list',
  templateUrl: './sponsor-specialization-list.component.html'
})
export class SponsorSpecializationListComponent {
  @Input() id: number;

  constructor(public lookupService: LookupService, public service: SponsorSpecializationService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.service.sponsorID = this.id;
    this.onSearch();
  }

  model = new SponsorSpecialization();
  modelSearch = new SponsorSpecialization();
  modelPush = new SponsorSpecialization();

  onNew() {
    this.model = new SponsorSpecialization();
    $("#PanelSearch, #PanelPush").hide();
    $('#PanelForm').slideToggle();
  }

  onPush() {
    this.modelPush = new SponsorSpecialization();
    $("#PanelSearch, #PanelForm").hide();
    $('#PanelPush').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm').slideToggle();

    this.model = Object.assign({}, model);
  }

  onStatus(values: any, sponsorSpecializationID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new SponsorSpecialization();
    this.service.model.sponsorSpecializationID = sponsorSpecializationID;

    if (values.currentTarget.checked)
      this.service.updateStatus(1).subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
    else
      this.service.updateStatus(0).subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
  }

  onArchive(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );

  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onSubmitPush() {

    this.service.model = this.modelPush;

    this.service.push().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }
}

