import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SponsorService } from '../services/sponsor.service';
import { Sponsor } from '../models/sponsor.model';
import { NotificationService } from '../services/notification.service';
@Component({
  selector: 'app-sponsor-specialization',
  templateUrl: './sponsor-specialization.component.html'
})
export class SponsorSpecializationComponent {
  constructor(private activatedRoute: ActivatedRoute, private service: SponsorService, private notificationService: NotificationService) { }

  id: string;
  model: Sponsor;

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');

      this.service.get(this.id).subscribe(
        res => {
          this.model = res as Sponsor;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );

    });
  }
}
