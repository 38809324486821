import { Component, Input } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';



@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html'
})
export class PrescriptionComponent {
  @Input() visitID: number;

  constructor(public service: PrescriptionService) {

  }

  ngOnInit() {
    this.service.visitID = this.visitID;
  }


}
