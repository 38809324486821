import { ServiceRequest } from '../models/service.request.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { param } from 'jquery';
import { RoleService } from './role.service';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceRequestService {

  model: ServiceRequest = null;
  modelSearch: ServiceRequest = null;
  list: ServiceRequest[];
  modelReport: Report = null;
  listReport: ServiceRequest[];
  baseUrl: string;

  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number;
  pages: number[];

  visitID: number = 0;
  serviceTypeID: number = 0;
  serviceTypeName: string = 'All Orders';
  patientID: number = 0;

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    this.model.visitID = this.visitID;
    return this.http.post(this.baseUrl + "api/ServiceRequest", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    this.model.visitID = this.visitID;
    return this.http.put(this.baseUrl + "api/ServiceRequest/" + this.model.serviceRequestID, this.model);
  }

  do() {
    this.model.doneBy = this.roleService.getStaffID();
    this.model.visitID = this.visitID;
    return this.http.put(this.baseUrl + "api/ServiceRequest/Do/" + this.model.serviceRequestID, this.model);
  }

  undo() {
    this.model.doneBy = this.roleService.getStaffID();
    this.model.visitID = this.visitID;
    return this.http.put(this.baseUrl + "api/ServiceRequest/Undo/" + this.model.serviceRequestID, this.model);
  }

  prepare() {
    this.model.preparedBy = this.roleService.getStaffID();
    return this.http.put(this.baseUrl + "api/ServiceRequest/Prepare/" + this.model.serviceRequestID, this.model);
  }

  unPrepare() {
    this.model.preparedBy = this.roleService.getStaffID();
    return this.http.put(this.baseUrl + "api/ServiceRequest/UnPrepare/" + this.model.serviceRequestID, this.model);
  }

  delete(id) {
    alert(this.baseUrl + "api/ServiceRequest/" + id);
    return this.http.delete(this.baseUrl + "api/ServiceRequest/" + id);
  }

  updateResult(id, result, serviceProviderID) {

    return this.http.post(this.baseUrl + "api/ServiceRequest/UpdateResult", { serviceRequestID: id, result: result, serviceProviderID: serviceProviderID });
  }

  download(resultFile) {
    return this.http.get(this.baseUrl + "api/ServiceRequest/Download", { params: new HttpParams().set("resultFile", resultFile), responseType: 'blob' });
  }

  getByServiceType(serviceTypeID) {

    let params = new HttpParams().set("visitID", this.visitID.toString());

    params = params.set("serviceTypeID", serviceTypeID.toString());

    return this.http.get(this.baseUrl + "api/ServiceRequest/GetByVisit", { params: params });
  }

  get(id) {
    return this.http.get(this.baseUrl + "api/ServiceRequest/" + id);
  }

  getByVisit() {

    this.list = null;

    let params = new HttpParams().set("visitID", this.visitID.toString());

    this.http.get(this.baseUrl + "api/ServiceRequest/GetByVisit", { params: params })
      .toPromise()
      .then(res => this.list = res as ServiceRequest[]);
  }

  getHistory() {

    this.list = null;

    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    params = params.set("patientID", this.patientID.toString());

    this.http.get(this.baseUrl + "api/ServiceRequest/GetHistory", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }


  report() {

    this.listReport = null;

    let params = new HttpParams();

    if (this.modelReport.fromDate != null)
      params = params.set("fromDate", this.modelReport.fromDate);

    if (this.modelReport.toDate != null)
      params = params.set("toDate", this.modelReport.toDate);

    if (this.modelReport.serviceTypeID != null)
      params = params.set("serviceTypeID", this.modelReport.serviceTypeID.toString());

    this.http.get(this.baseUrl + "api/ServiceRequest/Report", { params: params })
      .toPromise()
      .then(res => this.listReport = res as ServiceRequest[]);
  }

  search() {
    this.list = null;

    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    if (this.modelSearch.patientID != null)
      params = params.set("patientID", this.modelSearch.patientID.toString());

    if (this.modelSearch.visitID != null)
      params = params.set("visitID", this.modelSearch.visitID.toString());

    if (this.modelSearch.serviceID != null)
      params = params.set("stateID", this.modelSearch.serviceID.toString());

    if (this.modelSearch.serviceProviderID != null)
      params = params.set("serviceProviderID", this.modelSearch.serviceProviderID.toString());

    if (this.modelSearch.requestStatus != null)
      params = params.set("requestStatus", this.modelSearch.requestStatus.toString());

    this.http.get(this.baseUrl + "api/ServiceRequest/Search", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  loadData(res) {
    this.list = res.item1 as ServiceRequest[];
    this.paginate(res.item2);
  }

  paginate(totalRecords) {

    var pageSize = this.pageSize;
    var currentPage = this.currentPage;
    // calculate total pages
    var totalPages = Math.ceil(totalRecords / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalRecords - 1);

    this.totalPages = totalPages;
    this.totalRecords = totalRecords;

    // create an array of pages to ng-repeat in the pager control
    this.pages = [];
    var i;
    for (i = startPage; i <= endPage; i++)
      this.pages.push(i);
  };
}
