import { Component } from '@angular/core';
import { SponsorService } from '../services/sponsor.service';

@Component({
  selector: 'app-sponsor-pagination',
  templateUrl: './sponsor-pagination.component.html'
})
export class SponsorPaginationComponent {
  constructor(public service: SponsorService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
