export class ServiceRequest {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo


  serviceRequestID: number;
  visitID: number;
  serviceID: number;
  count: number;
  price: number;
  serviceProviderID: number;
  requestStatus: number;
  costPrice: number;
  assignedBy: number;
  providerLocationType: boolean;
  commission: string;
  serviceRequestRemarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  doneAt: Date;
  doneBy: number;

  preparedAt: Date;
  preparedBy: number;

  serviceCategoryID: number;

  result: string;
  resultFile: string;
  serviceTypeID: number;

  patientID: number;

  serviceName: string;

}
