import { Component } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';

@Component({
  selector: 'app-prescription-history',
  templateUrl: './prescription-history.component.html'
})
export class PrescriptionHistoryComponent {

  constructor(public service: PrescriptionService) { }

}

