import { Component } from '@angular/core';
import { AppointmentService } from '../services/appointment.service';

@Component({
  selector: 'app-appointment-report',
  templateUrl: './appointment-report.component.html'
})
export class AppointmentReportComponent {

  constructor(public service: AppointmentService) { }
}

