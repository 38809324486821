import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PharmacyContractService } from '../services/pharmacy.contract.service';
import { PharmacyContract } from '../models/pharmacy.contract.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-pharmacy-contract-form',
  templateUrl: './pharmacy-contract-form.component.html'
})
export class PharmacyContractFormComponent {
  constructor(public lookupService: LookupService, public service: PharmacyContractService, private notificationService: NotificationService) { }

  @Input() model: PharmacyContract;

  submitted = false;

  onGenericChanged(data: { value: string[] }): void {
      this.lookupService.getDrugsByGeneric(data);
  }

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.service.model.serviceCategoryID)
      this.service.model.serviceCategoryID = 0;

    if (!this.model.pharmacyContractID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.model.drugID = null;
        this.model.price = null;
        this.service.search();

        $('#PanelForm').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.service.search();

        $('#PanelForm').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }
}

