import { Component } from '@angular/core';
import { MembershipSpecializationService } from '../services/membership.specialization.service';

@Component({
  selector: 'app-membership-specialization-pagination',
  templateUrl: './membership-specialization-pagination.component.html'
})
export class MembershipSpecializationPaginationComponent {
  constructor(public service: MembershipSpecializationService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
