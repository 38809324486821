import { Component } from '@angular/core';
import { OnlinePatientService } from '../services/online.patient.service';
import { OnlinePatient } from '../models/online.patient.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { RoleService } from '../services/role.service';
import { Privilege } from '../models/privilege.model';

@Component({
  selector: 'app-online-patient-list',
  templateUrl: './online-patient-list.component.html'
})
export class OnlinePatientListComponent {

  constructor(public roleService: RoleService, public lookupService: LookupService, public service: OnlinePatientService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  registration: boolean = false;

  ngOnInit() {
    this.registration = this.roleService.isAuthorized(Privilege.Registration) || this.roleService.isAuthorized(Privilege.Admin);

    this.onSearch();

    this.lookupService.getStates();
    this.lookupService.getCities();
    this.lookupService.getGenders();
  }

  model = new OnlinePatient();
  modelSearch = new OnlinePatient();

  onDelete(id) {

    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onNew() {
    this.model = new OnlinePatient();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    model.dob = this.datepipe.transform(model.dob, 'yyyy-MM-dd');

    this.lookupService.getCitiesByState(model.stateID);
    this.lookupService.filterPatient(model.mobileNo);

    this.model = Object.assign({}, model);
  }

  onVerified(values: any, onlinePatientID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new OnlinePatient();
    this.service.model.onlinePatientID = onlinePatientID;

    if (values.currentTarget.checked)
      this.service.verify().subscribe(
        res => {
          this.notificationService.notifySuccess();

          this.onSearch();

        },
        err => { console.log(err); }
      );
  }

  id: null;

  onDetail(id) {

    $('#PanelDetail, #PanelMain').slideToggle();

    this.id = id;
  }

}

