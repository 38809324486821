import { Component, Input } from '@angular/core';
import { PaymentService } from '../services/payment.service';



@Component({
  selector: 'app-payment-view',
  templateUrl: './payment-view.component.html'
})
export class PaymentViewComponent {
  @Input() invoiceID: number;

  constructor(public service: PaymentService) {

  }

  ngOnInit() {
    this.service.invoiceID = this.invoiceID;
  }


}
