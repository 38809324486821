import { Component } from '@angular/core';
import { AppointmentService } from '../services/appointment.service';
import { Appointment } from '../models/appointment.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-assignee-appointment-list',
  templateUrl: './assignee-appointment-list.component.html'
})
export class AssigneeAppointmentListComponent {

  constructor(public roleService: RoleService,public lookupService: LookupService, public service: AppointmentService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  registration: boolean = false;
  doctor: boolean = false;
  nurse: boolean = false;
  admin: boolean = false;

  ngOnInit() {
    
    this.onSearch();

    this.registration = this.roleService.isAuthorized(Privilege.Registration);
    this.doctor = this.roleService.isAuthorized(Privilege.Doctor);
    this.nurse = this.roleService.isAuthorized(Privilege.Nurse);
    this.admin = this.roleService.isAuthorized(Privilege.Admin);
  }

  options: any = { format: 'YYYY-MM-DD' };

  model = new Appointment();
  modelWizard = new Appointment();

  onDelete(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.delete(id);
    this.notificationService.notifySuccess();

    this.onSearch();
  }

  onSearch() {

    this.service.list = null;
    this.service.currentPage = 1;
    this.service.listByAssignee();
  }

  onNew() {
    this.model = new Appointment();

    $('#PanelForm, #PanelMain').slideToggle();
  }


  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    try {
      model.appointmentDate = this.datepipe.transform(model.appointmentDate, 'yyyy-MM-dd');

    } catch (ex) {

    }

    this.lookupService.getCitiesByState(model.stateID);
    this.model = Object.assign({}, model);
  }

  onVisit(model) {
    this.modelWizard = Object.assign({}, model);

    $('#PanelVisit, #PanelMain').slideToggle();

    //model.appointmentDate = this.datepipe.transform(model.appointmentDate, 'dd/MM/yyyy');
    //this.lookupService.getCitiesByState(model.stateID);

  }

  onConfirmed(values: any, appointmentID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new Appointment();
    this.service.model.appointmentID = appointmentID;

    if (values.currentTarget.checked)
      this.service.confirm().subscribe(
        res => {
          this.notificationService.notifySuccess();

          this.onSearch();
        },
        err => { console.log(err); }
      );
  }
}

