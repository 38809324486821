import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Privilege } from '../models/privilege.model';
import { PharmacyService } from '../services/pharmacy.service';
import { Pharmacy } from '../models/pharmacy.model';
import { NotificationService } from '../services/notification.service';
import { RoleService } from '../services/role.service';
@Component({
  selector: 'app-pharmacy-contract',
  templateUrl: './pharmacy-contract.component.html'
})
export class PharmacyContractComponent {
  constructor(private activatedRoute: ActivatedRoute, private roleService: RoleService, private service: PharmacyService, private notificationService: NotificationService, private router: Router) { }

  id: string;
  model: Pharmacy;

  ngOnInit() {
    if (!this.roleService.isAuthorized(Privilege.Admin))
      this.router.navigate(['']);

    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');

      this.service.get(this.id).subscribe(
        res => {
          this.model = res as Pharmacy;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );

    });
  }
}
