import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MembershipTypeService } from '../services/membership.type.service';
import { MembershipType } from '../models/membership.type.model';
import { NotificationService } from '../services/notification.service';
@Component({
  selector: 'app-membership-specialization',
  templateUrl: './membership-specialization.component.html'
})
export class MembershipSpecializationComponent {
  constructor(private activatedRoute: ActivatedRoute, private service: MembershipTypeService, private notificationService: NotificationService) { }

  id: string;
  model: MembershipType;

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');

      this.service.get(this.id).subscribe(
        res => {
          this.model = res as MembershipType;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );

    });
  }
}
