import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-staff-visit-list',
  templateUrl: './staff-visit-list.component.html'
})
export class StaffVisitListComponent {

  constructor(public roleService: RoleService, public lookupService: LookupService, public service: VisitService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  doctor: boolean = false;
  nurse: boolean = false;
  admin: boolean = false;
  ngOnInit() {
    this.onSearch();

    this.doctor = this.roleService.isAuthorized(Privilege.Doctor);
    this.nurse = this.roleService.isAuthorized(Privilege.Nurse);
    this.admin = this.roleService.isAuthorized(Privilege.Admin);
  }

  options: any = { format: 'YYYY-MM-DD' };

  model = new Visit();

  onDelete(id) {
    this.service.delete(id);
    this.notificationService.notifySuccess();
  }

  onNew() {
    this.model = new Visit();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onSearch() {

    this.service.list = null;
    this.service.currentPage = 1;
    this.service.listByStaff();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    try {
      model.visitDate = this.datepipe.transform(model.visitDate, 'yyyy-MM-dd');

    } catch (ex) {

    }

    this.lookupService.getAppointmentsByPatient(model.patientID);
    this.model = Object.assign({}, model);
  }
}

