export class MembershipService {
  membershipServiceID: number;
  serviceTypeID: number;
  membershipTypeID: number;
  copayment: number;
  status: number;
  lastUpdate: Date;
  remarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  serviceTypeName: string;
}
