export class InvoiceDetail {

  invoiceDetailID: number;
  invoiceID: number;
  count: number;
  price: number;
  total: number;
  discription: string;
  serviceRequestID: number;
  prescriptionID: number;
  copayment: number;

  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

}
