import { Payment } from '../models/payment.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { RoleService } from './role.service';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  model: Payment = null;
  modelSearch: Payment = null;

  modelReport: Report = null;
  listReport: Payment[];

  list: Payment[];
  baseUrl: string;

  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number;
  pages: number[];

  invoiceID: number;
  totalAmount: number = 0;

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getDailyTotals(fromDate, toDate) {
    let params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate);

    return this.http.get(this.baseUrl + "api/Payment/GetDailyTotals", { params: params });
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    this.model.invoiceID = this.invoiceID;
    return this.http.post(this.baseUrl + "api/Payment", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    this.model.invoiceID = this.invoiceID;
    return this.http.put(this.baseUrl + "api/Payment/" + this.model.paymentID, this.model);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "api/Payment/" + id);
  }

  getByInvoice() {

    this.http.get(this.baseUrl + "api/Payment/GetByInvoice/" + this.invoiceID)
      .toPromise()
      .then(res => this.list = res as Payment[]);
  }

  report() {

    this.listReport = null;

    let params = new HttpParams();

    if (this.modelReport.fromDate != null)
      params = params.set("fromDate", this.modelReport.fromDate);

    if (this.modelReport.toDate != null)
      params = params.set("toDate", this.modelReport.toDate);

    if (this.modelReport.paymentMethodID != null)
      params = params.set("paymentMethodID", this.modelReport.paymentMethodID.toString());

    this.http.get(this.baseUrl + "api/Payment/Report", { params: params })
      .toPromise()
      .then(res => this.loadDataReport( res));
  }

  search() {
    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    if (this.modelSearch.fromDate != null)
      params = params.set("fromDate", this.modelSearch.fromDate);

    if (this.modelSearch.toDate != null)
      params = params.set("toDate", this.modelSearch.toDate);

    if (this.modelSearch.paymentMethodID != null)
      params = params.set("paymentMethodID", this.modelSearch.paymentMethodID.toString());

    if (this.modelSearch.invoiceNo != null)
      params = params.set("invoiceNo", this.modelSearch.invoiceNo);


    this.http.get(this.baseUrl + "api/Payment/Search", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  loadData(res) {
    this.list = res.item1 as Payment[];
    this.totalAmount = res.item3;
    this.paginate(res.item2);
  }

  loadDataReport(res) {
    this.listReport = res.item1 as Payment[];
    this.totalRecords = res.item2;
    this.totalAmount = res.item3;
  }

  paginate(totalRecords) {

    var pageSize = this.pageSize;
    var currentPage = this.currentPage;
    // calculate total pages
    var totalPages = Math.ceil(totalRecords / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalRecords - 1);

    this.totalPages = totalPages;
    this.totalRecords = totalRecords;

    // create an array of pages to ng-repeat in the pager control
    this.pages = [];
    var i;
    for (i = startPage; i <= endPage; i++)
      this.pages.push(i);
  };
}
