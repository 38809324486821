export class MembershipType {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  membershipTypeID: number;
  membershipTypeName: string;
  sponserID: number;
  serviceCopayment: number;
  drugCopayment: number;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  sponsorName: string;

}
