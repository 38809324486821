import { Component, Input } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { OnlineAppointmentService } from '../services/online.appointment.service';
import { OnlineAppointment } from '../models/online.appointment.model';

@Component({
  selector: 'app-online-appointment-detail',
  templateUrl: './online-appointment-detail.component.html'
})
export class OnlineAppointmentDetailComponent {

  constructor(public service: OnlineAppointmentService, private notificationService: NotificationService) { }

  model: OnlineAppointment;
  @Input() onlineAppointmentID: string;

  ngOnInit() {

    this.service.get(this.onlineAppointmentID).subscribe(
        res => {
          this.model = res as OnlineAppointment;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );
  }


}
