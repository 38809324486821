export class Problem {

  problemListID: number;
  patientID: number;

  remarks: string;
  status: number;
  diagnosisID: number;

  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

}
