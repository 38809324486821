import { Component, Input } from '@angular/core';
import { ServiceRequestService } from '../services/service.request.service';
import { ServiceRequest } from '../models/service.request.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { RoleService } from '../services/role.service';
import { Privilege } from '../models/privilege.model';

@Component({
  selector: 'app-service-request-list',
  templateUrl: './service-request-list.component.html'
})
export class ServiceRequestListComponent {

  constructor(public roleService: RoleService, public lookupService: LookupService, public service: ServiceRequestService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  doctor: boolean = false;
  nurse: boolean = false;
  admin: boolean = false;

  selected = [];
  serviceRequestIDs = null;

  ngOnInit() {
    this.doctor = this.roleService.isAuthorized(Privilege.Doctor);
    this.nurse = this.roleService.isAuthorized(Privilege.Nurse);
    this.admin = this.roleService.isAuthorized(Privilege.Admin);

    this.service.getByVisit();
  }

  model = new ServiceRequest();

  onDelete(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByVisit();
      },
      err => { console.log(err); }
    );    
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.model = Object.assign({}, model);

    this.lookupService.getSevicesByCategory(this.model.serviceCategoryID);
  }

  onNew() {
    this.model = new ServiceRequest();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onPrepared(values: any, serviceRequestID): void {
    this.service.model = new ServiceRequest();
    this.service.model.serviceRequestID = serviceRequestID;
    console.log(serviceRequestID);


    if (values.currentTarget.checked)
      this.service.prepare().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
    else
      this.service.unPrepare().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
  }

  onSelected(values: any, serviceRequestID): void {
    console.log("values.currentTarget.checked", values.currentTarget.checked);

    if (values.currentTarget.checked)
      this.selected.push(serviceRequestID);
    else
      this.selected = this.selected.filter(item => item != serviceRequestID);

    this.serviceRequestIDs = this.selected.toString();
  }
}

