import { Component, Input } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';
import { Prescription } from '../models/prescription.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';


@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html'
})
export class PrescriptionListComponent {

  constructor(public roleService: RoleService, public lookupService: LookupService, public service: PrescriptionService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  doctor: boolean = false;
  nurse: boolean = false;
  admin: boolean = false;

  selected = [];
  prescriptionIDs = null;

  ngOnInit() {
    this.doctor = this.roleService.isAuthorized(Privilege.Doctor);
    this.nurse = this.roleService.isAuthorized(Privilege.Nurse);
    this.admin = this.roleService.isAuthorized(Privilege.Admin);
    this.service.getByVisit();
  }


  model = new Prescription();
  modelSearch = new Prescription();

  onDelete(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByVisit();
      },
      err => { console.log(err); }
    );
  }

  onNew() {
    this.model = new Prescription();
    this.model.qty = 1;

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.lookupService.getDrugsByGeneric(model.genericID);

    this.model = Object.assign({}, model);
  }

  onDo(values: any, prescriptionID): void {
    this.service.model = new Prescription();
    this.service.model.prescriptionID = prescriptionID;

    if (values.currentTarget.checked)
      this.service.do().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
    else
      this.service.undo().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
  }

  onSelected(values: any, prescriptionID): void {
    if (values.currentTarget.checked)
      this.selected.push(prescriptionID);
    else
      this.selected = this.selected.filter(item => item != prescriptionID);

    this.prescriptionIDs = this.selected.toString();
  }

}

