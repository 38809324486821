import { Component } from '@angular/core';
import { RoleService } from '../services/role.service';
import { Router } from '@angular/router';
import { Privilege } from '../models/privilege.model';
@Component({
  selector: 'app-online-patient',
  templateUrl: './online-patient.component.html'
})
export class OnlinePatientComponent {
  constructor(private service: RoleService, private router: Router) { }

  ngOnInit() {
    if (!this.service.isAuthorized(Privilege.Registration) && !this.service.isAuthorized(Privilege.Doctor) && !this.service.isAuthorized(Privilege.Nurse) && !this.service.isAuthorized(Privilege.Admin))
      this.router.navigate(['']);
  }
}
