export class SponsorService {
  sponsorServiceID: number;
  serviceID: number;
  sponsorID: number;
  price: number;
  status: number;
  lastUpdate: Date;
  remarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  serviceCategoryID: number;
  serviceTypeID: number;
  serviceName: string;
}
