import { Component, Input, SimpleChange, SimpleChanges } from '@angular/core';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Report } from '../models/report.model';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html'
})
export class ReportComponent {

  constructor(
    private router: Router, public datepipe: DatePipe, public lookupService: LookupService, private activatedRoute: ActivatedRoute) { }

  options: any = { format: 'YYYY-MM-DD' };

  model: Report = new Report();

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {

      this.type = params.get('id');

      switch (this.type) {
        case 'Appointment':
          this.lookupService.getStaffs();
          break;
        case 'Visit':
          this.lookupService.getStaffs();
          break;
        case 'Service Order':
          this.lookupService.getServiceTypes();
          break;
        case 'Pharmacy Order':
          break;
        case 'Invoice':
          break;
        case 'Payment':
          this.lookupService.getPaymentMethods();
        case 'Service Claim':
          this.lookupService.getServiceProviders();
          break;
        case 'Drug Claim':
          this.lookupService.getPharmacies();
          break;
        case 'Sponsor Claim':
        case 'Sponsor Claim Membership':
          this.lookupService.getSponsors();
          break;
      }
    });

  }

  onSponsorChanged(data: { value: string[] }): void {
    this.lookupService.getMembershipTypesBySponsor(data);
  }

  type = '';

  onPrint() {

    var pk = 'null';

    if (this.model.fromDate != null)
      this.model.fromDate = this.datepipe.transform(this.model.fromDate, 'yyyy-MM-dd');

    if (this.model.toDate != null)
      this.model.toDate = this.datepipe.transform(this.model.toDate, 'yyyy-MM-dd');

    switch (this.type) {
      case 'Appointment':
        if (this.model.assignee)
          pk = this.model.assignee.toString();
        break;
      case 'Visit':
        if (this.model.staffID)
          pk = this.model.staffID.toString();
        break;
      case 'Service Order':
        if (this.model.serviceTypeID)
          pk = this.model.serviceTypeID.toString();
        break;
      case 'Pharmacy Order':
        break;
      case 'Invoice':
        if (this.model.status)
          pk = this.model.status;
        break;
      case 'Payment':
        if (this.model.paymentMethodID)
          pk = this.model.paymentMethodID.toString();
        break;
      case 'Service Claim':
        if (this.model.serviceProviderID)
          pk = this.model.serviceProviderID.toString();
        break;
      case 'Drug Claim':
        if (this.model.pharmacyID)
          pk = this.model.pharmacyID.toString();
        break;
      case 'Sponsor Claim':
        if (this.model.sponsorID)
          pk = this.model.sponsorID.toString();
        break;
      case 'Sponsor Claim Membership':
        if (this.model.membershipTypeID)
          pk = this.model.membershipTypeID.toString();
        break;
    }

    let newRelativeUrl = this.router.createUrlTree(['/print', this.type, this.model.fromDate, this.model.toDate, pk]);
    console.log(newRelativeUrl);
    let baseUrl = window.location.href.replace(this.router.url, '');

    window.open(baseUrl + newRelativeUrl, '_blank');
  }
}
