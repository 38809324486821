import { Component, Input } from '@angular/core';
import { LookupService } from '../services/lookup.service';
import { ServiceRequestService } from '../services/service.request.service';
@Component({
  selector: 'app-service-request-view',
  templateUrl: './service-request-view.component.html'
})
export class ServiceRequestViewComponent {
  @Input() visitID: number;

  constructor(public lookupService: LookupService, public service: ServiceRequestService) {

  }

  ngOnInit() {
    this.service.visitID = this.visitID;
    this.lookupService.getServiceTypes();
  }


}
