export class Prescription {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  prescriptionID: number;
  drugID: number;
  genericID: number;
  visitID: number;
  qty: number;
  prescriptionDose: string;
  prescriptionRoute: string;
  frequencyID: number;
  prescriptionTimeinitiated: Date;
  prescriptionLastDose: Date;
  prescriptionRemarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  doneAt: Date;
  doneBy: number;

  patientID: number;
  pharmacyID: number;
  
}
