import { Component, Input } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-doctor-form',
  templateUrl: './doctor-form.component.html'
})
export class DoctorFormComponent {
  @Input() visitID: number;
  DiagnosisID: number;

  constructor(public lookupService: LookupService, public service: VisitService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.service.getDiagnosis(this.visitID);
  }

  filterDiagnosis() {
    var term = $('#Filter').val();
    if (term.toString().length > 3)
      this.lookupService.filterDiagnosis(term);
  }

  onDiagnosisChanged(data: { value: string[] }): void {
    if (!data)
      return;

    this.service.addDiagnosis(this.visitID, data).subscribe(
      res => {
        this.notificationService.notifySuccess();
        $('#Filter').val('');
        this.DiagnosisID = null;

        this.service.getDiagnosis(this.visitID);
      },
      err => { this.notificationService.notifyFailure(); console.log(err); }
    )
  }


  onDiagnosisDeleated(i) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.deleteDiagnosis(this.visitID, i).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.DiagnosisID = null;

        this.service.getDiagnosis(this.visitID);
      },
      err => { this.notificationService.notifyFailure(); console.log(err); }
    )
  }
}
