import { Component } from '@angular/core';
import { MembershipServiceService } from '../services/membership.service.service';

@Component({
  selector: 'app-membership-service-pagination',
  templateUrl: './membership-service-pagination.component.html'
})
export class MembershipServicePaginationComponent {
  constructor(public service: MembershipServiceService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
