import { Component, Input } from '@angular/core';
import { AlergyService } from '../services/alergy.service';

@Component({
  selector: 'app-alergy',
  templateUrl: './alergy.component.html'
})
export class AlergyComponent {
  @Input() patientID: number;

  constructor(public service: AlergyService) {

  }

  ngOnInit() {
    this.service.patientID = this.patientID;
  }


}
