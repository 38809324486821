import { Component } from '@angular/core';
import { ServiceProviderService } from '../services/service.provider.service';

@Component({
  selector: 'app-service-provider-pagination',
  templateUrl: './service-provider-pagination.component.html'
})
export class ServiceProviderPaginationComponent {
  constructor(public service: ServiceProviderService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
