import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DatePipe } from '@angular/common'

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { BoardComponent } from './home/board.component';
import { LookupService } from './services/lookup.service';

import { PatientService } from './services/patient.service';
import { PatientComponent } from './patient/patient.component';
import { PatientListComponent } from './patient/patient-list.component';
import { PatientFormComponent } from './patient/patient-form.component';
import { PatientDetailComponent } from './patient/patient-detail.component';
import { PatientPaginationComponent } from './patient/patient-pagination.component';
import { PatientFormWizardComponent } from './patient/patient-form-wizard.component';

import { BookingService } from './services/booking.service';
import { BookingComponent } from './booking/booking.component';
import { BookingListComponent } from './booking/booking-list.component';
import { BookingFormComponent } from './booking/booking-form.component';
import { BookingDetailComponent } from './booking/booking-detail.component';
import { BookingPaginationComponent } from './booking/booking-pagination.component';

import { MessageService } from './services/message.service';
import { MessageComponent } from './message/message.component';
import { MessageListComponent } from './message/message-list.component';
import { MessageFormComponent } from './message/message-form.component';
import { MessageDetailComponent } from './message/message-detail.component';
import { MessagePaginationComponent } from './message/message-pagination.component';

import { AppointmentService } from './services/appointment.service';
import { AppointmentComponent } from './appointment/appointment.component';
import { AppointmentDetailComponent } from './appointment/appointment-detail.component';
import { AppointmentListComponent } from './appointment/appointment-list.component';
import { AppointmentFormComponent } from './appointment/appointment-form.component';
import { AppointmentFormWizardComponent } from './appointment/appointment-form-wizard.component';
import { AppointmentPaginationComponent } from './appointment/appointment-pagination.component';
import { AppointmentReportComponent } from './appointment/appointment-report.component';
import { AppointmentListDateComponent } from './appointment/appointment-list-date.component';

import { VisitService } from './services/visit.service';
import { VisitComponent } from './visit/visit.component';
import { VisitListComponent } from './visit/visit-list.component';
import { VisitFormComponent } from './visit/visit-form.component';
import { VisitFormWizardComponent } from './visit/visit-form-wizard.component';
import { VisitPaginationComponent } from './visit/visit-pagination.component';
import { VisitDetailComponent } from './visit/visit-detail.component';
import { VisitReportComponent } from './visit/visit-report.component';
import { VisitListDateComponent } from './visit/visit-list-date.component';

import { StateService } from './services/state.service';
import { StateComponent } from './state/state.component';
import { StateListComponent } from './state/state-list.component';
import { StateFormComponent } from './state/state-form.component';
import { StatePaginationComponent } from './state/state-pagination.component';

import { SpecializationService } from './services/specialization.service';
import { SpecializationComponent } from './specialization/specialization.component';
import { SpecializationListComponent } from './specialization/specialization-list.component';
import { SpecializationFormComponent } from './specialization/specialization-form.component';
import { SpecializationPaginationComponent } from './specialization/specialization-pagination.component';

import { PrescriptionService } from './services/prescription.service';
import { PrescriptionComponent } from './prescription/prescription.component';
import { PrescriptionListComponent } from './prescription/prescription-list.component';
import { PrescriptionFormComponent } from './prescription/prescription-form.component';
import { PrescriptionPaginationComponent } from './prescription/prescription-pagination.component';
import { PrescriptionListViewComponent } from './prescription/prescription-list-view.component';
import { PrescriptionViewComponent } from './prescription/prescription-view.component';

import { TaskService } from './services/task.service';
import { TaskComponent } from './task/task.component';
import { TaskListComponent } from './task/task-list.component';
import { TaskFormComponent } from './task/task-form.component';
import { TaskListViewComponent } from './task/task-list-view.component';
import { TaskViewComponent } from './task/task-view.component';

import { ProblemService } from './services/problem.service';
import { ProblemComponent } from './problem/problem.component';
import { ProblemListComponent } from './problem/problem-list.component';
import { ProblemFormComponent } from './problem/problem-form.component';
import { ProblemListViewComponent } from './problem/problem-list-view.component';
import { ProblemViewComponent } from './problem/problem-view.component';

import { InvoiceDetailService } from './services/invoice.detail.service';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { InvoiceDetailListComponent } from './invoice-detail/invoice-detail-list.component';
import { InvoiceDetailFormComponent } from './invoice-detail/invoice-detail-form.component';
import { InvoiceDetailPaginationComponent } from './invoice-detail/invoice-detail-pagination.component';
import { InvoiceDetailListViewComponent } from './invoice-detail/invoice-detail-list-view.component';
import { InvoiceDetailViewComponent } from './invoice-detail/invoice-detail-view.component';

import { ServiceRequestService } from './services/service.request.service';
import { ServiceRequestComponent } from './service-request/service-request.component';
import { ServiceRequestListComponent } from './service-request/service-request-list.component';
import { ServiceRequestFormComponent } from './service-request/service-request-form.component';
import { ServiceRequestPaginationComponent } from './service-request/service-request-pagination.component';
import { ServiceRequestViewComponent } from './service-request/service-request-view.component';
import { ServiceRequestListViewComponent } from './service-request/service-request-list-view.component';

import { AssesmentFormService } from './services/assesment.form.service';
import { AssesmentFormComponent } from './assesment-form/assesment-form.component';
import { DoctorFormComponent } from './assesment-form/doctor-form.component';
import { NurseFormComponent } from './assesment-form/nurse-form.component';

import { AssesmentFormDetailComponent } from './assesment-form/assesment-form-detail.component';
import { DoctorFormDetailComponent } from './assesment-form/doctor-form-detail.component';
import { NurseFormDetailComponent } from './assesment-form/nurse-form-detail.component';

import { FormFieldService } from './services/form.field.service';
import { FormFieldOptionService } from './services/form.field.option.service';

import { StaffService } from './services/staff.service';
import { StaffPasswordComponent } from './staff/staff-password.component';

import { ReportComponent } from './report/report.component';
import { PrintReportComponent } from './report/print-report.component';
import { PrintMedicalReportComponent } from './report/print-medical-report.component';

import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';

import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { LoginLayoutComponent } from './layout/login/login-layout.component';
import { MasterLayoutComponent } from './layout/master/master-layout.component';
import { ReportLayoutComponent } from './layout/report/report-layout.component';


import { InvoiceService } from './services/invoice.service';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceListComponent } from './invoice/invoice-list.component';
import { InvoiceFormComponent } from './invoice/invoice-form.component';
import { InvoicePaginationComponent } from './invoice/invoice-pagination.component';
import { InvoiceReportComponent } from './invoice/invoice-report.component';

import { PrintInvoiceComponent } from './report/print-invoice.component';
import { PrintPrescriptionComponent } from './report/print-prescription.component';
import { PrintOrderComponent } from './report/print-order.component';
import { PrintPaymentComponent } from './report/print-payment.component';
import { PrintStickerComponent } from './report/print-sticker.component';

import { PaymentService } from './services/payment.service';
import { PaymentComponent } from './payment/payment.component';
import { PaymentListComponent } from './payment/payment-list.component';
import { PaymentFormComponent } from './payment/payment-form.component';
import { PaymentPaginationComponent } from './payment/payment-pagination.component';
import { PaymentListViewComponent } from './payment/payment-list-view.component';
import { PaymentViewComponent } from './payment/payment-view.component';
import { PaymentsComponent } from './payment/payments.component';
import { PaymentReportComponent } from './payment/payment-report.component';

import { OrderComponent } from './order/order.component';
import { ServiceRequestOrderComponent } from './order/service-request-order.component';
import { ResultUploadComponent } from './order/result-upload.component';
import { PharmacyOrderComponent } from './order/pharmacy-order.component';
import { PrescriptionDrugComponent } from './order/prescription-drug.component';

import { StaffComponent } from './staff/staff.component';
import { StaffListComponent } from './staff/staff-list.component';
import { StaffFormComponent } from './staff/staff-form.component';
import { StaffPaginationComponent } from './staff/staff-pagination.component';

import { GenericComponent } from './generic/generic.component';
import { GenericListComponent } from './generic/generic-list.component';
import { GenericFormComponent } from './generic/generic-form.component';
import { GenericPaginationComponent } from './generic/generic-pagination.component';

import { DrugComponent } from './drug/drug.component';
import { DrugListComponent } from './drug/drug-list.component';
import { DrugFormComponent } from './drug/drug-form.component';
import { DrugPaginationComponent } from './drug/drug-pagination.component';

import { ServiceComponent } from './service/service.component';
import { ServiceListComponent } from './service/service-list.component';
import { ServiceFormComponent } from './service/service-form.component';
import { ServicePaginationComponent } from './service/service-pagination.component';

import { ServiceTypeComponent } from './service-type/service-type.component';
import { ServiceTypeListComponent } from './service-type/service-type-list.component';
import { ServiceTypeFormComponent } from './service-type/service-type-form.component';
import { ServiceTypePaginationComponent } from './service-type/service-type-pagination.component';

import { ServiceCategoryComponent } from './service-category/service-category.component';
import { ServiceCategoryListComponent } from './service-category/service-category-list.component';
import { ServiceCategoryFormComponent } from './service-category/service-category-form.component';
import { ServiceCategoryPaginationComponent } from './service-category/service-category-pagination.component';

import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { DiagnosisListComponent } from './diagnosis/diagnosis-list.component';
import { DiagnosisFormComponent } from './diagnosis/diagnosis-form.component';
import { DiagnosisPaginationComponent } from './diagnosis/diagnosis-pagination.component';

import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { ServiceProviderListComponent } from './service-provider/service-provider-list.component';
import { ServiceProviderFormComponent } from './service-provider/service-provider-form.component';
import { ServiceProviderDetailComponent } from './service-provider/service-provider-detail.component';
import { ServiceProviderPaginationComponent } from './service-provider/service-provider-pagination.component';

import { CityService } from './services/city.service';
import { CityComponent } from './city/city.component';
import { CityListComponent } from './city/city-list.component';
import { CityFormComponent } from './city/city-form.component';
import { CityPaginationComponent } from './city/city-pagination.component';

import { ExchangeRateComponent } from './exchange-rate/exchange-rate.component';
import { ExchangeRateListComponent } from './exchange-rate/exchange-rate-list.component';
import { ExchangeRateFormComponent } from './exchange-rate/exchange-rate-form.component';
import { ExchangeRatePaginationComponent } from './exchange-rate/exchange-rate-pagination.component';

import { ProviderContractComponent } from './provider-contract/provider-contract.component';
import { ProviderContractListComponent } from './provider-contract/provider-contract-list.component';
import { ProviderContractFormComponent } from './provider-contract/provider-contract-form.component';
import { ProviderContractPaginationComponent } from './provider-contract/provider-contract-pagination.component';

import { SponsorServiceComponent } from './sponsor-service/sponsor-service.component';
import { SponsorServiceListComponent } from './sponsor-service/sponsor-service-list.component';
import { SponsorServiceFormComponent } from './sponsor-service/sponsor-service-form.component';
import { SponsorServicePaginationComponent } from './sponsor-service/sponsor-service-pagination.component';

import { SponsorSpecializationComponent } from './sponsor-specialization/sponsor-specialization.component';
import { SponsorSpecializationListComponent } from './sponsor-specialization/sponsor-specialization-list.component';
import { SponsorSpecializationFormComponent } from './sponsor-specialization/sponsor-specialization-form.component';
import { SponsorSpecializationPaginationComponent } from './sponsor-specialization/sponsor-specialization-pagination.component';

import { MembershipServiceComponent } from './membership-service/membership-service.component';
import { MembershipServiceListComponent } from './membership-service/membership-service-list.component';
import { MembershipServiceFormComponent } from './membership-service/membership-service-form.component';
import { MembershipServicePaginationComponent } from './membership-service/membership-service-pagination.component';

import { MembershipSpecializationComponent } from './membership-specialization/membership-specialization.component';
import { MembershipSpecializationListComponent } from './membership-specialization/membership-specialization-list.component';
import { MembershipSpecializationFormComponent } from './membership-specialization/membership-specialization-form.component';
import { MembershipSpecializationPaginationComponent } from './membership-specialization/membership-specialization-pagination.component';

import { PharmacyContractComponent } from './pharmacy-contract/pharmacy-contract.component';
import { PharmacyContractListComponent } from './pharmacy-contract/pharmacy-contract-list.component';
import { PharmacyContractFormComponent } from './pharmacy-contract/pharmacy-contract-form.component';
import { PharmacyContractPaginationComponent } from './pharmacy-contract/pharmacy-contract-pagination.component';

import { FrequencyService } from './services/frequency.service';
import { FrequencyComponent } from './frequency/frequency.component';
import { FrequencyListComponent } from './frequency/frequency-list.component';
import { FrequencyFormComponent } from './frequency/frequency-form.component';
import { FrequencyPaginationComponent } from './frequency/frequency-pagination.component';

import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { PaymentMethodListComponent } from './payment-method/payment-method-list.component';
import { PaymentMethodFormComponent } from './payment-method/payment-method-form.component';
import { PaymentMethodPaginationComponent } from './payment-method/payment-method-pagination.component';

import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { PrescriptionHistoryComponent } from './patient-history/prescription-history.component';
import { ServiceRequestHistoryComponent } from './patient-history/service-request-history.component';
import { VisitHistoryComponent } from './patient-history/visit-history.component';
import { VisitHistoryPaginationComponent } from './patient-history/visit-history-pagination.component';
import { ServiceRequestHistoryPaginationComponent } from './patient-history/service-request-history-pagination.component';
import { PrescriptionHistoryPaginationComponent } from './patient-history/prescription-history-pagination.component';

import { PharmacyService } from './services/pharmacy.service';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { PharmacyListComponent } from './pharmacy/pharmacy-list.component';
import { PharmacyFormComponent } from './pharmacy/pharmacy-form.component';
import { PharmacyDetailComponent } from './pharmacy/pharmacy-detail.component';
import { PharmacyPaginationComponent } from './pharmacy/pharmacy-pagination.component';

import { AlergyService } from './services/alergy.service';
import { AlergyComponent } from './alergy/alergy.component';
import { AlergyListComponent } from './alergy/alergy-list.component';
import { AlergyFormComponent } from './alergy/alergy-form.component';
import { AlergyListViewComponent } from './alergy/alergy-list-view.component';
import { AlergyViewComponent } from './alergy/alergy-view.component';

import { AlergyTypeComponent } from './alergy-type/alergy-type.component';
import { AlergyTypeListComponent } from './alergy-type/alergy-type-list.component';
import { AlergyTypeFormComponent } from './alergy-type/alergy-type-form.component';
import { AlergyTypePaginationComponent } from './alergy-type/alergy-type-pagination.component';


import { LoaderComponent } from './loader/loader.component';
import { LoaderMiniComponent } from './loader/loader-mini.component';

import { NgTempusdominusBootstrapModule } from 'ngx-tempusdominus-bootstrap';
import { NgSelect2Module } from 'ng-select2';
import { NgxBarcodeModule } from 'ngx-barcode';
import { FileUploadModule } from 'ng2-file-upload';

import { ChartsModule } from 'ng2-charts';

import { DrugService } from './services/drug.service';
import { GenericService } from './services/generic.service';
import { Service } from './models/service.model';
import { ServiceService } from './services/service.service';
import { ServiceTypeService } from './services/service.type.service';
import { ServiceCategoryService } from './services/service.category.service';
import { DiagnosisService } from './services/diagnosis.service';
import { ServiceProviderService } from './services/service.provider.service';
import { ExchangeRateService } from './services/exchange.rate.service';
import { getBaseUrl } from '../main';
import { AuthInterceptor } from './auth.interceptor';
import { PrescriptionReportComponent } from './prescription/prescription-report.component';
import { ServiceRequestReportComponent } from './service-request/service-request-report.component';

import { RegistrationComponent } from './registration/registration.component';

import { OnlinePatientService } from './services/online.patient.service';
import { OnlinePatientComponent } from './online-patient/online-patient.component';
import { OnlinePatientListComponent } from './online-patient/online-patient-list.component';
import { OnlinePatientFormComponent } from './online-patient/online-patient-form.component';
import { OnlinePatientDetailComponent } from './online-patient/online-patient-detail.component';
import { OnlinePatientPaginationComponent } from './online-patient/online-patient-pagination.component';
import { OnlinePatientFormWizardComponent } from './online-patient/online-patient-form-wizard.component';

import { OnlineAppointmentService } from './services/online.appointment.service';
import { OnlineAppointmentComponent } from './online-appointment/online-appointment.component';
import { OnlineAppointmentDetailComponent } from './online-appointment/online-appointment-detail.component';
import { OnlineAppointmentListComponent } from './online-appointment/online-appointment-list.component';
import { OnlineAppointmentFormComponent } from './online-appointment/online-appointment-form.component';
import { OnlineAppointmentFormWizardComponent } from './online-appointment/online-appointment-form-wizard.component';
import { OnlineAppointmentPaginationComponent } from './online-appointment/online-appointment-pagination.component';
import { OnlineAppointmentReportComponent } from './online-appointment/online-appointment-report.component';
import { OnlineAppointmentListDateComponent } from './online-appointment/online-appointment-list-date.component';
import { StaffVisitListComponent } from './visit/staff-visit-list.component';
import { AssigneeAppointmentListComponent } from './appointment/assignee-appointment-list.component';
import { InvoiceDetailReportComponent } from './invoice-detail/invoice-detail-report.component';

import { SponsorService } from './services/sponsor.service';
import { SponsorComponent } from './sponsor/sponsor.component';
import { SponsorListComponent } from './sponsor/sponsor-list.component';
import { SponsorFormComponent } from './sponsor/sponsor-form.component';
import { SponsorPaginationComponent } from './sponsor/sponsor-pagination.component';

import { MembershipTypeService } from './services/membership.type.service';
import { MembershipTypeComponent } from './membership-type/membership-type.component';
import { MembershipTypeListComponent } from './membership-type/membership-type-list.component';
import { MembershipTypeFormComponent } from './membership-type/membership-type-form.component';
import { MembershipTypePaginationComponent } from './membership-type/membership-type-pagination.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    BoardComponent,

    PatientComponent,
    PatientListComponent,
    PatientFormComponent,
    PatientDetailComponent,
    PatientPaginationComponent,
    PatientFormWizardComponent,

    BookingComponent,
    BookingListComponent,
    BookingFormComponent,
    BookingDetailComponent,
    BookingPaginationComponent,

    MessageComponent,
    MessageListComponent,
    MessageFormComponent,
    MessageDetailComponent,
    MessagePaginationComponent,

    AppointmentComponent,
    AppointmentDetailComponent,
    AppointmentListComponent,
    AppointmentFormComponent,
    AppointmentFormWizardComponent,
    AppointmentPaginationComponent,
    AppointmentReportComponent,
    AppointmentListDateComponent,

    VisitComponent,
    VisitListComponent,
    VisitFormComponent,
    VisitFormWizardComponent,
    VisitPaginationComponent,
    VisitDetailComponent,
    VisitReportComponent,
    VisitListDateComponent,

    StateComponent,
    StateListComponent,
    StateFormComponent,
    StatePaginationComponent,

    SpecializationComponent,
    SpecializationListComponent,
    SpecializationFormComponent,
    SpecializationPaginationComponent,

    PrescriptionComponent,
    PrescriptionListComponent,
    PrescriptionFormComponent,
    PrescriptionPaginationComponent,
    PrescriptionListViewComponent,
    PrescriptionViewComponent,
    PrescriptionReportComponent,

    TaskComponent,
    TaskListComponent,
    TaskFormComponent,
    TaskListViewComponent,
    TaskViewComponent,

    ProblemComponent,
    ProblemListComponent,
    ProblemFormComponent,
    ProblemListViewComponent,
    ProblemViewComponent,

    AlergyComponent,
    AlergyListComponent,
    AlergyFormComponent,
    AlergyListViewComponent,
    AlergyViewComponent,

    AlergyTypeComponent,
    AlergyTypeListComponent,
    AlergyTypeFormComponent,
    AlergyTypePaginationComponent,

    ServiceRequestComponent,
    ServiceRequestListComponent,
    ServiceRequestFormComponent,
    ServiceRequestPaginationComponent,
    ServiceRequestViewComponent,
    ServiceRequestListViewComponent,
    ServiceRequestReportComponent,

    AssesmentFormComponent,
    DoctorFormComponent,
    NurseFormComponent,

    AssesmentFormDetailComponent,
    DoctorFormDetailComponent,
    NurseFormDetailComponent,

    StaffComponent,
    StaffListComponent,
    StaffFormComponent,
    StaffPasswordComponent,
    StaffPaginationComponent,

    ReportComponent,
    PrintReportComponent,
    PrintMedicalReportComponent,

    InvoiceComponent,
    InvoiceListComponent,
    InvoiceFormComponent,
    InvoicePaginationComponent,
    InvoiceReportComponent,

    InvoiceDetailComponent,
    InvoiceDetailListComponent,
    InvoiceDetailFormComponent,
    InvoiceDetailPaginationComponent,
    InvoiceDetailListViewComponent,
    InvoiceDetailViewComponent,
    PrintInvoiceComponent,

    PaymentComponent,
    PaymentListComponent,
    PaymentFormComponent,
    PaymentPaginationComponent,
    PaymentListViewComponent,
    PaymentViewComponent,
    PaymentsComponent,
    PaymentReportComponent,

    PrintPrescriptionComponent,
    PrintOrderComponent,
    PrintPaymentComponent,
    PrintStickerComponent,

    LoginComponent,

    OrderComponent,
    ServiceRequestOrderComponent,
    ResultUploadComponent,
    PharmacyOrderComponent,
    PrescriptionDrugComponent,

    GenericComponent,
    GenericListComponent,
    GenericFormComponent,
    GenericPaginationComponent,

    DrugComponent,
    DrugListComponent,
    DrugFormComponent,
    DrugPaginationComponent,

    ServiceComponent,
    ServiceListComponent,
    ServiceFormComponent,
    ServicePaginationComponent,

    ServiceTypeComponent,
    ServiceTypeListComponent,
    ServiceTypeFormComponent,
    ServiceTypePaginationComponent,

    ServiceCategoryComponent,
    ServiceCategoryListComponent,
    ServiceCategoryFormComponent,
    ServiceCategoryPaginationComponent,

    DiagnosisComponent,
    DiagnosisListComponent,
    DiagnosisFormComponent,
    DiagnosisPaginationComponent,

    ServiceProviderComponent,
    ServiceProviderListComponent,
    ServiceProviderFormComponent,
    ServiceProviderDetailComponent,
    ServiceProviderPaginationComponent,

    CityComponent,
    CityListComponent,
    CityFormComponent,
    CityPaginationComponent,

    ExchangeRateComponent,
    ExchangeRateListComponent,
    ExchangeRateFormComponent,
    ExchangeRatePaginationComponent,

    ProviderContractComponent,
    ProviderContractListComponent,
    ProviderContractFormComponent,
    ProviderContractPaginationComponent,

    SponsorServiceComponent,
    SponsorServiceListComponent,
    SponsorServiceFormComponent,
    SponsorServicePaginationComponent,

    SponsorSpecializationComponent,
    SponsorSpecializationListComponent,
    SponsorSpecializationFormComponent,
    SponsorSpecializationPaginationComponent,

    MembershipServiceComponent,
    MembershipServiceListComponent,
    MembershipServiceFormComponent,
    MembershipServicePaginationComponent,

    MembershipSpecializationComponent,
    MembershipSpecializationListComponent,
    MembershipSpecializationFormComponent,
    MembershipSpecializationPaginationComponent,

    PharmacyContractComponent,
    PharmacyContractListComponent,
    PharmacyContractFormComponent,
    PharmacyContractPaginationComponent,

    FrequencyComponent,
    FrequencyListComponent,
    FrequencyFormComponent,
    FrequencyPaginationComponent,

    PaymentMethodComponent,
    PaymentMethodListComponent,
    PaymentMethodFormComponent,
    PaymentMethodPaginationComponent,

    PatientHistoryComponent,
    PrescriptionHistoryComponent,
    ServiceRequestHistoryComponent,
    VisitHistoryComponent,
    VisitHistoryPaginationComponent,
    ServiceRequestHistoryPaginationComponent,
    PrescriptionHistoryPaginationComponent,

    PharmacyComponent,
    PharmacyListComponent,
    PharmacyDetailComponent,
    PharmacyFormComponent,
    PharmacyPaginationComponent,

    RegistrationComponent,

    OnlinePatientComponent,
    OnlinePatientListComponent,
    OnlinePatientFormComponent,
    OnlinePatientDetailComponent,
    OnlinePatientPaginationComponent,
    OnlinePatientFormWizardComponent,

    OnlineAppointmentComponent,
    OnlineAppointmentDetailComponent,
    OnlineAppointmentListComponent,
    OnlineAppointmentFormComponent,
    OnlineAppointmentFormWizardComponent,
    OnlineAppointmentPaginationComponent,
    OnlineAppointmentReportComponent,
    OnlineAppointmentListDateComponent,

    SponsorComponent,
    SponsorListComponent,
    SponsorFormComponent,
    SponsorPaginationComponent,

    MembershipTypeComponent,
    MembershipTypeListComponent,
    MembershipTypeFormComponent,
    MembershipTypePaginationComponent,

    StaffVisitListComponent,
    AssigneeAppointmentListComponent,
    InvoiceDetailReportComponent,
    
    LoaderComponent,
    LoaderMiniComponent,

    LoginLayoutComponent,
    MasterLayoutComponent,
    ReportLayoutComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgTempusdominusBootstrapModule,
    NgSelect2Module,
    NgxBarcodeModule,
    FileUploadModule,
    ChartsModule,
    AppRoutingModule,
  ],
  providers: [{ provide: 'BASE_URL', useFactory: getBaseUrl }, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    [DatePipe, AuthService, AuthGuard, LookupService, PatientService, StateService, StaffService, AppointmentService, VisitService, PrescriptionService, ServiceRequestService
    , AssesmentFormService, FormFieldService, FormFieldOptionService, InvoiceService, InvoiceDetailService, PaymentService, GenericService, DrugService, ServiceService, ServiceTypeService, ServiceCategoryService
      , DiagnosisService, Service, ServiceProviderService, CityService, ExchangeRateService, SpecializationService, FrequencyService, TaskService, PharmacyService,
      AlergyService, ProblemService, OnlinePatientService, BookingService, OnlineAppointmentService, MessageService, SponsorService, MembershipTypeService]],
  bootstrap: [AppComponent]
})
export class AppModule { }
