import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';

@Component({
  selector: 'app-visit-history-pagination',
  templateUrl: './visit-history-pagination.component.html'
})
export class VisitHistoryPaginationComponent {
  constructor(public service: VisitService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.getHistory();
  }
}
