import { Component } from '@angular/core';
import { ServiceService } from '../services/service.service';

@Component({
  selector: 'app-service-pagination',
  templateUrl: './service-pagination.component.html'
})
export class ServicePaginationComponent {
  constructor(public service: ServiceService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
