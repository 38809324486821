import { Component } from '@angular/core';
import { SponsorSpecializationService } from '../services/sponsor.specialization.service';

@Component({
  selector: 'app-sponsor-specialization-pagination',
  templateUrl: './sponsor-specialization-pagination.component.html'
})
export class SponsorSpecializationPaginationComponent {
  constructor(public service: SponsorSpecializationService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
