export class Service {

  serviceID: number;
  serviceName: string;
  serviceCategoryID: number;
  price: number;
  priceUSD: number;
  vAT: number;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number

  serviceTypeID: number;
  serviceCategoryName: string;
  serviceTypeName: string;
  isCommon: boolean;
}
