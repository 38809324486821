import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";

import { Option } from '../models/option.model';
import { State } from '../models/state.model';
import { City } from '../models/city.model';
import { Specialization } from '../models/specialization.model';
import { Staff } from '../models/staff.model';
import { StaffCategory } from '../models/staff.category.model';
import { Patient } from '../models/patient.model';
import { Appointment } from '../models/appointment.model';
import { vwAppointment } from '../models/vwappointment.model';
import { DatePipe } from '@angular/common';
import { Drug } from '../models/drug.model';
import { Frequency } from '../models/frequency.model';
import { ServiceCategory } from '../models/service.category.model';
import { Service } from '../models/service.model';
import { ServiceType } from '../models/service.type.model';
import { ServiceProvider } from '../models/service.provider.model';
import { PaymentMethod } from '../models/payment.method.model';
import { Generic } from '../models/generic.model';
import { Country } from '../models/country.model';
import { Diagnosis } from '../models/diagnosis.model';
import { Pharmacy } from '../models/pharmacy.model';
import { AlergyType } from '../models/alergy.type.model';
import { MembershipType } from '../models/membership.type.model';
import { Sponsor } from '../models/sponsor.model';


@Injectable({
  providedIn: 'root'
})
export class LookupService {

  states: Option[];
  cities: Option[];
  genders: Option[];
  customers: Option[];
  specializations: Option[];
  staffs: Option[];
  staffCategories: Option[];
  patients: Option[];
  appointments: Option[];

  drugs: Option[];
  generics: Option[];
  frequencies: Option[];
  serviceCategories: Option[];
  services: Option[];
  serviceTypes: Option[];
  paymentMethods: Option[];
  countries: Option[];

  diagnosises: Option[];

  serviceProviders: Option[];
  pharmacies: Option[];
  alergyTypes: Option[];

  sponsors: Option[];
  membershipTypes: Option[];

  baseUrl: string;

  constructor(private http: HttpClient, private datepipe: DatePipe, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getSponsors() {
    this.http.get(this.baseUrl + "api/Sponsor/GetAll")
      .toPromise()
      .then(res => this.sponsors = (res as Sponsor[]).map(item => { return <Option>{ id: item.sponsorID.toString(), text: item.sponsorName, disabled: false } }));
  }

  getMembershipTypesBySponsor(id) {
    this.http.get(this.baseUrl + "api/MembershipType/GetBySponsor/"+id)
      .toPromise()
      .then(res => this.membershipTypes = (res as MembershipType[]).map(item => { return <Option>{ id: item.membershipTypeID.toString(), text: item.membershipTypeName, disabled: false } }));
  }

  getMembershipTypes() {
    this.http.get(this.baseUrl + "api/MembershipType/GetAll/")
      .toPromise()
      .then(res => this.membershipTypes = (res as MembershipType[]).map(item => { return <Option>{ id: item.membershipTypeID.toString(), text: item.membershipTypeName, disabled: false } }));
  }

  getAlergyTypes() {
    this.http.get(this.baseUrl + "api/AlergyType/GetAll")
      .toPromise()
      .then(res => this.alergyTypes = (res as AlergyType[]).map(item => { return <Option>{ id: item.alergyTypeID.toString(), text: item.alergyTypeName, disabled: false } }));
  }

  getStates() {
    this.http.get(this.baseUrl + "api/State/GetAll")
      .toPromise()
      .then(res => this.states = (res as State[]).map(item => { return <Option>{ id: item.stateID.toString(), text: item.stateNameEn, disabled: false } }));
  }

  getPharmacies() {
    this.http.get(this.baseUrl + "api/Pharmacy/GetAll")
      .toPromise()
      .then(res => this.pharmacies = (res as Pharmacy[]).map(item => { return <Option>{ id: item.pharmacyID.toString(), text: item.pharmacyName, disabled: false } }));
  }

  getPaymentMethods() {
    this.http.get(this.baseUrl + "api/PaymentMethod/GetAll")
      .toPromise()
      .then(res => this.paymentMethods = (res as PaymentMethod[]).map(item => { return <Option>{ id: item.paymentMethodID.toString(), text: item.paymentMethodNameEn, disabled: false } }));
  }

  getFrequencies() {
    this.http.get(this.baseUrl + "api/Frequency/GetAll")
      .toPromise()
      .then(res => this.frequencies = (res as Frequency[]).map(item => { return <Option>{ id: item.frequencyID.toString(), text: item.frequencyName, disabled: false } }));
  }


  getCitiesByState(id) {
    if (!id)
      return;

    this.http.get(this.baseUrl + "api/City/GetByState/"+id)
      .toPromise()
      .then(res => this.cities = (res as City[]).map(item => { return <Option>{ id: item.cityID.toString(), text: item.cityNameEn, disabled: false } }));
  }

  getCities() {
    this.http.get(this.baseUrl + "api/City/GetAll/")
      .toPromise()
      .then(res => this.cities = (res as City[]).map(item => { return <Option>{ id: item.cityID.toString(), text: item.cityNameEn, disabled: false } }));
  }

  getGenders() {
    this.genders = [<Option>{ id: '1', text: 'Male', disabled: false }, <Option>{ id: '0', text: 'Female', disabled: false }];
  }


  getSpecializations() {
    this.http.get(this.baseUrl + "api/Specialization/GetAll")
      .toPromise()
      .then(res => this.specializations = (res as Specialization[]).map(item => { return <Option>{ id: item.specializationID.toString(), text: item.specializationNameEn, disabled: false } }));
  }

  getDrugs() {
    this.http.get(this.baseUrl + "api/Drug/GetAll")
      .toPromise()
      .then(res => this.drugs = (res as Drug[]).map(item => { return <Option>{ id: item.drugID.toString(), text: item.tradeName, disabled: false } }));
  }

  getDrugsCommon() {
    this.http.get(this.baseUrl + "api/Drug/GetCommon")
      .toPromise()
      .then(res => this.drugs = (res as Drug[]).map(item => { return <Option>{ id: item.drugID.toString(), text: item.tradeName, disabled: false } }));
  }

  getDrugsByGeneric(id) {
    this.http.get(this.baseUrl + "api/Drug/GetByGeneric/" + id)
      .toPromise()
      .then(res => this.drugs = (res as Drug[]).map(item => { return <Option>{ id: item.drugID.toString(), text: item.tradeName, disabled: false } }));
  }

  getGenerics() {
    this.http.get(this.baseUrl + "api/Generic/GetAll")
      .toPromise()
      .then(res => this.generics = (res as Generic[]).map(item => { return <Option>{ id: item.genericID.toString(), text: item.genericName, disabled: false } }));
  }

  getGenericsCommon() {
    this.http.get(this.baseUrl + "api/Generic/GetCommon")
      .toPromise()
      .then(res => this.generics = (res as Generic[]).map(item => { return <Option>{ id: item.genericID.toString(), text: item.genericName, disabled: false } }));
  }

  getCountries() {
    this.http.get(this.baseUrl + "api/Country/GetAll")
      .toPromise()
      .then(res => this.countries = (res as Country[]).map(item => { return <Option>{ id: item.countryID.toString(), text: item.countryName, disabled: false } }));
  }

  getStaffs() {
    this.http.get(this.baseUrl + "api/Staff/GetAll")
      .toPromise()
      .then(res => this.staffs = (res as Staff[]).map(item => { return <Option>{ id: item.staffID.toString(), text: item.staffNameEn, disabled: false } }));
  }

  getStaffCategories() {
    this.http.get(this.baseUrl + "api/StaffCategory/GetAll")
      .toPromise()
      .then(res => this.staffCategories = (res as StaffCategory[]).map(item => { return <Option>{ id: item.staffCategoryID.toString(), text: item.staffCategoryName, disabled: false } }));
  }

  getServiceTypes() {
    this.http.get(this.baseUrl + "api/ServiceType/GetAll")
      .toPromise()
      .then(res => this.serviceTypes = (res as ServiceType[]).map(item => { return <Option>{ id: item.serviceTypeID.toString(), text: item.serviceTypeName, disabled: false } }));
  }

  getServiceProviders() {
    this.http.get(this.baseUrl + "api/ServiceProvider/GetAll")
      .toPromise()
      .then(res => this.serviceProviders = (res as ServiceProvider[]).map(item => { return <Option>{ id: item.serviceProviderID.toString(), text: item.providerName, disabled: false } }));
  }

  getServiceProvidersByService(id) {
    this.http.get(this.baseUrl + "api/ServiceProvider/GetByService/" + id)
      .toPromise()
      .then(res => this.serviceProviders = (res as ServiceProvider[]).map(item => { return <Option>{ id: item.serviceProviderID.toString(), text: item.providerName, disabled: false } }));
  }

  getCategoriesByType(id) {
    if (!id)
      return;

    this.http.get(this.baseUrl + "api/ServiceCategory/GetByType/"+id)
      .toPromise()
      .then(res => this.serviceCategories = (res as ServiceCategory[]).map(item => { return <Option>{ id: item.serviceCategoryID.toString(), text: item.serviceCategoryName, disabled: false } }));
  }

  getSevicesByCategory(id) {
    if (!id)
      return;

    this.http.get(this.baseUrl + "api/Service/GetByCategory/" + id)
      .toPromise()
      .then(res => this.services = (res as Service[]).map(item => { return <Option>{ id: item.serviceID.toString(), text: item.serviceName, disabled: false } }));
  }

  getSevicesByType(id) {
    if (!id)
      return;

    this.http.get(this.baseUrl + "api/Service/GetByType/" + id)
      .toPromise()
      .then(res => this.services = (res as Service[]).map(item => { return <Option>{ id: item.serviceID.toString(), text: item.serviceName, disabled: false } }));
  }

  getSevicesCommon() {
    this.http.get(this.baseUrl + "api/Service/GetCommon")
      .toPromise()
      .then(res => this.services = (res as Service[]).map(item => { return <Option>{ id: item.serviceID.toString(), text: item.serviceName, disabled: false } }));
  }

  getAppointmentsByPatient(id) {
    if (!id)
      return;

    this.http.get(this.baseUrl + "api/Appointment/GetByPatient/" + id)
      .toPromise()
      .then(res => this.appointments = (res as vwAppointment[]).map(item => { return <Option>{ id: item.appointmentID.toString(), text: item.specializationNameEn + ' | ' + this.datepipe.transform(item.appointmentDate, 'dd/MM/yyyy') + " "+ item.appointmentTime, disabled: false } }));
  }

  getTodayAppointments() {
    this.http.get(this.baseUrl + "api/Appointment/GetToday")
      .toPromise()
      .then(res => this.appointments = (res as vwAppointment[]).map(item => { return <Option>{ id: item.appointmentID.toString(), text: item.patientID + ' - ' + item.patientNameEn + ' | ' + item.specializationNameEn + ' | ' + this.datepipe.transform(item.appointmentDate, 'dd/MM/yyyy') + " " + item.appointmentTime, disabled: false } }));
  }

  filterDiagnosis(term) {
    let params = new HttpParams().set("pageSize", "30").set("term", term);

    this.http.get(this.baseUrl + "api/Diagnosis/Filter", { params: params })
      .toPromise()
      .then(res => this.diagnosises = (res as Diagnosis[]).map(item => { return <Option>{ id: item.diagnosisID.toString(), text: item.diagnosisName, disabled: false } }));
  }

  filterPatient(term) {
    let params = new HttpParams().set("pageSize", "30").set("term", term);

    this.http.get(this.baseUrl + "api/patient/Filter", { params: params })
      .toPromise()
      .then(res => this.patients = (res as Patient[]).map(item => { return <Option>{ id: item.patientID.toString(), text: item.patientID.toString() + ' - '+ item.patientNameEn + ' (' + item.mobileNo + ')', disabled: false } }));
  }

  filterService(term) {
    let params = new HttpParams().set("pageSize", "30").set("term", term);

    this.http.get(this.baseUrl + "api/Service/Filter", { params: params })
      .toPromise()
      .then(res => this.services = (res as Service[]).map(item => { return <Option>{ id: item.serviceID.toString(), text: item.serviceName, disabled: false } }));
  }

  filterAppointment(term) {
    let params = new HttpParams().set("pageSize", "30").set("term", term);


    this.http.get(this.baseUrl + "api/Appointment/Filter", { params: params })
      .toPromise()
      .then(res => this.appointments = (res as vwAppointment[]).map(item => { return <Option>{ id: item.appointmentID.toString(), text: item.patientID.toString() + ' - ' + item.patientNameEn + " (" + item.mobileNo+ ") "+ item.specializationNameEn + ' | ' + this.datepipe.transform(item.appointmentDate, 'dd/MM/yyyy') + " " + item.appointmentTime, disabled: false } }));
  }

}
