import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() { }

  notifySuccess() {
    $('#btnNotifySuccess').click();
  }

  notifyFailure() {
    $('#btnNotifyFailure').click();
  }

  showDatePicker(){
    $('#btnShowDatePicker').click();
  }

}
