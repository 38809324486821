import { Component, Input } from '@angular/core';
import { LookupService } from '../services/lookup.service';
import { ServiceRequestService } from '../services/service.request.service';
@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html'
})
export class ServiceRequestComponent {
  @Input() visitID: number;

  constructor(public lookupService: LookupService, public service: ServiceRequestService) {

  }

  ngOnInit() {
    this.service.visitID = this.visitID;
    this.lookupService.getServiceTypes();
  }

}
