export class AlergyType {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  alergyTypeID: number;
  alergyTypeName: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
}



