export class PharmacyContract {
  pharmacyContractID: number;
  drugID: number;
  pharmacyID: number;
  price: number;
  status: number;
  lastUpdate: Date;
  remarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  serviceCategoryID: number;
  genericID: number;
  serviceName: string;
}
