export class AssesmentForm {
  //PatientID, PatientName, DOB, Gender, MobileNo, Email, Photo
  constructor(
    public assesmentFormID: number,
    public formFieldOptionID: number,
    public formFieldID: number,
    public inputValue: string,
    public visitID: number,
    public include: boolean,
    public createdAt: Date,
    public createdBy: number,
    public modifiedAt: Date,
    public modifiedBy: number,

    public fieldOptionTitle: string,
  ) {}
}
