import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';
import { Prescription } from '../models/prescription.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-prescription-drug',
  templateUrl: './prescription-drug.component.html'
})
export class PrescriptionDrugComponent {
  constructor(public lookupService: LookupService, public service: PrescriptionService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() model: Prescription;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();


  options: any = { format: 'YYYY-MM-DD' };
  optionsTime: any = { format: 'LT' };

  ngOnInit() {
    this.lookupService.getPharmacies();
    this.lookupService.getFrequencies();
  }


  submitted = false;

  onSubmitDrug() {
    this.submitted = true;

    this.service.model = this.model;

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByVisit();
        $('#PanelDrug').slideToggle();
      },
      err => {
        console.log(err);
      }
    );
  }



}
 
