import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';
@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html'
})
export class GenericComponent {
  constructor(private service: RoleService, private router: Router) { }

  ngOnInit() {
    if (!this.service.isAuthorized(Privilege.Admin))
      this.router.navigate(['']);
  }
}
