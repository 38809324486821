import { Component, Input } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';
import { Prescription } from '../models/prescription.model';
import * as $ from 'jquery';
import { Visit } from '../models/visit.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-pharmacy-order',
  templateUrl: './pharmacy-order.component.html'
})
export class PharmacyOrderComponent {

  @Input() model: Visit;
  modelPrescription: Prescription;
  constructor(public lookupService: LookupService, public service: PrescriptionService, private notificationService: NotificationService) { }

  onDrug(model) {

    this.lookupService.getDrugsByGeneric(model.genericID);
    this.modelPrescription = Object.assign({}, model);

    $("#PanelDrug").slideToggle();

    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }


  onGive(values: any, prescriptionID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new Prescription();
    this.service.model.prescriptionID = prescriptionID;

    if (values.currentTarget.checked)
      this.service.do().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
    else
      this.service.undo().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
  }

}

