import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { AssesmentFormService } from '../services/assesment.form.service';

import { Location } from '@angular/common';
import { Invoice } from '../models/invoice.model';
import { InvoiceService } from '../services/invoice.service';

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html'
})
export class PrintInvoiceComponent {

  constructor(private location: Location, private route: ActivatedRoute, public service: InvoiceService, public assesmentFormService: AssesmentFormService, private notificationService: NotificationService) { }

  model: Invoice;
  id: string;

  ngOnInit() {

    if (this.route.snapshot.paramMap.has('id')) {
      this.id = this.route.snapshot.paramMap.get('id');

      this.service.get(this.id).subscribe(
        res => {
          this.model = res as Invoice;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );

    }

  }

  goBack(): void {
    this.location.back();
  }
}

