import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MembershipTypeService } from '../services/membership.type.service';
import { MembershipType } from '../models/membership.type.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-membership-type-form',
  templateUrl: './membership-type-form.component.html'
})
export class MembershipTypeFormComponent {
  constructor(public service: MembershipTypeService, public lookupService: LookupService, private notificationService: NotificationService) { }

  @Input() model: MembershipType;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  submitted = false;


  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.model.membershipTypeID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.closeModal.emit(null);
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.closeModal.emit(null);
      },
      err => {
        console.log(err);
      }
    )
  }


}

