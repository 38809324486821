import { Component } from '@angular/core';
import { ExchangeRateService } from '../services/exchange.rate.service';

@Component({
  selector: 'app-exchange-rate-pagination',
  templateUrl: './exchange-rate-pagination.component.html'
})
export class ExchangeRatePaginationComponent {
  constructor(public service: ExchangeRateService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
