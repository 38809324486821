import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MembershipServiceService } from '../services/membership.service.service';
import { MembershipService } from '../models/membership.service.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-membership-service-form',
  templateUrl: './membership-service-form.component.html'
})
export class MembershipServiceFormComponent {
  constructor(public lookupService: LookupService, public service: MembershipServiceService, private notificationService: NotificationService) { }

  @Input() model: MembershipService;

  submitted = false;

  ngOnInit() {
    //this.notificationService.showDatePicker();
  }

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;

    if (!this.model.membershipServiceID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.model.serviceTypeID = null;
        this.model.copayment = null;
        this.service.search();

        $('#PanelForm').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.service.search();

        $('#PanelForm').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }
}

