import { Component, Input } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { MessageService } from '../services/message.service';
import { Message } from '../models/message.model';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html'
})
export class MessageDetailComponent {

  constructor(public service: MessageService, private notificationService: NotificationService) { }

  model: Message;
  @Input() messageID: string;

  ngOnInit() {

    this.service.get(this.messageID).subscribe(
        res => {
          this.model = res as Message;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );
  }


}
