import { Component } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';

@Component({
  selector: 'app-prescription-report',
  templateUrl: './prescription-report.component.html'
})
export class PrescriptionReportComponent {

  constructor(public service: PrescriptionService) { }

}

