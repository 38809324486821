import { Component, Input } from '@angular/core';
import { PrescriptionService } from '../services/prescription.service';

@Component({
  selector: 'app-prescription-list-view',
  templateUrl: './prescription-list-view.component.html'
})
export class PrescriptionListViewComponent {
  @Input() prescriptionIDs;

  constructor(public service: PrescriptionService) { }

  ngOnInit() {
    this.service.getByVisit();
  }

}

