import { Component, Input } from '@angular/core';
import { ServiceRequestService } from '../services/service.request.service';
import { ServiceRequest } from '../models/service.request.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { ServiceService } from '../services/service.service';

@Component({
  selector: 'app-service-request-form',
  templateUrl: './service-request-form.component.html'
})
export class ServiceRequestFormComponent {
  constructor(public lookupService: LookupService, public service: ServiceRequestService, public serviceService: ServiceService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() model: ServiceRequest;

  options: any = { format: 'YYYY-MM-DD' };
  optionsTime: any = { format: 'LT' };

  filterService() {
    var term = $('#Filter').val();
    if (term.toString().length >= 3)
      this.lookupService.filterService(term);
  }

  onServiceChanged(data: { value: string[] }): void {
    if (data) {
      this.lookupService.getServiceProvidersByService(data);
    }
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;

    this.service.model = this.model;
    if (!this.service.model.serviceCategoryID)
      this.service.model.serviceCategoryID = 0;

    if (!this.model.serviceRequestID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.model = new ServiceRequest();
        $('#formServiceRequest').trigger("reset");

        this.notificationService.notifySuccess();
        this.service.getByVisit();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.model = new ServiceRequest();
        $('#formServiceRequest').trigger("reset");

        this.notificationService.notifySuccess();
        this.service.getByVisit();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }

}
 
