import { Component } from '@angular/core';
import { StaffService } from '../services/staff.service';

@Component({
  selector: 'app-staff-pagination',
  templateUrl: './staff-pagination.component.html'
})
export class StaffPaginationComponent {
  constructor(public service: StaffService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
