import { Component } from '@angular/core';

import * as $ from 'jquery';
import { ServiceRequestService } from '../services/service.request.service';

@Component({
  selector: 'app-service-request-history',
  templateUrl: './service-request-history.component.html'
})
export class ServiceRequestHistoryComponent {

  constructor(public service: ServiceRequestService) { }
}

