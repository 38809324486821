import { Component, Output, EventEmitter } from '@angular/core';
import { OnlinePatientService } from '../services/online.patient.service';
import { OnlinePatient } from '../models/online.patient.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-online-patient-form-wizard',
  templateUrl: './online-patient-form-wizard.component.html'
})
export class OnlinePatientFormWizardComponent {
  constructor(public lookupService: LookupService, public service: OnlinePatientService, private notificationService: NotificationService) { }

  model: OnlinePatient = new OnlinePatient();

  options: any = { format: 'YYYY-MM-DD' };
  @Output() patientCreated: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.lookupService.getStates();
    this.lookupService.getGenders();
  }

  onStateChanged(data: { value: string[] }): void {
    this.lookupService.getCitiesByState(data);
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.model.onlinePatientID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {
    
    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.model.onlinePatientID = parseInt(res.toString());
        this.patientCreated.emit(this.model.onlinePatientID);        
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.reset();
        this.notificationService.notifySuccess();

      },
      err => {
        console.log(err);
      }
    )
  }

  // TODO: Remove this when we're done

  reset() {
    this.model = new OnlinePatient();
  }

}
 
