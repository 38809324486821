export class ServiceProvider {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  serviceProviderID: number;
  providerName: string;
  providerType: boolean;
  stateID: number;
  cityID: number;
  address: string;
  location: string;
  status: boolean;
  username: string;
  password: string;
  serviceID: number;
  costPrice: number;
  providerLocationType: boolean;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  email: string;
  phone: string;
  mobileNo: string;
}
