import { Component } from '@angular/core';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-message-pagination',
  templateUrl: './message-pagination.component.html'
})
export class MessagePaginationComponent {
  constructor(public service: MessageService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
