import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ExchangeRateService } from '../services/exchange.rate.service';
import { ExchangeRate } from '../models/exchange.rate.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-exchange-rate-form',
  templateUrl: './exchange-rate-form.component.html'
})
export class ExchangeRateFormComponent {
  constructor(public service: ExchangeRateService, private notificationService: NotificationService) { }

  @Input() model: ExchangeRate;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  options: any = { format: 'YYYY-MM-DD' };

  submitted = false;

  ngOnInit() {
    //this.notificationService.showDatePicker();
  }

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.model.exchangeRateID)
      this.insertRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.closeModal.emit(null);
      },
      err => { console.log(err); }
    )
  }
}

