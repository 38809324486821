import { Component } from '@angular/core';
import { PharmacyService } from '../services/pharmacy.service';
import { Pharmacy } from '../models/pharmacy.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-pharmacy-list',
  templateUrl: './pharmacy-list.component.html'
})
export class PharmacyListComponent {

  constructor(public lookupService: LookupService, public service: PharmacyService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  ngOnInit() {
    this.onSearch();

    this.lookupService.getStates();
  }

  model = new Pharmacy();
  modelSearch = new Pharmacy();

  onDelete(id) {
    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onNew() {
    this.model = new Pharmacy();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.lookupService.getCitiesByState(model.stateID);
    this.model = Object.assign({}, model);
  }
}

