import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequestService } from '../services/service.request.service';
import { PrescriptionService } from '../services/prescription.service';
import { ServiceTypes } from '../models/service.type.model';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html'
})
export class OrderComponent {

  constructor(private activatedRoute: ActivatedRoute, public lookupService: LookupService, public service: VisitService,
    public serviceRequestService: ServiceRequestService, public prescriptionService: PrescriptionService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  options: any = { format: 'YYYY-MM-DD' };

  id: string;
  type: string;
  serviceTypeID: number;

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');

      switch (this.id) {
        case "pharmacy":
          this.type = "Pharmacy Orders";
          break;
        case "lab":
          this.type = "Lab Orders";
          break;
        case "imaging":
          this.type = "X-Ray & MRI Orders";
          break;
        case "ecg":
          this.type = "ECG Orders";
          break;
      }

      $('#PanelMain').show();
      $('#PanelPrescription, #PanelServiceRequest').hide();


      this.onSearch();
    });

  }

  model = new Visit();
  modelSearch = new Visit();

  onSearch() {

    this.service.list = null;

    this.service.type = this.id;

    if (this.modelSearch.fromDate != null)
      this.modelSearch.fromDate = this.datepipe.transform(this.modelSearch.fromDate, 'yyyy-MM-dd');

    if (this.modelSearch.toDate != null)
      this.modelSearch.toDate = this.datepipe.transform(this.modelSearch.toDate, 'yyyy-MM-dd');

    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onView(model) {

    this.lookupService.getAppointmentsByPatient(model.patientID);
    this.model = Object.assign({}, model);

    if (this.id == "pharmacy") {

      this.prescriptionService.visitID = this.model.visitID;
      this.prescriptionService.getByVisit();

      $('#PanelPrescription, #PanelMain').slideToggle();
    } else {

      this.serviceRequestService.visitID = this.model.visitID;
      this.serviceRequestService.getByVisit();

      $('#PanelServiceRequest, #PanelMain').slideToggle();
    }

  }

}

