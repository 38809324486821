import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VisitService } from '../services/visit.service';
import { PrescriptionService } from '../services/prescription.service';
import { ServiceRequestService } from '../services/service.request.service';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { RoleService } from '../services/role.service';
import { Privilege } from '../models/privilege.model';
@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html'
})
export class PatientHistoryComponent {

  constructor(public roleService: RoleService, private router: Router, public lookupService: LookupService, private activatedRoute: ActivatedRoute, public visitService: VisitService, public prescriptionService: PrescriptionService, public serviceRequestService: ServiceRequestService, private datepipe: DatePipe) { }

  id: string;
  patientID: string = null;

  filterPatient() {
    var term = $('#Filter').val();
    if (term.toString().length >= 3)
      this.lookupService.filterPatient(term);
  }


  ngOnInit() {

    if (!this.roleService.isAuthorized(Privilege.Doctor) && !this.roleService.isAuthorized(Privilege.Nurse) && !this.roleService.isAuthorized(Privilege.CMO)
      && !this.roleService.isAuthorized(Privilege.Pharmacy) && !this.roleService.isAuthorized(Privilege.ECG) && !this.roleService.isAuthorized(Privilege.Lab))
      this.router.navigate(['']);

    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');

      if (this.id != "0")
        this.loadHistory();

    });
  }

  loadHistory() {
    this.visitService.patientID = parseInt(this.id);
    this.visitService.getHistory();

    this.serviceRequestService.patientID = parseInt(this.id);
    this.serviceRequestService.getHistory();

    this.prescriptionService.patientID = parseInt(this.id);
    this.prescriptionService.getHistory();
  }

  onPatientSelected() {
    if (this.patientID) {
      this.id = this.patientID;

      this.loadHistory();
    }
  }

}
