export class Frequency {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  frequencyID: number;
  frequencyName: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
}
