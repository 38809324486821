import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';

import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { AppointmentService } from '../services/appointment.service';
import { InvoiceService } from '../services/invoice.service';
import { PaymentService } from '../services/payment.service';
import { ChartItem } from '../models/chart.item.model';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  // Pie Chart
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  public pieChartData: SingleDataSet = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  public pieChartLabelsCity: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  public pieChartDataCity: SingleDataSet = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  public pieChartLabelsInvoice: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  public pieChartDataInvoice: SingleDataSet = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];


  from_date: string;
  to_date: string;

  options: any = { format: 'YYYY-MM-DD' };

  days: number = 0;

  totalAppointments: number = 0;
  totalVisits: number = 0;
  totalServices: number = 0;
  totalPatients: number = 0;
  totalPrescriptions: number = 0;

  data: string;

  accountant: boolean = false;

  constructor(public roleService: RoleService, public visitService: VisitService, public appointmentService: AppointmentService, public invoiceService: InvoiceService, public paymentService: PaymentService, private datePipe: DatePipe) {

    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();

  }

  resetTotals() {
    this.totalAppointments = 0;
    this.totalVisits = 0;
    this.totalServices = 0;
    this.totalPatients = 0;
  }
  //Bar Chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: { beginAtZero: true },
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Visits",
          },
        },
      ]
    }
  }

  public barChartLabels: Label[] = ['27-Nov-20', '28-Nov-20', '30-Nov-20', '29-Nov-20', '12-Dec-20', '30-Nov-20', '28-Nov-20', '29-Nov-20', '11-Dec-20'];
  public barChartLabelsAppointment: Label[] = ['27-Nov-20', '28-Nov-20', '30-Nov-20', '29-Nov-20', '12-Dec-20', '30-Nov-20', '28-Nov-20', '29-Nov-20', '11-Dec-20'];
  public barChartLabelsInvoice: Label[] = ['27-Nov-20', '28-Nov-20', '30-Nov-20', '29-Nov-20', '12-Dec-20', '30-Nov-20', '28-Nov-20', '29-Nov-20', '11-Dec-20'];
  public barChartLabelsPayment: Label[] = ['27-Nov-20', '28-Nov-20', '30-Nov-20', '29-Nov-20', '12-Dec-20', '30-Nov-20', '28-Nov-20', '29-Nov-20', '11-Dec-20'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [1, 2, 1, 1, 1, 1, 1, 1, 1], label: 'Visits' },
  ];

  public barChartDataAppointment: ChartDataSets[] = [
    { data: [1, 2, 1, 1, 1, 1, 1, 1, 1], label: 'Appointments' },
  ];

  public barChartDataInvoice: ChartDataSets[] = [
    { data: [1, 2, 1, 1, 1, 1, 1, 1, 1], label: 'Invoices' },
  ];

  public barChartDataPayment: ChartDataSets[] = [
    { data: [1, 2, 1, 1, 1, 1, 1, 1, 1], label: 'Payments' },
  ];

  ngOnInit() {

    this.accountant = this.roleService.isAuthorized(Privilege.Accountant);

    this.days = 7;

    this.onPeriodChnage();
  }

  onPeriodChnage() {

    var date: Date = new Date();
    date.setDate((new Date()).getDate() - this.days);

    this.from_date = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.to_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.filter();
  }

  filter() {
    this.from_date = this.datePipe.transform(this.from_date, 'yyyy-MM-dd');
    this.to_date = this.datePipe.transform(this.to_date, 'yyyy-MM-dd');

    this.resetTotals();
    this.loadStata();

    this.loadCharts();
  }

  loadCharts() {
    this.visitService.getVisitsBySpecializationTotals(this.from_date, this.to_date)
      .toPromise()
      .then(res => this.loadData(res));

    this.visitService.getVisitsByCityTotals(this.from_date, this.to_date)
      .toPromise()
      .then(res => this.loadDataCity(res));

    this.invoiceService.getInvoicesByStatusTotals(this.from_date, this.to_date)
      .toPromise()
      .then(res => this.loadDataInvoice(res));

    this.visitService.GetDailyTotals(this.from_date, this.to_date)
      .toPromise()
      .then(res => this.loadDataDaily(res));

    this.appointmentService.getDailyTotals(this.from_date, this.to_date)
      .toPromise()
      .then(res => this.loadDataDailyAppointment(res));

    this.invoiceService.getDailyTotals(this.from_date, this.to_date)
      .toPromise()
      .then(res => this.loadDataDailyInvoice(res));

    this.paymentService.getDailyTotals(this.from_date, this.to_date)
      .toPromise()
      .then(res => this.loadDataDailyPayment(res));
  }

  loadStata() {
    this.visitService.getTotalStata(this.from_date, this.to_date)
      .toPromise()
      .then(res => this.loadStataData(res));
  }

  loadStataData(res) {

    this.totalAppointments = res.item1;
    this.totalVisits = res.item2;
    this.totalServices = res.item3;
    this.totalPrescriptions = res.item4;
  }

  loadData(res) {
    this.pieChartLabels = (res as ChartItem[]).map(item => { return item.label });
    this.pieChartData = (res as ChartItem[]).map(item => { return item.value });
  }

  loadDataCity(res) {
    this.pieChartLabelsCity = (res as ChartItem[]).map(item => { return item.label });
    this.pieChartDataCity = (res as ChartItem[]).map(item => { return item.value });
  }

  loadDataInvoice(res) {
    this.pieChartLabelsInvoice = (res as ChartItem[]).map(item => { return item.label });
    this.pieChartDataInvoice = (res as ChartItem[]).map(item => { return item.value });
  }

  loadDataDaily(res) {

    this.barChartLabels = (res as ChartItem[]).map(item => { return item.label });
    this.barChartData[0].data = (res as ChartItem[]).map(item => { return item.value });
  }

  loadDataDailyAppointment(res) {

    this.barChartLabelsAppointment = (res as ChartItem[]).map(item => { return item.label });
    this.barChartDataAppointment[0].data = (res as ChartItem[]).map(item => { return item.value });
  }

  loadDataDailyInvoice(res) {

    this.barChartLabelsInvoice = (res as ChartItem[]).map(item => { return item.label });
    this.barChartDataInvoice[0].data = (res as ChartItem[]).map(item => { return item.value });
  }

  loadDataDailyPayment(res) {

    this.barChartLabelsPayment = (res as ChartItem[]).map(item => { return item.label });
    this.barChartDataPayment[0].data = (res as ChartItem[]).map(item => { return item.value });
  }

}
