import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProviderContractService } from '../services/provider.contract.service';
import { ProviderContract } from '../models/provider.contract.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-provider-contract-form',
  templateUrl: './provider-contract-form.component.html'
})
export class ProviderContractFormComponent {
  constructor(public lookupService: LookupService, public service: ProviderContractService, private notificationService: NotificationService) { }

  @Input() model: ProviderContract;

  submitted = false;

  ngOnInit() {
    //this.notificationService.showDatePicker();
  }

  onServiceTypeChanged(data: { value: string[] }): void {
    this.lookupService.getCategoriesByType(data);
    this.lookupService.getSevicesByType(data);
  }

  onServiceCategoryChanged(data: { value: string[] }): void {
    this.lookupService.getSevicesByCategory(data);
  }

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.service.model.serviceCategoryID)
      this.service.model.serviceCategoryID = 0;

    if (!this.model.providerContractID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.model.serviceID = null;
        this.model.price = null;
        this.service.search();

        $('#PanelForm').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();

        this.service.search();

        $('#PanelForm').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }
}

