export enum Privilege {
  Registration,
  Doctor,
  Nurse,
  Accountant,
  Pharmacy,
  Lab,
  Imaging,
  ECG,
  Admin,
  CMO
}
