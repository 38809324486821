import { Alergy } from '../models/alergy.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root'
})
export class AlergyService {

  model: Alergy = null;
  list: Alergy[];
  baseUrl: string;

  patientID: number;

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    this.model.patientID = this.patientID;
    return this.http.post(this.baseUrl + "api/Alergy", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    this.model.patientID = this.patientID;
    return this.http.put(this.baseUrl + "api/Alergy/" + this.model.alergyID, this.model);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "api/Alergy/" + id);
  }


  get(id) {
    return this.http.get(this.baseUrl + "api/Alergy/" + id);
  }

  getByPatient() {
    this.list = null;

    this.http.get(this.baseUrl + "api/Alergy/GetByPatient/" + this.patientID)
      .toPromise()
      .then(res => this.list = res as Alergy[]);
  }


}
