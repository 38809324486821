import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';
import { Visit } from '../models/visit.model';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../services/service.service';
import { Service } from '../models/service.model';

@Component({
  selector: 'app-print-sticker',
  templateUrl: './print-sticker.component.html'
})
export class PrintStickerComponent {

  constructor(public visitService: VisitService, public serviceService: ServiceService, private activatedRoute: ActivatedRoute, private datepipe: DatePipe) { }


  serviceName = '';
  model: Visit;

  ngOnInit() {


    this.activatedRoute.paramMap.subscribe(params => {
      var id = params.get('id');
      var serviceID = params.get('serviceID');

      this.visitService.get(id).subscribe(
        res => {
          this.model = res as Visit;
        },
        err => { console.log(err); }
      );

      if (serviceID) {
        this.serviceService.get(serviceID).subscribe(
          res => {
            this.serviceName = (res as Service).serviceName;
          },
          err => { console.log(err); }
        );
      }

    });
  }

  
}
