import { Visit } from '../models/visit.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Diagnosis } from '../models/diagnosis.model';
import { RoleService } from './role.service';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class VisitService {

  modelSearch: Visit = null;
  modelReport: Report = null;
  model: Visit = null;
  list: Visit[];
  listReport: Visit[];
  diagnosises: Diagnosis[];
  baseUrl: string;

  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number;
  pages: number[];

  type: string;
  patientID: number;

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    return this.http.post(this.baseUrl + "api/Visit", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    return this.http.put(this.baseUrl + "api/Visit/" + this.model.visitID, this.model);
  }

  addDiagnosis(visitID, diagnosisID) {
    let params = new HttpParams().set("visitID", visitID).set("diagnosisID", diagnosisID);

    return this.http.get(this.baseUrl + "api/Visit/AddDiagnosis", { params: params });
  }

  deleteDiagnosis(visitID, i) {
    let params = new HttpParams().set("visitID", visitID).set("i", i);

    return this.http.get(this.baseUrl + "api/Visit/DeleteDiagnosis", { params: params });
  }

  get(id) {
    return this.http.get(this.baseUrl + "api/Visit/" + id);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "api/Visit/" + id);
  }

  getTotalStata(fromDate, toDate) {
    let params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate);

    return this.http.get(this.baseUrl + "api/Visit/getTotalStata", { params: params });
  }

  getGrandTotalStata() {
    return this.http.get(this.baseUrl + "api/Visit/getGrandTotalStata");
  }

  getDiagnosis(id) {
    return this.http.get(this.baseUrl + "api/Visit/getDiagnosis/" + id).toPromise()
      .then(res => this.diagnosises = res as Diagnosis[]);
  }

  getVisitsByGenderTotals(fromDate, toDate) {
    let params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate);

    return this.http.get(this.baseUrl + "api/Visit/getVisitsByGenderTotals", { params: params });
  }

  getVisitsBySpecializationTotals(fromDate, toDate) {
    let params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate);

    return this.http.get(this.baseUrl + "api/Visit/getVisitsBySpecializationTotals", { params: params });
  }

  getVisitsByCityTotals(fromDate, toDate) {
    let params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate);


    return this.http.get(this.baseUrl + "api/Visit/getVisitsByCityTotals", { params: params });
  }

  GetDailyTotals(fromDate, toDate) {
    let params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate);

    return this.http.get(this.baseUrl + "api/Visit/GetDailyTotals", { params: params });
  }

  getHistory() {

    this.list = null;

    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    params = params.set("patientID", this.patientID.toString());

    this.http.get(this.baseUrl + "api/Visit/GetHistory", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  search() {

    this.list = null;

    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    if (this.modelSearch.patientID != null)
      params = params.set("patientID", this.modelSearch.patientID.toString());

    if (this.modelSearch.fromDate != null)
      params = params.set("fromDate", this.modelSearch.fromDate);

    if (this.modelSearch.toDate != null)
      params = params.set("toDate", this.modelSearch.toDate);

    if (this.modelSearch.specializationID != null)
      params = params.set("specializationID", this.modelSearch.specializationID.toString());

    if (this.modelSearch.consultationType != null)
      params = params.set("consultationType", this.modelSearch.consultationType.toString());

    if (this.modelSearch.staffID != null)
      params = params.set("staffID", this.modelSearch.staffID.toString());

    if (this.modelSearch.email != null)
      params = params.set("email", this.modelSearch.email);

    if (this.modelSearch.mobileNo != null)
      params = params.set("mobileNo", this.modelSearch.mobileNo);

    if (this.type)
      params = params.set("type", this.type);

    if (this.modelSearch.visitNo != null)
      params = params.set("visitNo", this.modelSearch.visitNo);

    this.http.get(this.baseUrl + "api/Visit/Search", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  listByStaff() {
    this.list = null;

    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

      params = params.set("staffID", this.roleService.getStaffID().toString());

    this.http.get(this.baseUrl + "api/Visit/ListByStaff", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  report() {

    this.listReport = null;

    let params = new HttpParams();

    if (this.modelReport.fromDate != null)
      params = params.set("fromDate", this.modelReport.fromDate);

    if (this.modelReport.toDate != null)
      params = params.set("toDate", this.modelReport.toDate);

    if (this.modelReport.specializationID != null)
      params = params.set("specializationID", this.modelReport.specializationID.toString());

    if (this.modelReport.staffID != null)
      params = params.set("staffID", this.modelReport.staffID.toString());


    this.http.get(this.baseUrl + "api/Visit/Report", { params: params })
      .toPromise()
      .then(res => this.listReport = res as Visit[]);
  }

  loadData(res) {
    this.list = res.item1 as Visit[];
    this.paginate(res.item2);
  }

  paginate(totalRecords) {

    var pageSize = this.pageSize;
    var currentPage = this.currentPage;
    // calculate total pages
    var totalPages = Math.ceil(totalRecords / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalRecords - 1);

    this.totalPages = totalPages;
    this.totalRecords = totalRecords;

    // create an array of pages to ng-repeat in the pager control
    this.pages = [];
    var i;
    for (i = startPage; i <= endPage; i++)
      this.pages.push(i);
  };
}
