import { Component, Input } from '@angular/core';
import { FormField } from '../models/form.field.model';
import { AssesmentFormService } from '../services/assesment.form.service';
import { FormFieldService } from '../services/form.field.service';
import { AssesmentForm } from '../models/assesment.form.model';

@Component({
  selector: 'app-assesment-form-detail',
  templateUrl: './assesment-form-detail.component.html'
})
export class AssesmentFormDetailComponent {
  constructor(private formFieldService: FormFieldService, private service: AssesmentFormService) { }

  @Input() visitID: number;
  @Input() formFieldID: number;
  formField: FormField;

  assesmentForms: AssesmentForm[];

  ngOnInit() {
    this.formFieldService.get(this.formFieldID)
      .toPromise()
      .then(res => this.formField = res as FormField);

    this.service.getByVisitField(this.visitID, this.formFieldID)
      .toPromise()
      .then(res => this.assesmentForms = res as AssesmentForm[]);
  }

}
