import { Component } from '@angular/core';
import { MessageService } from '../services/message.service';
import { Message } from '../models/message.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html'
})
export class MessageListComponent {

  constructor(public roleService: RoleService,public lookupService: LookupService, public service: MessageService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  registration: boolean = false;
  doctor: boolean = false;
  nurse: boolean = false;
  admin: boolean = false;
  messageID: number = null;

  ngOnInit() {
    this.onSearch();

    this.registration = this.roleService.isAuthorized(Privilege.Registration);
    this.doctor = this.roleService.isAuthorized(Privilege.Doctor);
    this.nurse = this.roleService.isAuthorized(Privilege.Nurse);
    this.admin = this.roleService.isAuthorized(Privilege.Admin);
  }

  options: any = { format: 'YYYY-MM-DD' };

  model = new Message();
  modelSearch = new Message();
  modelWizard = new Message();

  onDelete(id) {
    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }

  onSearch() {

    if (this.modelSearch.fromDate != null)
      this.modelSearch.fromDate = this.datepipe.transform(this.modelSearch.fromDate, 'yyyy-MM-dd');

    if (this.modelSearch.toDate != null)
      this.modelSearch.toDate = this.datepipe.transform(this.modelSearch.toDate, 'yyyy-MM-dd');

    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onNew() {
    this.model = new Message();
    this.messageID = null;

    $('#PanelForm, #PanelMain').slideToggle();
  }


  onEdit(model) {
    this.messageID = null;

    $('#PanelForm, #PanelMain').slideToggle();

    try {
      model.messageDate = this.datepipe.transform(model.messageDate, 'yyyy-MM-dd');

    } catch (ex) {

    }

    this.model = Object.assign({}, model);
  }

  onDetail(id) {
    this.messageID = id;
    $('#PanelDetail, #PanelMain').slideToggle();
  }
}

