import { Component } from '@angular/core';
import { PatientService } from '../services/patient.service';
import { Patient } from '../models/patient.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { RoleService } from '../services/role.service';
import { Privilege } from '../models/privilege.model';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html'
})
export class PatientListComponent {

  constructor(public roleService: RoleService, public lookupService: LookupService, public service: PatientService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  registration: boolean = false;


  ngOnInit() {
    this.registration = this.roleService.isAuthorized(Privilege.Registration) || this.roleService.isAuthorized(Privilege.Admin);

    this.onSearch();

    this.lookupService.getStates();
    this.lookupService.getCities();
    this.lookupService.getGenders();
    this.lookupService.getSponsors();
    this.lookupService.getMembershipTypes();

  }

  model = new Patient();
  modelSearch = new Patient();

  onDelete(id) {
    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onNew() {
    this.model = new Patient();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();
    console.log("model.dob", model.dob);

    model.dOB = this.datepipe.transform(model.dob, 'yyyy-MM-dd');
    console.log("model.dOB", model.dOB);
    this.lookupService.getCitiesByState(model.stateID);
    this.model = Object.assign({}, model);
  }

  id: null;

  onDetail(id) {

    $('#PanelDetail, #PanelMain').slideToggle();

    this.id = id;
  }

}

