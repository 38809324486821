import { Component } from '@angular/core';
import { ProviderContractService } from '../services/provider.contract.service';

@Component({
  selector: 'app-provider-contract-pagination',
  templateUrl: './provider-contract-pagination.component.html'
})
export class ProviderContractPaginationComponent {
  constructor(public service: ProviderContractService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
