import { Component, Input } from '@angular/core';
import { PharmacyContractService } from '../services/pharmacy.contract.service';

import { PharmacyContract } from '../models/pharmacy.contract.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-pharmacy-contract-list',
  templateUrl: './pharmacy-contract-list.component.html'
})
export class PharmacyContractListComponent {
  @Input() id: number;

  constructor(public lookupService: LookupService, public service: PharmacyContractService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.service.pharmacyID = this.id;
    this.lookupService.getGenerics();
    this.onSearch();
  }

  model = new PharmacyContract();
  modelSearch = new PharmacyContract();

  onGenericChanged(data: { value: string[] }): void {
    this.lookupService.getDrugsByGeneric(data);
  }

  onNew() {
    this.model = new PharmacyContract();
    $("#PanelSearch").hide();
    $('#PanelForm').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm').slideToggle();

    this.lookupService.getDrugsByGeneric(model.genericID);

    this.model = Object.assign({}, model);
  }

  onStatus(values: any, pharmacyContractID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new PharmacyContract();
    this.service.model.pharmacyContractID = pharmacyContractID;

    if (values.currentTarget.checked)
      this.service.updateStatus(1).subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
    else
      this.service.updateStatus(0).subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
  }

  onArchive(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );

  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }
}

