import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Privilege } from '../models/privilege.model';
import { ServiceProviderService } from '../services/service.provider.service';
import { ServiceProvider } from '../models/service.provider.model';
import { NotificationService } from '../services/notification.service';
@Component({
  selector: 'app-provider-contract',
  templateUrl: './provider-contract.component.html'
})
export class ProviderContractComponent {
  constructor(private activatedRoute: ActivatedRoute, private service: ServiceProviderService, private notificationService: NotificationService, private router: Router) { }

  id: string;
  model: ServiceProvider;

  ngOnInit() {
    /*if (!this.service.isAuthorized(Privilege.ProviderContract))
      this.router.navigate(['']);*/

    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');

      this.service.get(this.id).subscribe(
        res => {
          this.model = res as ServiceProvider;
        },
        err => { console.log(err); this.notificationService.notifyFailure(); }
      );

    });
  }
}
