import { Component, Input } from '@angular/core';
import { AssesmentFormService } from '../services/assesment.form.service';
import { AssesmentForm } from '../models/assesment.form.model';

@Component({
  selector: 'app-nurse-form-detail',
  templateUrl: './nurse-form-detail.component.html'
})
export class NurseFormDetailComponent {
  @Input() visitID: number;
  GASTROINTESTINAL: boolean = false;
  VitalSigns: boolean = false;
  CHIEFCOMPLAINT: boolean = false;
  PainAssesment: boolean = false;
  PASTMEDICALHISTORY: boolean = false;
  MEDICALDECISIONMAKING: boolean = false;
  VACCINATIONHISTORY: boolean = false;
  NUTRITION: boolean = false;
  EDUCATION: boolean = false;

  ids: number[];
  Tracheostomy: boolean = false;
  UrinaryCatheter: boolean = false;
  OXYGENTHERAPY: boolean = false;
  Ophthalmology: boolean = false;
  ENT: boolean = false;
  CARDIOVASCULAR: boolean = false;
  RESPIRATORY: boolean = false;
  GENITOURINARY: boolean = false;
  NEUROLOGICAL: boolean = false;
  NURSINGNOTE: boolean = false;
  MUSCULOSKELETAL: boolean = false;
  INTEGUMENTARY: boolean = false;
  NursingCareProvided: boolean = false;

  constructor(public assesmentFormService: AssesmentFormService) { }

  show(ids: number[]) {

    if (this.ids.length == 0)
      return false;

    for (let id of ids) {
      if (this.ids.indexOf(id) >= 0)
        return true;
    }

    return false;
  }

  ngOnInit() {
    this.assesmentFormService.get(this.visitID)
      .toPromise()
      .then(res => this.loadData(res));
  }

  loadData(res) {
    this.ids = (res as AssesmentForm[]).map(item => { return item.formFieldID });

    this.VitalSigns = this.show(new Array(80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92));
    this.CHIEFCOMPLAINT = this.show(new Array(60, 61));
    this.PainAssesment = this.show(new Array(159));
    this.PASTMEDICALHISTORY = this.show(new Array(93));
    this.MEDICALDECISIONMAKING = this.show(new Array(73, 74));
    this.VACCINATIONHISTORY = this.show(new Array(94, 95, 96));
    this.NUTRITION = this.show(new Array(102, 103, 104));
    this.EDUCATION = this.show(new Array(77));

    this.Tracheostomy = this.show(new Array(105, 106, 107, 108, 109));
    this.UrinaryCatheter = this.show(new Array(110, 111, 112, 113, 114, 115, 116, 117));
    this.OXYGENTHERAPY = this.show(new Array(118));
    this.Ophthalmology = this.show(new Array(119));

    this.ENT = this.show(new Array(120, 121, 122));
    this.CARDIOVASCULAR = this.show(new Array(123, 124, 125, 126, 127, 128));
    this.RESPIRATORY = this.show(new Array(130, 131, 132, 133));
    this.GASTROINTESTINAL = this.show(new Array(139, 140, 142, 143));
    this.GENITOURINARY = this.show(new Array(144, 145,1463));
    this.MUSCULOSKELETAL = this.show(new Array(147, 148, 149, 150, 151));
    this.NEUROLOGICAL = this.show(new Array(152));
    this.INTEGUMENTARY = this.show(new Array(153, 154, 155, 156));
    this.NursingCareProvided = this.show(new Array(157, 158));
    this.NURSINGNOTE = this.show(new Array(164));


  }
}
 
