import { Component } from '@angular/core';
import { TaskService } from '../services/task.service';

@Component({
  selector: 'app-task-list-view',
  templateUrl: './task-list-view.component.html'
})
export class TaskListViewComponent {

  constructor(public service: TaskService) { }

  ngOnInit() {
    this.service.getByVisit();
  }

}

