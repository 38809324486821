export class Task {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  visitTaskID: number;
  visitID: number;
  description: string;
  remarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
  doneAt: Date;
  doneBy: number;
  
}
