import { Component, Input } from '@angular/core';
import { PatientService } from '../services/patient.service';
import { Patient } from '../models/patient.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html'
})
export class PatientFormComponent {
  constructor(public lookupService: LookupService, public service: PatientService, private notificationService: NotificationService) { }

  @Input() model: Patient;

  options: any = { format: 'YYYY-MM-DD', maxDate: new Date() };

  ngOnInit() {

  }

  onStateChanged(data: { value: string[] }): void {
    this.lookupService.getCitiesByState(data);
  }

  onSponsorChanged(data: { value: string[] }): void {
    this.lookupService.getMembershipTypesBySponsor(data);
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;
    this.service.model = this.model;
    if (!this.model.patientID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {
    
    this.service.post().subscribe(
      res => {
        this.reset();
        this.notificationService.notifySuccess();
        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.reset();
        this.notificationService.notifySuccess();
        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }

  // TODO: Remove this when we're done

  reset() {
    this.model = new Patient();
  }

}
 
