import { Component, Input } from '@angular/core';
import { ProblemService } from '../services/problem.service';

@Component({
  selector: 'app-problem',
  templateUrl: './problem.component.html'
})
export class ProblemComponent {
  @Input() patientID: number;

  constructor(public service: ProblemService) {

  }

  ngOnInit() {
    this.service.patientID = this.patientID;
  }


}
