import { Component, Input } from '@angular/core';
import { MessageService } from '../services/message.service';
import { Message } from '../models/message.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { PatientService } from '../services/patient.service';
import { Patient } from '../models/patient.model';

@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html'
})
export class MessageFormComponent {
  constructor(public lookupService: LookupService, public patientService: PatientService, public service: MessageService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() model: Message;

  filter: string;
  
  ngOnInit() {

  }

  filterPatient() {
    var term = $('#Filter').val();
    if (term.toString().length >= 3)
      this.lookupService.filterPatient(term);
  }

  getCitiesByState(id) {
    this.lookupService.getCitiesByState(id);
  }

  onStateChanged(data: { value: string[] }): void {
    this.getCitiesByState(data);
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;

    this.service.model = this.model;
    if (!this.model.messageID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.search();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }


}
 
