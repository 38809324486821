export class ServiceCategory {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  serviceCategoryID: number;
  serviceCategoryName: string;
  serviceTypeID: boolean;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
}
