import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppointmentService } from '../services/appointment.service';
import { Appointment } from '../models/appointment.model';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { PatientService } from '../services/patient.service';
import { Patient } from '../models/patient.model';

@Component({
  selector: 'app-appointment-form-wizard',
  templateUrl: './appointment-form-wizard.component.html'
})
export class AppointmentFormWizardComponent {
  constructor(public lookupService: LookupService, public service: AppointmentService, public patientService: PatientService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  @Input() patientID: number;
  model: Appointment = new Appointment();
  @Output() appointmentCreated: EventEmitter<any> = new EventEmitter();

  options: any = { format: 'YYYY-MM-DD' };
  optionsTime: any = { format: 'LT' };
  patientName: string;

  ngOnInit() {
    this.lookupService.getStates();
    this.lookupService.getSpecializations();
    this.lookupService.getStaffs();

    
    this.patientService.get(this.patientID).subscribe(
      res => {
        this.patientName = (res as Patient).patientNameEn;
      },
      err => { console.log(err); this.notificationService.notifyFailure(); }
    );
  }

  onStateChanged(data: { value: string[] }): void {
    this.lookupService.getCitiesByState(data);
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;
    this.model.patientID = this.patientID;

    try {
      this.model.appointmentTime = this.datepipe.transform(this.model.appointmentTime, 'HH:mm');
    } catch (ex) {
      console.log(ex);
    }

    this.service.model = this.model;
    if (!this.model.appointmentID) 
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        
        this.model.appointmentID = parseInt(res.toString());
        this.appointmentCreated.emit(this.model.appointmentID);
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => {
        console.log(err);
      }
    )
  }


}
 
