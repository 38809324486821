import { Component, Input } from '@angular/core';
import { ProblemService } from '../services/problem.service';
import { Problem } from '../models/problem.model';
import { NotificationService } from '../services/notification.service';
import * as $ from 'jquery';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-problem-form',
  templateUrl: './problem-form.component.html'
})
export class ProblemFormComponent {
  constructor(public service: ProblemService, public lookupService: LookupService, private notificationService: NotificationService) { }

  @Input() model: Problem;

  submitted = false;

  filterDiagnosis() {
    var term = $('#Filter').val();
    if (term.toString().length > 3)
      this.lookupService.filterDiagnosis(term);
  }

  onSubmit() {
    this.submitted = true;

    this.service.model = this.model;
    if (!this.model.problemListID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByPatient();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByPatient();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }

}
 
