import { Invoice } from '../models/invoice.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { RoleService } from './role.service';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  model: Invoice = null;
  modelSearch: Invoice = null;

  modelReport: Report = null;
  listReport: Invoice[];

  list: Invoice[];


  baseUrl: string;

  totalRecords: number;
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number;
  pages: number[];

  totalAmount: number=0;
  totalPaid: number=0;
  totalRemaining: number = 0;
  totalCopayment: number = 0;
  totalDiscount: number = 0;

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }


  getDailyTotals(fromDate, toDate) {
    let params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate);

    return this.http.get(this.baseUrl + "api/Invoice/GetDailyTotals", { params: params });
  }

  getInvoicesByStatusTotals(fromDate, toDate) {
    let params = new HttpParams().set("fromDate", fromDate).set("toDate", toDate);


    return this.http.get(this.baseUrl + "api/Invoice/GetInvoicesByStatusTotals", { params: params });
  }

  post() {
    this.model.createdBy = this.roleService.getStaffID();
    return this.http.post(this.baseUrl + "api/Invoice", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    return this.http.put(this.baseUrl + "api/Invoice/" + this.model.invoiceID, this.model);
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "api/Invoice/" + id);
  }

  get(id) {
    return this.http.get(this.baseUrl + "api/Invoice/" + id);
  }

  refreshModel(id) {
    return this.http.get(this.baseUrl + "api/Invoice/" + id).subscribe(
      res => {
        this.model = res as Invoice;
      },
      err => { console.log(err);  }
    );
  }

  report() {

    this.listReport = null;

    let params = new HttpParams();

    if (this.modelReport.fromDate != null)
      params = params.set("fromDate", this.modelReport.fromDate);

    if (this.modelReport.toDate != null)
      params = params.set("toDate", this.modelReport.toDate);

    if (this.modelReport.status != null)
      params = params.set("status", this.modelReport.status);

    this.http.get(this.baseUrl + "api/Invoice/Report", { params: params })
      .toPromise()
      .then(res => this.loadDataReport( res ));
  }

  search() {
    let params = new HttpParams().set("page", this.currentPage.toString()).set("pageSize", this.pageSize.toString());

    if (this.modelSearch.fromDate != null)
      params = params.set("fromDate", this.modelSearch.fromDate);

    if (this.modelSearch.toDate != null)
      params = params.set("toDate", this.modelSearch.toDate);

    if (this.modelSearch.patientID != null)
      params = params.set("patientID", this.modelSearch.patientID.toString());

    if (this.modelSearch.visitNo != null)
      params = params.set("visitNo", this.modelSearch.visitNo);

    this.http.get(this.baseUrl + "api/Invoice/Search", { params: params })
      .toPromise()
      .then(res => this.loadData(res));
  }

  loadData(res) {
    this.list = res.item1 as Invoice[];
    this.totalAmount = res.item3;
    this.totalPaid = res.item4;
    this.totalRemaining = this.totalAmount - this.totalPaid;
    this.totalCopayment = res.item5;
    this.totalDiscount = res.item6;

    this.paginate(res.item2);
  }

  loadDataReport(res) {
    this.listReport = res.item1 as Invoice[];
    this.totalRecords = res.item2;
    this.totalAmount = res.item3;
    this.totalPaid = res.item4;
    this.totalRemaining = this.totalAmount - this.totalPaid;
    this.totalCopayment = res.item5;
    this.totalDiscount = res.item6;
  }

  paginate(totalRecords) {

    var pageSize = this.pageSize;
    var currentPage = this.currentPage;
    // calculate total pages
    var totalPages = Math.ceil(totalRecords / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalRecords - 1);

    this.totalPages = totalPages;
    this.totalRecords = totalRecords;

    // create an array of pages to ng-repeat in the pager control
    this.pages = [];
    var i;
    for (i = startPage; i <= endPage; i++)
      this.pages.push(i);
  };
}
