import { Component } from '@angular/core';
import { CityService } from '../services/city.service';

@Component({
  selector: 'app-city-pagination',
  templateUrl: './city-pagination.component.html'
})
export class CityPaginationComponent {
  constructor(public service: CityService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
