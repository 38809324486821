import { Component } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { MembershipTypeService } from '../services/membership.type.service';

import { MembershipType } from '../models/membership.type.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-membership-type-list',
  templateUrl: './membership-type-list.component.html'
})
export class MembershipTypeListComponent {

  constructor(public lookupService: LookupService, public service: MembershipTypeService, private modalService: NgbModal, private notificationService: NotificationService) { }

  ngOnInit() {
    this.onSearch();

    this.lookupService.getSponsors();
  }

  closeResult = '';
  model = new MembershipType();
  modelSearch = new MembershipType();

  open(content, model) {
    if (model == null)
      model = new MembershipType();

    this.model = Object.assign({}, model);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }

  onArchive(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );

  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }
}

