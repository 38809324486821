import { Component, Input } from '@angular/core';
import { ProviderContractService } from '../services/provider.contract.service';

import { ProviderContract } from '../models/provider.contract.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-provider-contract-list',
  templateUrl: './provider-contract-list.component.html'
})
export class ProviderContractListComponent {
  @Input() id: number;

  constructor(public lookupService: LookupService, public service: ProviderContractService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.service.serviceProviderID = this.id;
    this.lookupService.getServiceTypes();
    this.onSearch();
  }

  model = new ProviderContract();
  modelSearch = new ProviderContract();
  modelPush = new ProviderContract();


  onServiceTypeChanged(data: { value: string[] }): void {
    this.lookupService.getCategoriesByType(data);
  }

  onNew() {
    this.model = new ProviderContract();
    $("#PanelSearch, #PanelPush").hide();
    $('#PanelForm').slideToggle();
  }

  onPush() {
    this.modelPush = new ProviderContract();
    $("#PanelSearch, #PanelForm").hide();
    $('#PanelPush').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm').slideToggle();

    this.lookupService.getCategoriesByType(model.serviceTypeID);
    this.lookupService.getSevicesByCategory(model.serviceCategoryID);

    this.model = Object.assign({}, model);
  }

  onStatus(values: any, providerContractID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new ProviderContract();
    this.service.model.providerContractID = providerContractID;

    if (values.currentTarget.checked)
      this.service.updateStatus(1).subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
    else
      this.service.updateStatus(0).subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
  }

  onArchive(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );

  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onSubmitPush() {
    if (!this.modelPush.serviceCategoryID)
      this.modelPush.serviceCategoryID = 0;

    this.service.model = this.modelPush;

    this.service.push().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }
}

