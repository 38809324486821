import { Component } from '@angular/core';
import { InvoiceService } from '../services/invoice.service';
import { Invoice } from '../models/invoice.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html'
})
export class InvoiceListComponent {

  constructor(public roleService: RoleService,public lookupService: LookupService, public service: InvoiceService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  accountant: boolean = false;
  admin: boolean = false;

  ngOnInit() {
    this.onSearch();

    this.accountant = this.roleService.isAuthorized(Privilege.Accountant);
    this.admin = this.roleService.isAuthorized(Privilege.Admin);
  }


  options: any = { format: 'YYYY-MM-DD' };

  model = new Invoice();
  modelSearch = new Invoice();

  onDelete(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }

  onSearch() {

    if (this.modelSearch.fromDate != null)
      this.modelSearch.fromDate = this.datepipe.transform(this.modelSearch.fromDate, 'yyyy-MM-dd');

    if (this.modelSearch.toDate != null)
      this.modelSearch.toDate = this.datepipe.transform(this.modelSearch.toDate, 'yyyy-MM-dd');

    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onNew() {
    this.model = new Invoice();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    //model.invoiceDate = this.datepipe.transform(model.invoiceDate, 'dd/MM/yyyy');
    this.model = Object.assign({}, model);
  }
}

