import { Component } from '@angular/core';

import { DrugService } from '../services/drug.service';

import { Drug } from '../models/drug.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-drug-list',
  templateUrl: './drug-list.component.html'
})
export class DrugListComponent {

  constructor(public service: DrugService, public lookupService: LookupService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.onSearch();

    this.lookupService.getCountries();
    this.lookupService.getGenerics();
  }

  model = new Drug();
  modelSearch = new Drug();

  onNew() {
    this.model = new Drug();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onArchive(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );

  }

  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    this.model = Object.assign({}, model);
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onCommon(values: any, drugID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.toggleCommon(drugID).subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );

  }

}

