export class PaymentMethod {
  //PatientID; PatientName; DOB; Gender; MobileNo; Email; Photo

  paymentMethodID: number;
  paymentMethodNameEn: string;
  paymentMethodNameAr: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
}
