import { Component } from '@angular/core';
import { VisitService } from '../services/visit.service';

@Component({
  selector: 'app-visit-report',
  templateUrl: './visit-report.component.html'
})
export class VisitReportComponent {

  constructor(public service: VisitService) { }

 
}

