import { AssesmentForm } from '../models/assesment.form.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root'
})
export class AssesmentFormService {

  model: AssesmentForm = null;
  baseUrl: string;
  list: AssesmentForm[];
  ids: number[];

  constructor(private roleService: RoleService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  push() {
    return this.http.post(this.baseUrl + "api/AssesmentForm/Push", this.model);
  }

  pull() {
    return this.http.post(this.baseUrl + "api/AssesmentForm/Pull", this.model);
  }

  put() {
    this.model.modifiedBy = this.roleService.getStaffID();
    return this.http.post(this.baseUrl + "api/AssesmentForm/Put", this.model);
  }

  get(id) {
    return this.http.get(this.baseUrl + "api/AssesmentForm/GetByVisit/" + id);
  }

  getByVisit(id) {
    this.list = null;

    this.http.get(this.baseUrl + "api/AssesmentForm/GetByVisit/" + id)
      .toPromise()
      .then(res => this.list = res as AssesmentForm[]);
  }

  getByVisitField(visitID, formFieldID) {
    let params = new HttpParams().set("visitID", visitID).set("formFieldID", formFieldID);

    return this.http.get(this.baseUrl + "api/AssesmentForm/GetByVisitField", { params: params });
  }
}
