import { Component } from '@angular/core';
import { OnlineAppointmentService } from '../services/online.appointment.service';
import { OnlineAppointment } from '../models/online.appointment.model';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { LookupService } from '../services/lookup.service';
import { NotificationService } from '../services/notification.service';
import { Privilege } from '../models/privilege.model';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-online-appointment-list',
  templateUrl: './online-appointment-list.component.html'
})
export class OnlineAppointmentListComponent {

  constructor(public roleService: RoleService, public lookupService: LookupService, public service: OnlineAppointmentService, public datepipe: DatePipe, private notificationService: NotificationService) { }

  registration: boolean = false;
  doctor: boolean = false;
  nurse: boolean = false;
  admin: boolean = false;

  ngOnInit() {
    this.onSearch();

    this.registration = this.roleService.isAuthorized(Privilege.Registration);
    this.doctor = this.roleService.isAuthorized(Privilege.Doctor);
    this.nurse = this.roleService.isAuthorized(Privilege.Nurse);
    this.admin = this.roleService.isAuthorized(Privilege.Admin);
  }

  options: any = { format: 'YYYY-MM-DD' };

  model = new OnlineAppointment();
  modelSearch = new OnlineAppointment();
  modelWizard = new OnlineAppointment();

  onDelete(id) {
    this.service.delete(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );
  }

  onSearch() {

    if (this.modelSearch.fromDate != null)
      this.modelSearch.fromDate = this.datepipe.transform(this.modelSearch.fromDate, 'yyyy-MM-dd');

    if (this.modelSearch.toDate != null)
      this.modelSearch.toDate = this.datepipe.transform(this.modelSearch.toDate, 'yyyy-MM-dd');

    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onNew() {
    this.model = new OnlineAppointment();

    $('#PanelForm, #PanelMain').slideToggle();
  }


  onEdit(model) {

    $('#PanelForm, #PanelMain').slideToggle();

    model.appointmentDate = this.datepipe.transform(model.appointmentDate, 'yyyy-MM-dd');

    this.lookupService.getCitiesByState(model.stateID);
    this.model = Object.assign({}, model);
  }

  onConfirmed(values: any, onlineAppointmentID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new OnlineAppointment();
    this.service.model.onlineAppointmentID = onlineAppointmentID;

    if (values.currentTarget.checked)
      this.service.confirm().subscribe(
        res => {
          this.notificationService.notifySuccess();

          this.onSearch();
        },
        err => { console.log(err); }
      );
  }
}

