import { Component, Input } from '@angular/core';
import { AlergyService } from '../services/alergy.service';
import { Alergy } from '../models/alergy.model';
import { NotificationService } from '../services/notification.service';
import * as $ from 'jquery';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-alergy-form',
  templateUrl: './alergy-form.component.html'
})
export class AlergyFormComponent {
  constructor(public service: AlergyService, public lookupService: LookupService, private notificationService: NotificationService) { }

  @Input() model: Alergy;

  submitted = false;

  onSubmit() {
    this.submitted = true;

    this.service.model = this.model;
    if (!this.model.alergyID)
      this.insertRecord();
    else
      this.updateRecord();
  }


  insertRecord() {

    this.service.post().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByPatient();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => { console.log(err); }
    )
  }

  updateRecord() {

    this.service.put().subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.service.getByPatient();
        $('#PanelForm, #PanelMain').slideToggle();
      },
      err => {
        console.log(err);
      }
    )
  }

}
 
