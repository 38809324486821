import { Component } from '@angular/core';
import { AppointmentService } from '../services/appointment.service';

@Component({
  selector: 'app-appointment-pagination',
  templateUrl: './appointment-pagination.component.html'
})
export class AppointmentPaginationComponent {
  constructor(public service: AppointmentService) { }

  onPaginate(page: number) {
    this.service.currentPage = page;
    this.service.search();
  }
}
