import { Component, Input, SimpleChanges } from '@angular/core';
import { OnlineAppointmentService } from '../services/online.appointment.service';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { OnlineAppointment } from '../models/online.appointment.model';


@Component({
  selector: 'app-online-appointment-list-date',
  templateUrl: './online-appointment-list-date.component.html'
})
export class OnlineAppointmentListDateComponent {

  constructor(public service: OnlineAppointmentService, public datepipe: DatePipe) { }

  @Input() date: Date;

  ngOnChanges(changes: SimpleChanges) {

    this.date = changes.date.currentValue;
    this.onSearch();
  }

  ngOnInit() {
    this.onSearch();
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = new OnlineAppointment();
    this.service.modelSearch.fromDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    this.service.modelSearch.toDate = this.service.modelSearch.fromDate
    this.service.search();
  }
}

