import { Component } from '@angular/core';

import { ServiceService } from '../services/service.service';

import { Service } from '../models/service.model';
import { NotificationService } from '../services/notification.service';
import { LookupService } from '../services/lookup.service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html'
})
export class ServiceListComponent {

  constructor(public service: ServiceService, public lookupService: LookupService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.onSearch();
    this.lookupService.getServiceTypes();
  }

  model = new Service();
  modelSearch = new Service();

  onServiceTypeChanged(data: { value: string[] }): void {
    this.lookupService.getCategoriesByType(data);
  }

  onNew() {
    this.model = new Service();

    $('#PanelForm, #PanelMain').slideToggle();
  }

  onArchive(id) {
    if (!confirm('Are you sure?')) {
      return;
    }

    this.service.archive(id).subscribe(
      res => {
        this.notificationService.notifySuccess();
        this.onSearch();
      },
      err => { console.log(err); }
    );

  }

  onEdit(model) {

    this.lookupService.getCategoriesByType(model.serviceTypeID);

    $('#PanelForm, #PanelMain').slideToggle();

    this.model = Object.assign({}, model);
  }

  onSearch() {
    this.service.list = null;
    this.service.currentPage = 1;
    this.service.modelSearch = this.modelSearch;
    this.service.search();
  }

  onCommon(values: any, serviceID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.toggleCommon(serviceID).subscribe(
      res => {
        this.notificationService.notifySuccess();
      },
      err => { console.log(err); }
    );

  }
}

