import { Component, Input } from '@angular/core';
import { ServiceRequestService } from '../services/service.request.service';
import { ServiceRequest } from '../models/service.request.model';
import * as $ from 'jquery';
import { Visit } from '../models/visit.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-service-request-order',
  templateUrl: './service-request-order.component.html'
})
export class ServiceRequestOrderComponent {

  @Input() model: Visit;
  modelServiceRequest: ServiceRequest;
  constructor(public service: ServiceRequestService, private notificationService: NotificationService) { }

  closeResult = '';


  onResult(model) {

    this.modelServiceRequest = Object.assign({}, model);

    $("#PanelResult").show();

    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  onDo(values: any, serviceRequestID): void {

    if (!confirm('Are you sure?'))
      return;

    this.service.model = new ServiceRequest();
    this.service.model.serviceRequestID = serviceRequestID;

    if (values.currentTarget.checked)
      this.service.do().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
    else
      this.service.undo().subscribe(
        res => {
          this.notificationService.notifySuccess();
        },
        err => { console.log(err); }
      );
  }

  onDownload(resultFile) {

    this.service.download(resultFile).subscribe(
      res => {
        var type: string;
        if (resultFile.toString().includes('.png'))
          type = "image/png";
        else if (resultFile.toString().includes('.jpg'))
          type = "image/jpg";
        else if (resultFile.toString().includes('.pdf'))
          type = "application/pdf";

        const blob = new Blob([res], { type: type });

        window.open(window.URL.createObjectURL(blob));

        this.notificationService.notifySuccess();
      },
      err => { console.log(err); this.notificationService.notifyFailure(); }
    );
  }

}

