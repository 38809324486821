export class SponsorSpecialization {
  sponsorSpecializationID: number;
  specializationID: number;
  sponsorID: number;
  price: number;
  telePrice: number;

  status: number;
  lastUpdate: Date;
  remarks: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;

  specializationName: string;
}
