import { Component, Input } from '@angular/core';

import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { NotificationService } from '../services/notification.service';
import { OnlinePatient } from '../models/online.patient.model';
import { OnlinePatientService } from '../services/online.patient.service';

@Component({
  selector: 'app-online-patient-detail',
  templateUrl: './online-patient-detail.component.html'
})
export class OnlinePatientDetailComponent {

  constructor(public service: OnlinePatientService, private notificationService: NotificationService, private datepipe: DatePipe) { }

  model: OnlinePatient;
  @Input() id: string;

  ngOnInit() {

    this.service.get(this.id).subscribe(
      res => {
        this.model = res as OnlinePatient;
      },
      err => { console.log(err); this.notificationService.notifyFailure(); }
    );

  }
}
